import React, { Component } from 'react';
import { useSendInterpretationsToBackend } from '../../hooks/useSaveInterpretations';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceArea,
  ReferenceLine,
  Label,
  Text,
} from 'recharts';
import './SndPlot.scss';

// import { create } from 'esri/layers/support/TileInfo';
interface SNDData {
  coordinate_z: number;
  borehole: string;
  penetration_force: number;
  depth: number;
  pred: string;
  color: string;
  flushing_pressure: number;
  penetration_time: number;
  R: boolean;
  Y: boolean;
  S: boolean;
}

interface SndPlotData {
  data: SNDData[];
  showFullPlot: boolean;
  splittedWiews: boolean;
  showQuickClay: boolean;
  selectedProject: string;
  selectedFilenames: string[];
  fetchAuthenticated: any;
}

const optionColors: { [key: string]: string } = {
  Topplag: 'rgba(139, 70, 19, 0.9)',
  Kohesjon: 'rgba(0, 191, 255, 0.9)',
  Friksjonsmateriale: 'rgba(223, 127, 255, 0.9)',
  Sprobrudd: 'rgba(255, 0, 0, 0.9)',
  Berg: 'rgba(101, 116, 154, 0.9)',
  Leire: 'rgba(0, 191, 255, 0.9)',
  Sand: 'rgba(165, 165, 0, 0.9)',
  Grus: 'rgba(145, 145, 0, 0.9)', // Adjusted r and g values by 20 to differentiate from sand
  Morene: 'rgba(223, 127, 255, 0.9)',
  Torv: 'rgba(255, 191, 0, 0.9)',
  Silt: 'rgba(127, 255, 127, 0.9)',
  Blokk: 'rgba(101, 116, 154, 0.9)',
  Udefinert: 'rgba(50, 50, 50, 0.9)',
};

export const ekReferenceAreas: any = [];
export const skReferenceAreas: any = [];

export default class SndPlot extends Component<any, any> {
  constructor(props: SndPlotData, nrOfSnds: number) {
    super(props, nrOfSnds);
    this.state = {
      data: props.data,
      filename: this.extractStatusAndFilenameFromFilename(props.data, props.selectedFilenames)
        .filename,
      selectedProject: props.selectedProject,
      showFullPlot: props.showFullPlot,
      splittedWiews: props.splittedWiews,
      showQuickClay: props.showQuickClay,
      selectedFilenames: props.selectedFilenames,
      nrOfSnds: nrOfSnds,
      left: 'dataMin',
      right: 'dataMax',
      top: 'dataMax+1',
      bottom: 'dataMin-1',
      shadeAreaTop: undefined,
      shadeAreaBottom: undefined,
      refLine: undefined,
      animation: true,
      plotHeight: props.data[props.data.length - 1].depth * 30,
      ekReferenceAreas: [],
      skReferenceAreas: [],
      predAreas: [],
      quickqlayPredAreas: [],
      selectedGeology: 'Topplag',
      customSelectedGeology: 'Torv',
      lineStrokeWidth: 8,
      lineIsClicked: false,
      hoveredLineIndex: -1,
      isDraggingPredArea: false, // Track dragging state for predAreas
      dragPredAreaIndex: null, // Track the index of the predArea being dragged
      dragStartY: 0, // Track the starting Y position of the drag'
      tooltipY: null,
      tooltipX: null,
      isDragging: false,
      mouseOnPlot: false,
      dragLineKey: null,
      selectedInterpretation: this.extractStatusAndFilenameFromFilename(
        props.data,
        props.selectedFilenames
      )?.renderedPlot,
      usedFlushing: this.flushingAndHammeringAndRotationReferenceAreas(props.data).usedFlushing,
      usedHammering: this.flushingAndHammeringAndRotationReferenceAreas(props.data).usedHammering,
      usedRotation: this.flushingAndHammeringAndRotationReferenceAreas(props.data).usedRotation,
      hasStoredManualControl: props.data.some(
        (item: any) => item.manual_control !== '' && item.manual_control !== undefined
      ),
      hasStoredFinalControl: props.data.some(
        (item: any) => item.final_control !== '' && item.final_control !== undefined
      ),
      clickedSave: 'Lagre tolkning',
      selectedStatus: this.extractStatusAndFilenameFromFilename(props.data, props.selectedFilenames)
        ?.status,
      fetchAuthenticated: props.fetchAuthenticated,
      
    };
    this.drawMlPredictions();
  }

  updateSelectedFilenames() {
    const { filename, selectedFilenames, selectedStatus } = this.state;
    const parts = filename.split('~');
    const newFilename = parts[0] + '~' + 'TOT' + '~' + selectedStatus + '~.csv';
    this.setState({ filename: newFilename });
  }

  drawManualOrFinalControl() {
    const {
      data,
      ekReferenceAreas,
      skReferenceAreas,
      selectedInterpretation,
      hasStoredManualControl,
      hasStoredFinalControl,
    } = this.state;
    let predIndexTop;
    let predIndexBottom;
    let predArea;
    let drawnReferenceAreas;
    let dataList;

    if (
      selectedInterpretation === 'egenkontroll' ||
      (selectedInterpretation === 'mlModel' && hasStoredManualControl)
    ) {
      drawnReferenceAreas = ekReferenceAreas;
      dataList = data.map((item: any) => {
        return item.manual_control;
      });
    } else if (hasStoredFinalControl) {
      drawnReferenceAreas = skReferenceAreas;
      dataList = data.map((item: any) => {
        return item.final_control;
      });
    } else {
      return;
    }

    if (data.length > 0 && drawnReferenceAreas.length === 0) {
      let currentPred = dataList[0]; // Initialize with the first pred value
      predIndexTop = 0;
      for (let i = 1; i < data.length; i++) {
        if (dataList[i] !== currentPred) {
          predIndexBottom = i - 1;
          let prevPred = currentPred; // Update the previous pred value
          currentPred = dataList[i]; // Update the current pred value
          const topDepth = data[predIndexTop].depth;
          const bottomDepth = data[predIndexBottom].depth;
          predArea = {
            topIndex: topDepth,
            bottomIndex: bottomDepth,
            prediction: prevPred,
          };
          drawnReferenceAreas.push(predArea);
          predIndexTop = predIndexBottom;
        }
        if (i === data.length - 1) {
          const topDepth = data[predIndexTop].depth;
          const bottomDepth = data[data.length - 1].depth;
          predArea = {
            topIndex: topDepth,
            bottomIndex: bottomDepth,
            prediction: currentPred,
          };
          drawnReferenceAreas.push(predArea);
        }
      }
    }
    if (selectedInterpretation === 'egenkontroll' || selectedInterpretation === 'mlModel') {
      this.setState({
        ekReferenceAreas: drawnReferenceAreas,
        selectedInterpretation: 'egenkontroll',
      });
    } else {
      this.setState({
        skReferenceAreas: drawnReferenceAreas,
        selectedInterpretation: 'sidemannskontroll',
      });
    }
  }

  drawMlPredictions() {
    const { data, predAreas, quickqlayPredAreas } = this.state;
    let predIndexTop: number;
    let predIndexBottom: number;
    let quickclayPredIndexTop: number;
    let quickclayPredIndexBottom: number;
    let predArea;
    let quickqlayPredArea;

    if (data.length > 0 && predAreas.length === 0) {
      let currentPred = data[0].pred; // Initialize with the first pred value
      let currentQuickqlayPred = data[0].pred_quickclay; // Initialize with the first pred value
      predIndexTop = 0;
      quickclayPredIndexTop = 0;
      for (let i = 1; i < data.length; i++) {
        // Geobutler
        if (data[i].pred !== currentPred) {
          predIndexBottom = i - 1;
          let prevPred = currentPred; // Update the previous pred value
          currentPred = data[i].pred; // Update the current pred value
          const topDepth = data[predIndexTop].depth;
          const bottomDepth = data[predIndexBottom].depth;
          predArea = {
            topIndex: topDepth,
            bottomIndex: bottomDepth,
            prediction: prevPred,
          };
          predAreas.push(predArea);
          predIndexTop = predIndexBottom;
        }
        // Geobutler quickclay
        if (data[i].pred_quickclay !== currentQuickqlayPred) {
          quickclayPredIndexBottom = i - 1;
          let prevPredQC = currentQuickqlayPred; // Update the previous pred value
          currentQuickqlayPred = data[i].pred_quickclay; // Update the current pred value
          const topDepth = data[quickclayPredIndexTop].depth;
          const bottomDepth = data[quickclayPredIndexBottom].depth;
          quickqlayPredArea = {
            topIndex: topDepth,
            bottomIndex: bottomDepth,
            prediction: prevPredQC,
          };
          quickqlayPredAreas.push(quickqlayPredArea);
          quickclayPredIndexTop = quickclayPredIndexBottom;
        }
        if (i === data.length - 1) {
          const topDepth = data[predIndexTop].depth;
          const bottomDepth = data[data.length - 1].depth;
          // Geobutler
          predArea = {
            topIndex: topDepth,
            bottomIndex: bottomDepth,
            prediction: currentPred,
          };
          predAreas.push(predArea);
          // Geobutler quickclay
          quickqlayPredArea = {
            topIndex: topDepth,
            bottomIndex: bottomDepth,
            prediction: currentQuickqlayPred,
          };
          quickqlayPredAreas.push(quickqlayPredArea);
        }
      }
    }
    this.setState({
      predAreas: predAreas,
      quickqlayPredAreas: quickqlayPredAreas,
      selectedInterpretation: 'mlModel',
    });
  }

  extractStatusAndFilenameFromFilename(data: any, filenames: string[]) {
    if (filenames === undefined || filenames.length === 0) {
      return { filename: data[0].borehole, status: '', renderedPlot: '' };
    }

    for (let i = 0; i < filenames.length; i++) {
      const parts = filenames[i].split('~');
      const status = parts[2];
      const filename = parts[0];
      if (status === 'Ikke Tolket') {
        return { filename: data[0].borehole, status: 'Påbegynt', renderedPlot: 'mlModel' };
      } else {
        return { filename: filename, status: status, renderedPlot: 'egenkontroll' };
      }
    }
    //   if (parts[0] === data[0].borehole.split('TOT')[0]) {
    //     const status = parts[2];
    //     const filename = filenames[i];
    //     if (status === undefined) {
    //       return { filename: filename, status: 'Påbegynt', renderedPlot: 'mlModel' };
    //     } else {
    //       return { filename: filename, status: status, renderedPlot: 'egenkontroll' };
    //     }
    //   }
    // }
    // If no matching filename is found, return an empty string or another appropriate default value.
    return { filename: data[0].borehole, status: '', renderedPlot: '' };
  }

  flushingAndHammeringAndRotationReferenceAreas(data: any) {
    //const { data } = this.state;
    const usedFlushing = [];
    const usedHammering = [];
    const usedRotation = [];
    let startIndexY = -1;
    let startIndexS = -1;
    let startIndexR = -1;
    let flushingArea;
    let hammerArea;
    let rotationArea;

    for (let i = 0; i < data.length; i++) {
      if (data[i].Y === 1 && startIndexY === -1) {
        startIndexY = i;
      } else if (data[i].Y === 0 && startIndexY !== -1) {
        flushingArea = {
          topIndex: data[startIndexY].depth,
          bottomIndex: data[i - 1].depth,
        };
        usedFlushing.push(flushingArea);
        startIndexY = -1;
      }

      if (data[i].S === 1 && startIndexS === -1) {
        startIndexS = i;
      } else if (data[i].S === 0 && startIndexS !== -1) {
        hammerArea = {
          topIndex: data[startIndexS].depth,
          bottomIndex: data[i - 1].depth,
        };
        usedHammering.push(hammerArea);
        startIndexS = -1;
      }
      if (data[i].R === 1 && startIndexR === -1) {
        startIndexR = i;
      } else if (data[i].R === 0 && startIndexR !== -1) {
        rotationArea = {
          topIndex: data[startIndexR].depth,
          bottomIndex: data[i - 1].depth,
        };
        usedRotation.push(rotationArea);
        startIndexR = -1;
      }
    }

    if (startIndexY !== -1) {
      flushingArea = {
        topIndex: data[startIndexY].depth,
        bottomIndex: data[data.length - 1].depth,
      };
      usedFlushing.push(flushingArea);
    }
    if (startIndexS !== -1) {
      flushingArea = {
        topIndex: data[startIndexS].depth,
        bottomIndex: data[data.length - 1].depth,
      };
      usedHammering.push(flushingArea);
    }
    if (startIndexR !== -1) {
      rotationArea = {
        topIndex: data[startIndexR].depth,
        bottomIndex: data[data.length - 1].depth,
      };
      usedRotation.push(rotationArea);
    }

    return { usedFlushing, usedHammering, usedRotation };
  }

  handleMouseDownOutsidePlot(e: any) {
    const { isDragging, mouseOnPlot } = this.state;
    this.setState({ isDragging: true });
  }

  handleMouseUpOutsidePlot(e: any) {
    const { isDragging, mouseOnPlot } = this.state;
    this.setState({ isDragging: false });
  }
  onMouseEnter(e: any) {
    const { isDragging, mouseOnPlot } = this.state;
    if (isDragging && !mouseOnPlot) {
      this.setState({ shadeAreaTop: 0, isDragging: false });
      return;
    }
  }
  onMouseLeave(e: any) {
    const {
      isDragging,
      data,
      mouseOnPlot,
      shadeAreaTop,
      selectedGeology,
      selectedInterpretation,
      shadeAreaBottom,
      ekReferenceAreas,
      skReferenceAreas,
    } = this.state;
    let shadeAreaBottomLeave = data[data.length - 1].depth;
    let updatedReferenceAreas;
    if (selectedInterpretation === 'egenkontroll' || selectedInterpretation === 'mlModel') {
      updatedReferenceAreas = [...ekReferenceAreas];
    } else {
      updatedReferenceAreas = [...skReferenceAreas];
    }
    if (isDragging && mouseOnPlot) {
      this.setState({ ShadeAreaBottom: data[data.length - 1].depth, mouseOnPlot: false });
      let shadeAreaBottomLeave = data[data.length - 1].depth;
      let newReferenceAreaTop = shadeAreaTop;
      let newReferenceAreaBottom = shadeAreaBottomLeave;
      if (updatedReferenceAreas.length > 0) {
        for (let i = 0; i < updatedReferenceAreas.length; i++) {
          const area = updatedReferenceAreas[i];
          if (area.topIndex > shadeAreaTop && area.topIndex > shadeAreaBottomLeave) {
            //("Området er over skyggen og blir ikke berørt", i);
            continue;
          } else if (
            area.topIndex < shadeAreaTop &&
            area.bottomIndex < shadeAreaBottomLeave &&
            area.bottomIndex < shadeAreaTop
          ) {
            //("Området er under skyggen og blir ikke berørt", i);
            continue;
          } else if (area.topIndex >= shadeAreaTop && area.bottomIndex <= shadeAreaBottomLeave) {
            updatedReferenceAreas.splice(i, 1);
            i--;
            //("Skyggen Fanger hele området, sletter området.");
          } else if (area.topIndex > shadeAreaTop && area.bottomIndex > shadeAreaBottomLeave) {
            //Hvis skyggen starter før toppen og avslutter før bunnen
            let newRefArea = {
              topIndex: shadeAreaBottomLeave,
              bottomIndex: area.bottomIndex,
              prediction: area.prediction,
            };
            //("Skyggen starter før toppen og avslutter før bunnen");
            updatedReferenceAreas.splice(i, 1, newRefArea);
          } else if (area.topIndex < shadeAreaTop && area.bottomIndex < shadeAreaBottomLeave) {
            //Hvis skyggen starter etter toppen og avslutter etter bunnen
            let newRefArea = {
              topIndex: area.topIndex,
              bottomIndex: shadeAreaTop,
              prediction: area.prediction,
            };
            //("Skyggen starter etter toppen og avslutter etter bunnen");
            updatedReferenceAreas.splice(i, 1, newRefArea);
          } else if (area.topIndex < shadeAreaTop && area.bottomIndex > shadeAreaBottomLeave) {
            //Hvis skyggen er midt i område
            let newRefAreaTop = {
              topIndex: area.topIndex,
              bottomIndex: shadeAreaTop,
              prediction: area.prediction,
            };
            let newRefAreaBottom = {
              topIndex: shadeAreaBottomLeave,
              bottomIndex: area.bottomIndex,
              prediction: area.prediction,
            };
            //("Skyggen er i midten av området");
            updatedReferenceAreas.splice(i, 1, newRefAreaTop, newRefAreaBottom);
            break;
          }
        }
      }

      let newAreaPred;
      // ('selectedGeology', selectedGeology);
      if (selectedGeology === 'Other') {
        newAreaPred = this.state.customSelectedGeology;
        // ('newAreaPred', newAreaPred);
      } else {
        newAreaPred = selectedGeology;
      }
      const newReferenceArea = {
        topIndex: newReferenceAreaTop,
        bottomIndex: newReferenceAreaBottom,
        prediction: newAreaPred,
      };
      updatedReferenceAreas.push(newReferenceArea);

      // if (selectedInterpretation === 'egenkontroll' || selectedInterpretation === 'mlModel') {
      //   this.setState({ selectedInterpretation: 'egenkontroll' });
      // }
      if (selectedInterpretation === 'egenkontroll') {
        this.checkIfReferenceAreasAreEqual(ekReferenceAreas, skReferenceAreas);
        this.setState({
          selectedInterpretation: selectedInterpretation,
          ekReferenceAreas: updatedReferenceAreas,
          refLine: undefined,
          shadeAreaTop: undefined,
          shadeAreaBottom: undefined,
          isDragging: false,
          dragLineKey: null,
          dragAreaIndex: null,
          dragStartY: 0,
        });
      } else if (selectedInterpretation === 'sidemannskontroll') {
        this.setState({
          selectedInterpretation: selectedInterpretation,
          skReferenceAreas: updatedReferenceAreas,
          refLine: undefined,
          shadeAreaTop: undefined,
          shadeAreaBottom: undefined,
          isDragging: false,
          dragLineKey: null,
          dragAreaIndex: null,
          dragStartY: 0,
        });
      } else {
        this.setState({
          selectedInterpretation: 'egenkontroll',
          ekReferenceAreas: updatedReferenceAreas,
          refLine: undefined,
          shadeAreaTop: undefined,
          shadeAreaBottom: undefined,
          isDragging: false,
          dragLineKey: null,
          dragAreaIndex: null,
          dragStartY: 0,
          mouseOnPlot: false,
        });
      }
    }
  }

  onMouseDown(e: any) {
    const { data, plotHeight, predAreas, dragLineKey, top, bottom } = this.state;
    if (!e || !e.chartY) {
      return; // Ignore the event if 'chartY' is null or undefined
    }

    const dataIndex = Math.round((e.chartY / (plotHeight - 40)) * (data.length - 1));
    let chartY;
    if (dataIndex < 20) {
      chartY = data[0].depth; // Ignore the event if the index is out of range
    } else {
      chartY = data[dataIndex]?.depth;
    }

    if (chartY !== undefined && dragLineKey === null) {
      this.setState({
        shadeAreaTop: chartY,
        mouseOnPlot: true,
      });
    }
  }

  onMouseMove(e: any) {
    if (!e || !e.activePayload || e.activePayload.length === 0) {
      return;
    }
    if (!e || !e.chartY) {
      return; // Ignore the event if 'chartY' is null or undefined
    }
    const { plotHeight, shadeAreaTop, isDragging, dragLineKey, predAreas, dragAreaIndex } =
      this.state;
    const { data } = this.props;
    const dataIndex = Math.round((e.chartY / (plotHeight - 40)) * (data.length - 1));
    if (dataIndex < 0 || dataIndex >= data.length) {
      return; // Ignore the event if the index is out of range
    }
    this.setState(() => ({
      refLine: data[dataIndex]?.depth,
      mlPrediction: data[dataIndex]?.pred,
      ekInterpretation: data[dataIndex]?.manual_control,
      tooltipY: data[dataIndex]?.depth,
      tooltipX: e.chartX,
    }));
    if (shadeAreaTop !== undefined) {
      this.setState({
        shadeAreaBottom: data[dataIndex]?.depth,
        refLine: data[dataIndex]?.depth,
      });
    }

    if (isDragging && dragLineKey !== null) {
      const dataIndex = Math.round((e.chartY / (plotHeight - 40)) * (data.length - 1));
      // Update the position of the dragged reference line and corresponding reference area
      const updatedPredAreas = predAreas.splice(dragAreaIndex); //[...predAreas];
      const area = updatedPredAreas[dragAreaIndex];

      // if (`${area.topIndex}_topLine` === dragLineKey) {
      //   area.topIndex = 1//data[dataIndex]?.depth //+= increment;

      // } else if (`${area.bottomIndex}_bottomLine` === dragLineKey) {
      //   area.bottomIndex = 3//data[dataIndex]?.depth //+= increment;
      // }

      if (shadeAreaTop === undefined) {
        this.setState({
          refLine: data[dataIndex]?.depth,
          ekReferenceAreas: updatedPredAreas,
        });
      }
    }
  }

  onMouseUp = (e: any) => {
    const {
      data,
      shadeAreaBottom,
      shadeAreaTop,
      plotHeight,
      ekReferenceAreas,
      skReferenceAreas,
      selectedGeology,
      selectedInterpretation,
    } = this.state;

    if (!e || !e.chartY) {
      return;
    }

    const dataIndex = Math.round((e.chartY / (plotHeight - 40)) * (data.length - 1));
    const chartY = data[dataIndex]?.depth;

    if (shadeAreaTop !== undefined && shadeAreaBottom !== undefined) {
      let newReferenceAreaTop = shadeAreaTop;
      let newReferenceAreaBottom = shadeAreaBottom;
      let updatedReferenceAreas;

      if (shadeAreaTop > shadeAreaBottom) {
        this.setState({
          refLine: undefined,
          shadeAreaTop: undefined,
          shadeAreaBottom: undefined,
          isDragging: false,
          dragLineKey: null,
          dragAreaIndex: null,
          dragStartY: 0,
        });
        return;
      }

      if (selectedInterpretation === 'egenkontroll' || selectedInterpretation === 'mlModel') {
        updatedReferenceAreas = [...ekReferenceAreas];
      } else {
        updatedReferenceAreas = [...skReferenceAreas];
      }

      if (updatedReferenceAreas.length > 0) {
        for (let i = 0; i < updatedReferenceAreas.length; i++) {
          const area = updatedReferenceAreas[i];
          if (area.topIndex > shadeAreaTop && area.topIndex > shadeAreaBottom) {
            //("Området er over skyggen og blir ikke berørt", i);
            continue;
          } else if (
            area.topIndex < shadeAreaTop &&
            area.bottomIndex < shadeAreaBottom &&
            area.bottomIndex < shadeAreaTop
          ) {
            //("Området er under skyggen og blir ikke berørt", i);
            //("area.topIndex", area.topIndex, "shadeAreaTop", shadeAreaTop);
            continue;
          } else if (area.topIndex >= shadeAreaTop && area.bottomIndex <= shadeAreaBottom) {
            updatedReferenceAreas.splice(i, 1);
            i--;
            //("Skyggen Fanger hele området, sletter området.");
          } else if (area.topIndex > shadeAreaTop && area.bottomIndex > shadeAreaBottom) {
            //Hvis skyggen starter før toppen og avslutter før bunnen
            let newRefArea = {
              topIndex: shadeAreaBottom,
              bottomIndex: area.bottomIndex,
              prediction: area.prediction,
            };
            //("Skyggen starter før toppen og avslutter før bunnen");
            updatedReferenceAreas.splice(i, 1, newRefArea);
          } else if (area.topIndex < shadeAreaTop && area.bottomIndex < shadeAreaBottom) {
            //Hvis skyggen starter etter toppen og avslutter etter bunnen
            let newRefArea = {
              topIndex: area.topIndex,
              bottomIndex: shadeAreaTop,
              prediction: area.prediction,
            };
            //("Skyggen starter etter toppen og avslutter etter bunnen");
            updatedReferenceAreas.splice(i, 1, newRefArea);
          } else if (area.topIndex < shadeAreaTop && area.bottomIndex > shadeAreaBottom) {
            //Hvis skyggen er midt i område
            let newRefAreaTop = {
              topIndex: area.topIndex,
              bottomIndex: shadeAreaTop,
              prediction: area.prediction,
            };
            let newRefAreaBottom = {
              topIndex: shadeAreaBottom,
              bottomIndex: area.bottomIndex,
              prediction: area.prediction,
            };
            //("Skyggen er i midten av området");
            updatedReferenceAreas.splice(i, 1, newRefAreaTop, newRefAreaBottom);
            break;
          }
        }
      }

      let newAreaPred;
      // ('selectedGeology', selectedGeology);
      if (selectedGeology === 'Other') {
        newAreaPred = this.state.customSelectedGeology;
        // ('newAreaPred', newAreaPred);
      } else {
        newAreaPred = selectedGeology;
      }
      const newReferenceArea = {
        topIndex: newReferenceAreaTop,
        bottomIndex: newReferenceAreaBottom,
        prediction: newAreaPred,
      };
      updatedReferenceAreas.push(newReferenceArea);

      // if (selectedInterpretation === 'egenkontroll' || selectedInterpretation === 'mlModel') {
      //   this.setState({ selectedInterpretation: 'egenkontroll' });
      // }
      if (selectedInterpretation === 'egenkontroll') {
        this.checkIfReferenceAreasAreEqual(ekReferenceAreas, skReferenceAreas);
        this.setState({
          selectedInterpretation: selectedInterpretation,
          ekReferenceAreas: updatedReferenceAreas,
          refLine: undefined,
          shadeAreaTop: undefined,
          shadeAreaBottom: undefined,
          isDragging: false,
          dragLineKey: null,
          dragAreaIndex: null,
          dragStartY: 0,
          mouseOnPlot: false,
        });
      } else if (selectedInterpretation === 'sidemannskontroll') {
        this.setState({
          selectedInterpretation: selectedInterpretation,
          skReferenceAreas: updatedReferenceAreas,
          refLine: undefined,
          shadeAreaTop: undefined,
          shadeAreaBottom: undefined,
          isDragging: false,
          dragLineKey: null,
          dragAreaIndex: null,
          dragStartY: 0,
          mouseOnPlot: false,
        });
      } else {
        this.setState({
          selectedInterpretation: 'egenkontroll',
          ekReferenceAreas: updatedReferenceAreas,
          refLine: undefined,
          shadeAreaTop: undefined,
          shadeAreaBottom: undefined,
          isDragging: false,
          dragLineKey: null,
          dragAreaIndex: null,
          dragStartY: 0,
          mouseOnPlot: false,
        });
      }
    }
  };

  handleUndo = () => {
    const { ekReferenceAreas, skReferenceAreas, selectedInterpretation } = this.state;

    if (selectedInterpretation === 'egenkontroll' && ekReferenceAreas.length > 0) {
      const updatedekReferenceAreas = ekReferenceAreas.slice(0, -1); // Remove the last reference area
      this.setState({
        ekReferenceAreas: updatedekReferenceAreas,
      });
    } else if (selectedInterpretation === 'sidemannskontroll' && skReferenceAreas.length > 0) {
      const updatedskReferenceAreas = skReferenceAreas.slice(0, -1); // Remove the last reference area
      this.setState({
        skReferenceAreas: updatedskReferenceAreas,
      });
    }
  };

  handleClear = () => {
    const { ekReferenceAreas, skReferenceAreas, selectedInterpretation } = this.state;

    if (selectedInterpretation === 'egenkontroll' && ekReferenceAreas.length > 0) {
      const updatedEkReferenceAreas: any = [];
      this.setState({
        ekReferenceAreas: updatedEkReferenceAreas,
      });
    } else if (selectedInterpretation === 'sidemannskontroll' && skReferenceAreas.length > 0) {
      const updatedSkReferenceAreas: any = [];
      this.setState({
        skReferenceAreas: updatedSkReferenceAreas,
      });
    }
  };

  handleGeologyOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGeology = event.target.value;
    this.setState({
      selectedGeology,
      selectedCustomGeology: '',
    });
  };

  handleCustomGeologyOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCustomGeology = event.target.value;
    this.setState({
      selectedGeology: 'Other',
      customSelectedGeology: selectedCustomGeology, // Make sure the state variable matches the one in your state
    });
  };

  handleInterpretationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedInterpretation = event.target.value;

    this.setState({ selectedInterpretation }, () => {
      if (
        selectedInterpretation === 'sidemannskontroll' &&
        this.state.skReferenceAreas.length === 0
      ) {
        // ('Switching to sidemannskontroll, kopier EK hvis tom');
        this.handleCopyInterpretation();
      }
    });
  };

  handleCopyMLInterpretation = () => {
    const { predAreas, selectedInterpretation } = this.state;

    if (selectedInterpretation === 'egenkontroll' && predAreas.length > 0) {
      const updatedEkReferenceAreas = [...predAreas];
      this.setState({ ekReferenceAreas: updatedEkReferenceAreas });
    }

    if (selectedInterpretation === 'sidemannskontroll' && predAreas.length > 0) {
      const updatedSkReferenceAreas = [...predAreas];
      this.setState({ skReferenceAreas: updatedSkReferenceAreas });
    }
  };

  handleCopyInterpretation = () => {
    const { ekReferenceAreas, selectedInterpretation } = this.state;

    if (selectedInterpretation === 'egenkontroll' && this.state.skReferenceAreas.length > 0) {
      const updatedEkReferenceAreas = [...this.state.skReferenceAreas];
      this.setState({ ekReferenceAreas: updatedEkReferenceAreas });
    }

    if (selectedInterpretation === 'sidemannskontroll' && ekReferenceAreas.length > 0) {
      // ('Copying EK to SK');
      const updatedSkReferenceAreas = [...ekReferenceAreas];
      this.setState({ skReferenceAreas: updatedSkReferenceAreas });
    }
  };

  listsAreEqual(list1: any, list2: any) {
    if (list1.length !== list2.length) {
      return false;
    }

    // Sort the lists to ensure elements are in the same order
    const sortedList1 = list1.slice().sort();
    const sortedList2 = list2.slice().sort();

    for (let i = 0; i < sortedList1.length; i++) {
      if (sortedList1[i] !== sortedList2[i]) {
        return false;
      }
    }

    return true;
  }

  checkIfReferenceAreasAreEqual(ekReferenceAreas: any, skReferenceAreas: any) {
    if (ekReferenceAreas.length !== 0 && skReferenceAreas.length !== 0) {
      if (!this.listsAreEqual(ekReferenceAreas, skReferenceAreas)) {
        this.setState({ selectedStatus: 'Påbegynt' });
      } else {
        this.setState({ selectedStatus: 'Godkjent' });
      }
    }
  }

  handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStatus = event.target.value;
    const { skReferenceAreas, ekReferenceAreas } = this.state;
    if (ekReferenceAreas.length === 0) {
      this.setState({ selectedStatus: 'Påbegynt' });
    } else if (skReferenceAreas.length === 0) {
      this.setState({ selectedStatus: 'Til kontroll' });
    } else if (!this.listsAreEqual(skReferenceAreas, ekReferenceAreas)) {
      this.setState({ selectedStatus: 'Til kontroll' });
    } else
      this.setState({
        selectedStatus,
      });
  };

  createAvgPenetrationTimeColumn(data: any): number[] {
    const result = [...data];
    const depthInterval = data[1].depth - data[0].depth; // [m]
    const averageHeight = 0.2; // meters
    const averageNthTimes = Math.floor(averageHeight / depthInterval);

    for (let i = 0; i < data.length; i += averageNthTimes) {
      const inputStart = i;
      const inputStop = Math.min(i + averageNthTimes, data.length);
      const sumPenetrationTime = data
        .slice(inputStart, inputStop)
        .reduce((sum: number, value: any) => sum + value.penetration_time, 0);
      const avgPenetrationTime = sumPenetrationTime / (inputStop - inputStart);

      for (let j = inputStart; j < inputStop; j++) {
        result[j].avg_penetration_time = avgPenetrationTime;
        data[j].penetration_time = avgPenetrationTime;
      }
    }

    return result;
  }

  handleSaveInterpretation = (filename: string, selectedStatus: string) => {
    //const { clickedSave } = this.state;
    let statusText = selectedStatus
    if (!statusText) {
      statusText = 'Ikke tolket'
    }
    this.setState({ clickedSave: 'Lagret!' });
    setTimeout(() => {
      this.setState({ clickedSave: 'Lagre tolkning' });
    }, 1000);
  };

  render() {
    const {
      data,
      left,
      right,
      top,
      bottom,
      shadeAreaTop,
      shadeAreaBottom,
      refLine,
      plotHeight,
      ekReferenceAreas,
      skReferenceAreas,
      selectedGeology,
      customSelectedGeology,
      lineStrokeWidth,
      predAreas,
      quickqlayPredAreas,
      isDraggingPredArea,
      tooltipY,
      tooltipX,
      mlPrediction,
      selectedInterpretation,
      nrOfSnds,
      usedFlushing,
      usedHammering,
      usedRotation,
      selectedProject,
      hasStoredManualControl,
      hasStoredFinalControl,
      toPdf,
      selectedStatus,
      filename,
      fetchAuthenticated,
    } = this.state;

    const hoverBuffer = 10;

    const tooltipStyle: React.CSSProperties = {
      position: 'absolute',
      //left: `${(tooltipX + 20) * nrOfSnds}px`, // Adjust the position as needed
      left: this.props.showFullPlot ? '630px' : '340px',
      top: `${tooltipY * 28.5}px`, // Adjust the position based on the y-coordinate value
      backgroundColor: 'white',
      padding: '1px',
      border: '1px solid black',
      fontSize: '14px',
      display: 'flex',
      flexDirection: 'column',
    };
    const startValue = 0;
    const maxDepth = data[data.length - 1].depth;
    const step = 1;

    const depthTicks = [];
    depthTicks.push(startValue);
    for (let value = startValue; value <= maxDepth; value += step) {
      depthTicks.push(value);
    }

    const hammeringAndFlushingPlotWidth = 200;
    const averagePenetrationTime = this.createAvgPenetrationTimeColumn(data);
    // this.drawMlPredictions();

    return (
      <div
        className="total-sounding-plot"
        onMouseDown={this.handleMouseDownOutsidePlot.bind(this)}
        onMouseUp={this.handleMouseUpOutsidePlot.bind(this)}
      >
        <div className="buttons-and-dropdown">
          <div className="buttons">
            <div className="two-and-two-buttons">
              <button type="button" className="btn-update" onClick={this.handleUndo.bind(this)}>
                Angre
              </button>
              <button type="button" className="btn-update" onClick={this.handleClear.bind(this)}>
                Slett tolkning
              </button>
            </div>
            <div className="two-and-two-buttons">
              <button
                type="button"
                className="btn-update"
                onClick={this.drawMlPredictions.bind(this)}
              >
                Kjør Geobutler
              </button>
              <button
                type="button"
                className="btn-update"
                onClick={() => {
                  useSendInterpretationsToBackend(
                    data[0].borehole.split('~')[0],
                    selectedProject,
                    ekReferenceAreas,
                    skReferenceAreas,
                    selectedStatus,
                    fetchAuthenticated
                  );
                  this.handleSaveInterpretation(filename, selectedStatus);
                  this.updateSelectedFilenames();
                }}
              >
                {this.state.clickedSave}
              </button>
            </div>
            <div className="two-and-two-buttons">
              <button
                type="button"
                className="btn-update"
                onClick={this.handleCopyMLInterpretation.bind(this)}
              >
                Kopier ml-tolkning
              </button>
              {selectedInterpretation === 'egenkontroll' ? (
                <button
                  type="button"
                  className="btn-update"
                  onClick={this.handleCopyInterpretation.bind(this)}
                  style={{ fontSize: '12px' }}
                >
                  Kopier sidemannskontroll
                </button>
              ) : (
                selectedInterpretation === 'sidemannskontroll' && (
                  <button
                    type="button"
                    className="btn-update"
                    onClick={this.handleCopyInterpretation.bind(this)}
                  >
                    Kopier egenkontroll
                  </button>
                )
              )}
            </div>
            <div className="two-and-two-buttons">
              {(selectedInterpretation === 'egenkontroll' && hasStoredManualControl) ||
              (selectedInterpretation === 'sidemannskontroll' && hasStoredFinalControl) ? (
                <button
                  type="button"
                  className="btn-update"
                  onClick={this.drawManualOrFinalControl.bind(this)}
                >
                  Tegn lagret tolkning
                </button>
              ) : null}
            </div>
          </div>
          <div
            className="dropdowns"
            style={
              this.props.showFullPlot
                ? { display: 'flex', flexDirection: 'row' }
                : { display: 'flex', flexDirection: 'column' }
            }
          >
            <div
              className="plot-and-label"
              style={
                this.props.showFullPlot
                  ? { display: 'flex', flexDirection: 'row' }
                  : { display: 'flex', flexDirection: 'column' }
              }
            >
              <label htmlFor="plotSelect"> Velg plot: </label>
              <select
                id="plotSelect"
                value={selectedInterpretation}
                onChange={this.handleInterpretationChange}
                style={{
                  border: '1px solid black',
                  color: 'black',
                  backgroundColor: 'lightgrey',
                  width: '150px',
                  borderRadius: '5px',
                  padding: '2px',
                }}
              >
                <option value="mlModel">Geobutler</option>
                <option value="egenkontroll">Tolkning</option>
                <option value="sidemannskontroll">Sidemannskontroll</option>
              </select>
            </div>

            <div
              className="plot-and-label"
              style={
                this.props.showFullPlot
                  ? { display: 'flex', flexDirection: 'row' }
                  : { display: 'flex', flexDirection: 'column' }
              }
            >
              <label htmlFor="geologySelect"> Tolk materiale: </label>
              <select
                id="geologySelect"
                value={selectedGeology}
                onChange={this.handleGeologyOptionChange}
                style={{
                  border: '1px solid black',
                  color: 'black',
                  backgroundColor: 'lightgrey',
                  width: '150px',
                  borderRadius: '5px',
                  padding: '2px',
                }}
              >
                <option
                  value="Topplag"
                  style={{ backgroundColor: optionColors['Topplag'], color: 'white' }}
                >
                  Topplag
                </option>
                <option
                  value="Kohesjon"
                  style={{ backgroundColor: optionColors['Kohesjon'], color: 'black' }}
                >
                  Kohesjon
                </option>
                <option
                  value="Friksjonsmateriale"
                  style={{ backgroundColor: optionColors['Friksjonsmateriale'], color: 'black' }}
                >
                  Friksjonsmateriale
                </option>
                <option
                  value="Sprobrudd"
                  style={{ backgroundColor: optionColors['Sprobrudd'], color: 'black' }}
                >
                  Sprobrudd
                </option>
                <option
                  value="Berg"
                  style={{ backgroundColor: optionColors['Berg'], color: 'white' }}
                >
                  Berg
                </option>

                <option value="Other">Mer..</option>
              </select>

              {selectedGeology === 'Other' && (
                <select
                  id="geologySelect"
                  value={customSelectedGeology}
                  onChange={this.handleCustomGeologyOptionChange}
                  style={{
                    border: '1px solid black',
                    color: 'black',
                    backgroundColor: 'lightgrey',
                    width: '150px',
                    borderRadius: '5px',
                    padding: '2px',
                  }}
                >
                  <option
                    value="Torv"
                    style={{ backgroundColor: optionColors['Torv'], color: 'black' }}
                  >
                    Torv
                  </option>
                  <option
                    value="Sand"
                    style={{ backgroundColor: optionColors['Sand'], color: 'black' }}
                  >
                    Sand
                  </option>
                  <option
                    value="Silt"
                    style={{ backgroundColor: optionColors['Silt'], color: 'black' }}
                  >
                    Silt
                  </option>
                  <option
                    value="Leire"
                    style={{ backgroundColor: optionColors['Leire'], color: 'black' }}
                  >
                    Leire
                  </option>
                  <option
                    value="Grus"
                    style={{ backgroundColor: optionColors['Grus'], color: 'black' }}
                  >
                    Grus
                  </option>

                  <option
                    value="Blokk"
                    style={{ backgroundColor: optionColors['Blokk'], color: 'white' }}
                  >
                    Blokk
                  </option>
                  <option
                    value="Morene"
                    style={{ backgroundColor: optionColors['Morene'], color: 'black' }}
                  >
                    Morene
                  </option>

                  <option
                    value="Udefinert"
                    style={{ backgroundColor: optionColors['Udefinert'], color: 'white' }}
                  >
                    Udefinert
                  </option>

                  <option value="Egendefinert">Egendefinert</option>
                </select>
              )}
            </div>
            <div
              className="status"
              style={
                this.props.showFullPlot
                  ? { display: 'flex', flexDirection: 'row' }
                  : { display: 'flex', flexDirection: 'column' }
              }
            >
              <label htmlFor="statusSelect"> Status: </label>
              <select
                id="statusSelect"
                value={selectedStatus}
                onChange={this.handleStatusChange}
                style={{
                  border: '1px solid black',
                  color: 'black',
                  backgroundColor: 'lightgrey',
                  width: '150px',
                  borderRadius: '5px',
                  padding: '2px',
                }}
              >
                <option value="Påbegynt">Påbegynt</option>
                <option value="Til kontroll">Til kontroll</option>
                <option value="Godkjent">Godkjent</option>
              </select>
            </div>
          </div>
        </div>
        <div className="plot text">
          <h1
            style={{
              fontSize: '15px',
              paddingLeft: this.props.showFullPlot ? '200px' : '50px',
              marginBottom: '0px',
              userSelect: 'none',
            }}
          >
            {'TOT: '}
            {data[0].borehole.split('~')[0]}{' '}
            {'Kote: ' + parseFloat(data[0].coordinate_z).toFixed(1) + ' m'}
          </h1>
          <div
            className="legend"
            style={{ display: 'flex', marginLeft: this.props.showFullPlot ? '30px' : '5px' }}
          >
            <div className="legend-item">
              <div
                className="legend-item-color"
                id="topplag"
                style={{ backgroundColor: optionColors['Topplag'] }}
              ></div>
              <div className="legend-item-text">{'Topplag'}</div>
            </div>
            <div className="legend-item">
              <div
                id="kohesjon"
                className="legend-item-color"
                style={{ backgroundColor: optionColors['Kohesjon'] }}
              ></div>
              <div className="legend-item-text">{'Kohesjon'}</div>
            </div>
            <div className="legend-item">
              <div
                id="friksjonsmateriale"
                className="legend-item-color"
                style={{ backgroundColor: optionColors['Friksjonsmateriale'] }}
              ></div>
              <div className="legend-item-text">{'Friksjonsmateriale'}</div>
            </div>
            <div className="legend-item">
              <div
                id="sprobrudd"
                className="legend-item-color"
                style={{ backgroundColor: optionColors['Sprobrudd'] }}
              ></div>
              <div className="legend-item-text">{'Sprobrudd'}</div>
            </div>
            <div className="legend-item">
              <div
                id="berg"
                className="legend-item-color"
                style={{ backgroundColor: optionColors['Berg'] }}
              ></div>
              <div className="legend-item-text">{'Berg'}</div>
            </div>
          </div>

          {selectedGeology === 'Other' && (
            <div
              className="legend"
              style={{ display: 'flex', marginLeft: '30px', userSelect: 'none' }}
            >
              <div className="legend-item">
                <div
                  id="Torv"
                  className="legend-item-color"
                  style={{ backgroundColor: optionColors['Torv'] }}
                ></div>
                <div className="legend-item-text">{'Torv'}</div>
              </div>
              <div className="legend-item">
                <div
                  id="Sand"
                  className="legend-item-color"
                  style={{ backgroundColor: optionColors['Sand'] }}
                ></div>
                <div className="legend-item-text">{'Sand'}</div>
              </div>
              <div className="legend-item">
                <div
                  id="Silt"
                  className="legend-item-color"
                  style={{ backgroundColor: optionColors['Silt'] }}
                ></div>
                <div className="legend-item-text">{'Silt'}</div>
              </div>
              <div className="legend-item">
                <div
                  id="Leire"
                  className="legend-item-color"
                  style={{ backgroundColor: optionColors['Leire'] }}
                ></div>
                <div className="legend-item-text">{'Leire'}</div>
              </div>
              <div className="legend-item">
                <div
                  id="Grus"
                  className="legend-item-color"
                  style={{ backgroundColor: optionColors['Grus'] }}
                ></div>
                <div className="legend-item-text">{'Grus'}</div>
              </div>
              <div className="legend-item">
                <div
                  id="Blokk"
                  className="legend-item-color"
                  style={{ backgroundColor: optionColors['Blokk'] }}
                ></div>
                <div className="legend-item-text">{'Blokk'}</div>
              </div>
              <div className="legend-item">
                <div
                  id="Morene"
                  className="legend-item-color"
                  style={{ backgroundColor: optionColors['Morene'] }}
                ></div>
                <div className="legend-item-text">{'Morene'}</div>
              </div>
              <div
                id="Udefinert"
                className="legend-item-color"
                style={{ backgroundColor: optionColors['Udefinert'] }}
              ></div>
              <div className="legend-item-text">{'Udefinert'}</div>
            </div>
          )}

          {selectedInterpretation === 'egenkontroll' && this.props.splittedWiews && (
            <div className="interpretation-labels" style={{ display: 'flex' }}>
              <h3
                style={{
                  fontSize: '10px',
                  paddingLeft: this.props.showFullPlot ? '360px' : '70px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                {'Tolkning'}
              </h3>
              <h3
                style={{
                  fontSize: '10px',
                  paddingLeft: '60px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                {'Sidemannskontroll'}
              </h3>
              <h3
                style={{
                  fontSize: '10px',
                  paddingLeft: '15px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                {'Geobutler'}
              </h3>
            </div>
          )}
          {selectedInterpretation === 'sidemannskontroll' && this.props.splittedWiews && (
            <div className="interpretation-labels" style={{ display: 'flex' }}>
              <h3
                style={{
                  fontSize: '10px',
                  paddingLeft: '340px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                {'Sidemannskontroll'}
              </h3>
              <h3
                style={{
                  fontSize: '10px',
                  paddingLeft: '60px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                {'Tolkning'}
              </h3>
              <h3
                style={{
                  fontSize: '10px',
                  paddingLeft: '35px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                {'Geobutler'}
              </h3>
            </div>
          )}
        </div>
        <div className="plots-container">
          <div
            className="plots"
            style={{
              display: 'flex',
              marginTop: '5px',
              flexDirection: 'row',
              transform: 'scale(1)',
              transformOrigin: 'top left',
            }}
          >
            {this.props.showFullPlot && (
              <>
                <div className="time-and-pressure">
                  <div className="time-diagram">
                    <LineChart
                      width={plotHeight + 90}
                      height={hammeringAndFlushingPlotWidth}
                      data={data} // Use the dataset from the component's state
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        allowDataOverflow
                        dataKey="depth"
                        orientation="bottom"
                        domain={[left, right]}
                        style={{ userSelect: 'none' }}
                        type="number"
                        tick={({ x, payload }: any) => (
                          <Text x={x + 10} y={200} angle={-90} fontSize={20}>
                            {payload.value % 5 === 0 ? payload.value : ''}
                          </Text>
                        )}
                        ticks={depthTicks}
                      >
                        {/* <Label value="Bortid" position="insideBottom" offset={-5} /> */}
                      </XAxis>

                      <YAxis
                        yAxisId="rightBortid"
                        allowDataOverflow
                        domain={[0, 300]}
                        type="number"
                        style={{ userSelect: 'none' }}
                        reversed={true}
                        orientation="right"
                        tick={({ y, payload }: any) => (
                          <Text x={plotHeight - 15} y={y + 10} angle={-90}>
                            {payload.value}
                          </Text>
                        )}
                        ticks={[0, 100, 200, 300]}
                      >
                        <Label
                          value="Bortid, s/m"
                          angle={-90}
                          position="outside"
                          style={{ textAnchor: 'middle', userSelect: 'none' }}
                        />
                      </YAxis>
                      <YAxis
                        yAxisId="leftSpyletrykk"
                        allowDataOverflow
                        domain={[0, 3000]}
                        type="number"
                        reversed={false}
                        orientation="right"
                        tick={({ y, payload }: any) => (
                          <Text x={plotHeight + 20} y={y} angle={-90}>
                            {payload.value / 1000}
                          </Text>
                        )}
                        ticks={[0, 1000, 2000, 3000]}
                        style={{ userSelect: 'none' }}
                      >
                        <Label
                          value="Spyletrykk, MPa"
                          angle={-90}
                          style={{ marginTop: '10px', userSelect: 'none' }}
                        />
                      </YAxis>
                      <Line
                        dataKey="flushing_pressure"
                        yAxisId="leftSpyletrykk"
                        name="Spyletrykk"
                        animationDuration={300}
                        dot={false} // Set dot prop to false to remove circle symbols
                        strokeWidth={1.5} // Set strokeWidth to control the line thickness
                        stroke="blue"
                      />
                      <Line
                        dataKey="avg_penetration_time"
                        name="Bortid"
                        yAxisId="rightBortid"
                        animationDuration={300}
                        dot={false} // Set dot prop to false to remove circle symbols
                        strokeWidth={1.5} // Set strokeWidth to control the line thickness
                        stroke="red"
                      />
                    </LineChart>
                  </div>
                </div>
                <div className="slag-og-spyl" style={{ display: 'flex', gap: 0 }}>
                  <div style={{ display: 'flex', gap: 0 }}>
                    <div className="spyl" style={{ flex: 1, marginRight: -5 }}>
                      <LineChart
                        width={50}
                        height={plotHeight}
                        data={data} // Use the dataset from the component's state
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="Y"
                          tick={({ x, y, payload }: any) => (
                            <Text x={x} y={y}>
                              {''}
                            </Text>
                          )}
                          style={{ userSelect: 'none' }}
                          ticks={[0]}
                          tickLine={false}
                        >
                          <Label
                            value="Spyling"
                            position="bottom"
                            angle={-90}
                            offset={-70}
                            style={{
                              fontSize: 20,
                              fontWeight: 'bold',
                              overflow: 'visible',
                              userSelect: 'none',
                            }}
                          />
                        </XAxis>

                        <YAxis
                          //allowDataOverflow
                          domain={[top, bottom]}
                          type="number"
                          reversed={true}
                          ticks={[0]}
                          width={0}
                          style={{ userSelect: 'none' }}
                        />
                        <Line
                          dataKey="depth"
                          dot={false} // Set dot prop to false to remove circle symbols
                          strokeWidth={0} // Set strokeWidth to control the line thickness
                        />
                        {usedFlushing.length > 0 &&
                          usedFlushing.map((area: any, index: number) => (
                            <ReferenceArea
                              key={`flushing_${index}`}
                              y1={area.topIndex}
                              y2={area.bottomIndex}
                              strokeOpacity={1}
                              fill={'blue'}
                            />
                          ))}
                      </LineChart>
                    </div>
                    <div className="slag" style={{ flex: 1, marginLeft: -5 }}>
                      <LineChart
                        width={50}
                        height={plotHeight}
                        data={data} // Use the dataset from the component's state
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="S"
                          tick={({ x, y, payload }: any) => (
                            <Text x={x} y={y}>
                              {''}
                            </Text>
                          )}
                          ticks={[0]}
                          tickLine={false}
                          style={{ userSelect: 'none' }}
                        >
                          <Label
                            value="Slagboring"
                            position="bottom"
                            angle={-90}
                            offset={-90}
                            style={{ fontSize: 20, fontWeight: 'bold', userSelect: 'none' }}
                          />
                        </XAxis>

                        <YAxis domain={[top, bottom]} reversed={true} ticks={[0]} width={0} />
                        <Line
                          dataKey="depth"
                          dot={false} // Set dot prop to false to remove circle symbols
                          strokeWidth={0} // Set strokeWidth to control the line thickness
                          style={{ userSelect: 'none' }}
                        />
                        {usedHammering.length > 0 &&
                          usedHammering.map((area: any, index: number) => (
                            <ReferenceArea
                              key={`hammering_${index}`}
                              y1={area.topIndex}
                              y2={area.bottomIndex}
                              strokeOpacity={1}
                              fill={'red'}
                            />
                          ))}
                      </LineChart>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="pressure" style={{ display: 'flex', marginRight: '10px' }}>
              <div className="0-10kN" style={{ marginRight: '-5px' }}>
                <LineChart
                  width={180}
                  height={plotHeight}
                  data={data} // Use the dataset from the component's state
                  onMouseDown={(e: any) => this.onMouseDown(e)}
                  onMouseMove={(e: any) => this.onMouseMove(e)}
                  onMouseUp={(e: any) => this.onMouseUp(e)}
                  onMouseEnter={(e: any) => this.onMouseEnter(e)}
                  onMouseLeave={(e: any) => this.onMouseLeave(e)}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    allowDataOverflow
                    dataKey="penetration_force"
                    domain={[0, 10000]}
                    type="number"
                    tick={({ x, y, payload }: any) => (
                      <Text x={x - 5} y={y + 10} style={{ userSelect: 'none' }}>
                        {payload.value / 1000}
                      </Text>
                    )}
                    ticks={[0, 5000, 10000]}
                    style={{ userSelect: 'none' }}
                  >
                    <Label
                      value="F_DT  kN"
                      position="bottom"
                      offset={-10}
                      style={{ userSelect: 'none' }}
                    />
                  </XAxis>

                  <YAxis
                    hide={true}
                    allowDataOverflow
                    domain={[top, bottom]}
                    type="number"
                    reversed={true}
                    tick={{ display: 'none' }}
                    // orientation={this.props.showFullPlot ? 'right' : 'left'}
                    orientation="left"
                    style={{ userSelect: 'none' }}
                    ticks={depthTicks}
                  />

                  <Line
                    dataKey="depth"
                    animationDuration={300}
                    dot={false} // Set dot prop to false to remove circle symbols
                    strokeWidth={1.5} // Set strokeWidth to control the line thickness
                    stroke="black"
                  />

                  {selectedInterpretation === 'egenkontroll' &&
                    ekReferenceAreas.map((area: any, index: number) => (
                      <ReferenceArea
                        key={`ek-0-10_${index}`}
                        y1={area.topIndex}
                        y2={area.bottomIndex}
                        fillOpacity={0.5}
                        fill={optionColors[area.prediction]}
                      />
                    ))}

                  {selectedInterpretation === 'sidemannskontroll' &&
                    skReferenceAreas.map((area: any, index: number) => (
                      <ReferenceArea
                        key={`sk-0-10_${index}`}
                        y1={area.topIndex}
                        y2={area.bottomIndex}
                        fillOpacity={0.5}
                        fill={optionColors[area.prediction]}
                      />
                    ))}

                  {selectedInterpretation === 'mlModel' && !this.props.showQuickClay
                    ? predAreas.map((predArea: any, index: number) => (
                        <ReferenceArea
                          key={`pred-0-10_${index}`}
                          y1={predArea.topIndex}
                          y2={predArea.bottomIndex}
                          fillOpacity={0.5}
                          fill={optionColors[predArea.prediction]}
                        />
                      ))
                    : selectedInterpretation === 'mlModel' &&
                      quickqlayPredAreas.map((quickqlayPredArea: any, index: number) => (
                        <ReferenceArea
                          key={`quickclaypred_${index}`}
                          y1={quickqlayPredArea.topIndex}
                          y2={quickqlayPredArea.bottomIndex}
                          fillOpacity={0.5}
                          fill={optionColors[quickqlayPredArea.prediction]}
                        />
                      ))}
                  {usedRotation.length > 0 &&
                    usedRotation.map((rotationArea: any, index: number) => (
                      <>
                        <ReferenceArea
                          key={`rotationArea_${index}`}
                          y1={rotationArea.topIndex}
                          y2={rotationArea.bottomIndex}
                          fillOpacity={0.2}
                          fill={'grey'}
                          x1={20000}
                          x2={30000}
                        />
                        <ReferenceLine
                          key={`rotationLineLeft_${index}`}
                          stroke="black"
                          strokeWidth={1}
                          segment={[
                            { x: 20000, y: rotationArea.topIndex },
                            { x: 30000, y: rotationArea.bottomIndex },
                          ]}
                        />
                        <ReferenceLine
                          key={`rotationLineRight_${index}`}
                          stroke="black"
                          strokeWidth={1}
                          segment={[
                            { x: 30000, y: rotationArea.topIndex },
                            { x: 20000, y: rotationArea.bottomIndex },
                          ]}
                        />
                      </>
                    ))}

                  {shadeAreaTop && shadeAreaBottom ? (
                    <ReferenceArea
                      key={'shade-0-10'}
                      y1={shadeAreaTop}
                      y2={shadeAreaBottom}
                      fillOpacity={0.5}
                    />
                  ) : null}

                  <ReferenceLine
                    y={refLine}
                    stroke="blue"
                    strokeWidth={0.5}
                    strokeDasharray="3 3"
                  />
                </LineChart>
              </div>
              <div className="10-30kN" style={{ marginLeft: '-5px' }}>
                <LineChart
                  width={235}
                  height={plotHeight}
                  data={data} // Use the dataset from the component's state
                  onMouseDown={(e: any) => this.onMouseDown(e)}
                  onMouseMove={(e: any) => this.onMouseMove(e)}
                  onMouseUp={(e: any) => this.onMouseUp(e)}
                  onMouseEnter={(e: any) => this.onMouseEnter(e)}
                  onMouseLeave={(e: any) => this.onMouseLeave(e)}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    allowDataOverflow
                    dataKey="penetration_force"
                    domain={[10000, 30000]}
                    type="number"
                    tick={({ x, y, payload }: any) => (
                      <Text x={x - 5} y={y + 10} style={{ userSelect: 'none' }}>
                        {payload.value / 1000}
                      </Text>
                    )}
                    ticks={[10000, 20000, 30000]}
                    style={{ userSelect: 'none' }}
                  >
                    {/* <Label value="F_DT  kN" position="left" offset={-10} /> */}
                  </XAxis>
                  <YAxis
                    allowDataOverflow
                    domain={[top, bottom]}
                    type="number"
                    reversed={true}
                    ticks={depthTicks}
                    tick={({ x, y, payload }: any) => (
                      <Text x={x} y={y + 5} style={{ userSelect: 'none' }}>
                        {payload.value % 5 === 0 ? payload.value : ''}
                      </Text>
                    )}
                    orientation={'right'}
                    style={{ userSelect: 'none' }}
                  />
                  <Line
                    dataKey="depth"
                    animationDuration={300}
                    dot={false} // Set dot prop to false to remove circle symbols
                    strokeWidth={1.5} // Set strokeWidth to control the line thickness
                    stroke="black"
                  />
                  {selectedInterpretation === 'egenkontroll' &&
                    !this.props.splittedWiews &&
                    ekReferenceAreas.map((area: any, index: number) => (
                      <ReferenceArea
                        key={`ek-10-30${index}`}
                        y1={area.topIndex}
                        y2={area.bottomIndex}
                        fillOpacity={0.5}
                        fill={optionColors[area.prediction]}
                      />
                    ))}
                  {selectedInterpretation === 'sidemannskontroll' &&
                    !this.props.splittedWiews &&
                    skReferenceAreas.map((area: any, index: number) => (
                      <ReferenceArea
                        key={`sk-10-30-${index}`}
                        y1={area.topIndex}
                        y2={area.bottomIndex}
                        fillOpacity={0.5}
                        fill={optionColors[area.prediction]}
                      />
                    ))}
                  {selectedInterpretation === 'egenkontroll' && this.props.splittedWiews && (
                    <>
                      {ekReferenceAreas.map((area: any, index: number) => (
                        <ReferenceArea
                          key={`ek-ek-split-${index}`}
                          y1={area.topIndex}
                          y2={area.bottomIndex}
                          x1={0}
                          x2={10000}
                          fillOpacity={0.5}
                          fill={optionColors[area.prediction]}
                        />
                      ))}
                      {skReferenceAreas.map((area: any, index: number) => (
                        <ReferenceArea
                          key={`ek-sk-split-${index}`}
                          y1={area.topIndex}
                          y2={area.bottomIndex}
                          x1={10000}
                          x2={20000}
                          fillOpacity={0.5}
                          fill={optionColors[area.prediction]}
                        />
                      ))}

                      {!this.props.showQuickClay
                        ? predAreas.map((predArea: any, index: number) => (
                            <ReferenceArea
                              key={`ek-pred-${index}`}
                              y1={predArea.topIndex}
                              y2={predArea.bottomIndex}
                              x1={20000}
                              x2={30000}
                              fillOpacity={0.5}
                              fill={optionColors[predArea.prediction]}
                            />
                          ))
                        : quickqlayPredAreas.map((quickqlayPredArea: any, index: number) => (
                            <ReferenceArea
                              key={`ek-quickclaypred-${index}`}
                              y1={quickqlayPredArea.topIndex}
                              y2={quickqlayPredArea.bottomIndex}
                              x1={20000}
                              x2={30000}
                              fillOpacity={0.5}
                              fill={optionColors[quickqlayPredArea.prediction]}
                            />
                          ))}
                    </>
                  )}

                  {selectedInterpretation === 'sidemannskontroll' && this.props.splittedWiews && (
                    <>
                      {ekReferenceAreas.map((area: any, index: number) => (
                        <ReferenceArea
                          key={`sk-ek-${index}`}
                          y1={area.topIndex}
                          y2={area.bottomIndex}
                          x1={10000}
                          x2={20000}
                          fillOpacity={0.5}
                          fill={optionColors[area.prediction]}
                        />
                      ))}
                      {skReferenceAreas.map((area: any, index: number) => (
                        <ReferenceArea
                          key={`sk-sk${index}`}
                          y1={area.topIndex}
                          y2={area.bottomIndex}
                          x1={0}
                          x2={10000}
                          fillOpacity={0.5}
                          fill={optionColors[area.prediction]}
                        />
                      ))}

                      {!this.props.showQuickClay
                        ? predAreas.map((predArea: any, index: number) => (
                            <ReferenceArea
                              key={`sk-pred_${index}`}
                              y1={predArea.topIndex}
                              y2={predArea.bottomIndex}
                              x1={20000}
                              x2={30000}
                              fillOpacity={0.5}
                              fill={optionColors[predArea.prediction]}
                            />
                          ))
                        : quickqlayPredAreas.map((quickqlayPredArea: any, index: number) => (
                            <ReferenceArea
                              key={`sk-quickclaypred_${index}`}
                              y1={quickqlayPredArea.topIndex}
                              y2={quickqlayPredArea.bottomIndex}
                              x1={20000}
                              x2={30000}
                              fillOpacity={0.5}
                              fill={optionColors[quickqlayPredArea.prediction]}
                            />
                          ))}
                    </>
                  )}

                  {selectedInterpretation === 'mlModel' && !this.props.showQuickClay
                    ? predAreas.map((predArea: any, index: number) => (
                        <ReferenceArea
                          key={`ml-pred_${index}`}
                          y1={predArea.topIndex}
                          y2={predArea.bottomIndex}
                          fillOpacity={0.5}
                          fill={optionColors[predArea.prediction]}
                        />
                      ))
                    : selectedInterpretation === 'mlModel' &&
                      quickqlayPredAreas.map((quickqlayPredArea: any, index: number) => (
                        <ReferenceArea
                          key={`ml-quickclaypred_${index}`}
                          y1={quickqlayPredArea.topIndex}
                          y2={quickqlayPredArea.bottomIndex}
                          fillOpacity={0.5}
                          fill={optionColors[quickqlayPredArea.prediction]}
                        />
                      ))}

                  {usedRotation.length > 0 &&
                    usedRotation.map((rotationArea: any, index: number) => (
                      <>
                        <ReferenceArea
                          key={`rotationArea_${index}`}
                          y1={rotationArea.topIndex}
                          y2={rotationArea.bottomIndex}
                          fillOpacity={0.2}
                          fill={'grey'}
                          x1={20000}
                          x2={30000}
                        />
                        <ReferenceLine
                          key={`rotationLineLeft_${index}`}
                          stroke="black"
                          strokeWidth={1}
                          segment={[
                            { x: 20000, y: rotationArea.topIndex },
                            { x: 30000, y: rotationArea.bottomIndex },
                          ]}
                        />
                        <ReferenceLine
                          key={`rotationLineRight_${index}`}
                          stroke="black"
                          strokeWidth={1}
                          segment={[
                            { x: 30000, y: rotationArea.topIndex },
                            { x: 20000, y: rotationArea.bottomIndex },
                          ]}
                        />
                      </>
                    ))}

                  <ReferenceLine
                    key={`ek-sk`}
                    stroke="grey"
                    strokeWidth={5}
                    segment={[
                      { x: 10000, y: 0 },
                      { x: 10000, y: data[data.length - 1].depth },
                    ]}
                    strokeOpacity={0.2}
                    fillOpacity={0.5}
                  />

                  {this.props.splittedWiews && (
                    <ReferenceLine
                      key={`ek/sk/gb`}
                      stroke="grey"
                      strokeWidth={5}
                      segment={[
                        { x: 20000, y: 0 },
                        { x: 20000, y: data[data.length - 1].depth },
                      ]}
                      strokeOpacity={0.2}
                      fillOpacity={0.5}
                    />
                  )}

                  {shadeAreaTop && shadeAreaBottom ? (
                    <ReferenceArea
                      key={'shade-10-30'}
                      y1={shadeAreaTop}
                      y2={shadeAreaBottom}
                      fillOpacity={0.5}
                    />
                  ) : null}
                  <ReferenceLine
                    y={refLine}
                    stroke="blue"
                    strokeWidth={0.5}
                    strokeDasharray="3 3"
                  />
                </LineChart>
              </div>
            </div>

            {refLine && (
              <div style={{ ...tooltipStyle, userSelect: 'none' }}>
                <div> Y: {tooltipY.toFixed(1)} m </div>
                <div> Pred: {mlPrediction} </div>
              </div>
            )}
          </div>
          <div style={{ marginTop: '100px' }} />
          {/* to create artifical space to fit the doublex-azis of the chart  */}
          {/* <button onClick={this.generatePdf}>Generate PDF</button> */}
        </div>
      </div>
    );
  }
}
