import { createContext, useState, PropsWithChildren } from 'react';
import { MeasureEntry, VersionType } from '../utils/databaseTypes';
import { TableEntry } from '../utils/tableHelper';

export type IntersectCtxType = {
  activeVersion: VersionType | undefined;
  getActiveVersion: () => VersionType | undefined;
  updateActiveVersion: (version: VersionType) => void;
  addIntersectTableEntries: (tableEntries: TableEntry[]) => void;
  updateIntersectTableEntries: (tableEntries: TableEntry[]) => void;
  updateIntersectData: (intersectData: TableEntry[]) => void;
  updateIntersectLayer: (layer: string) => void;
  selectedTableEntries: string[];
  updateSelectedTableEntries: (selectedGraphic: string) => void;
  clearActiveVersion: () => void;
  addIntersectedLayer: (intersectedLayer: string) => void;
  updateInfluenceArea: (coordinates: number[][][]) => void;
  influenceArea: number[][][];
  addToMeasureTable: (measure: MeasureEntry) => void;
};

export const IntersectCtx = createContext<IntersectCtxType>({
  activeVersion: undefined,
  getActiveVersion: () => {
    return undefined;
  },
  updateActiveVersion: (version: VersionType) => {},
  addIntersectTableEntries: (tableEntries: TableEntry[]) => {},
  updateIntersectTableEntries: (tableEntries: TableEntry[]) => {},
  updateIntersectData: (intersectData: TableEntry[]) => {},
  updateIntersectLayer: (layer: string) => {},
  selectedTableEntries: [],
  updateSelectedTableEntries: (selectedGraphic: string) => {},
  clearActiveVersion: () => {},
  addIntersectedLayer: (intersectedLayer: string) => {},
  updateInfluenceArea: (coordinates: number[][][]) => {},
  influenceArea: [[[]]],
  addToMeasureTable: (measure: MeasureEntry) => {},
});

export function IntersectDataCtx(props: PropsWithChildren) {
  const [selectedTableEntries, setSelectedTableEntries] = useState<string[]>([]);
  const [activeVersion, setActiveVersion] = useState<VersionType | undefined>();
  const [influenceArea, setInfluenceArea] = useState<number[][][]>([[[]]]);

  const addIntersectTableEntries = (tableEntries: TableEntry[]): void => {
    activeVersion?.table?.push(...tableEntries);
  };

  const updateIntersectTableEntries = (tableEntries: TableEntry[]): void => {
    if (activeVersion) {
      const updatedActiveVersion = activeVersion;
      updatedActiveVersion.table = tableEntries;
      setActiveVersion(updatedActiveVersion);
    }
  };

  const updateIntersectData = (intersectData: TableEntry[]): void => {
    if (activeVersion?.table) {
      const updatedActiveVersion = activeVersion;
      updatedActiveVersion.table = intersectData;
      setActiveVersion(updatedActiveVersion);
    }
  };

  const updateIntersectLayer = (layer: string): void => {
    if (activeVersion) {
      const updatedActiveVersion = activeVersion;
      updatedActiveVersion.intersectedArea = layer;
      setActiveVersion(updatedActiveVersion);
    }
  };

  const updateSelectedTableEntries = (selectedgraphic: string) => {
    if (selectedTableEntries.includes(selectedgraphic)) {
      setSelectedTableEntries(
        selectedTableEntries.filter((graphic) => graphic !== selectedgraphic)
      );
    } else {
      setSelectedTableEntries((tableSelectedGraphics) => [
        ...tableSelectedGraphics,
        selectedgraphic,
      ]);
    }
  };

  const clearActiveVersion = () => {
    setActiveVersion(undefined);
    setSelectedTableEntries([]);
  };

  const getActiveVersion = (): VersionType | undefined => {
    return activeVersion;
  };

  const updateActiveVersion = (version: VersionType): void => {
    setActiveVersion(version);
  };

  const addIntersectedLayer = (intersectedLayer: string) => {
    if (activeVersion) {
      const updatedActiveVersion = activeVersion;
      updatedActiveVersion.intersectedArea += intersectedLayer;
      setActiveVersion(updatedActiveVersion);
    }
  };

  const updateInfluenceArea = (coordinates: number[][][]) => {
    setInfluenceArea(coordinates);
  };

  const addToMeasureTable = (measure: MeasureEntry) => {
    if (activeVersion) {
      const updateActiveVersion = activeVersion;
      updateActiveVersion.measures.push(measure);
      setActiveVersion(updateActiveVersion);
    }
  };

  return (
    <IntersectCtx.Provider
      value={{
        activeVersion,
        getActiveVersion,
        updateActiveVersion,
        addIntersectTableEntries,
        updateIntersectTableEntries,
        updateIntersectData,
        updateIntersectLayer,
        selectedTableEntries,
        updateSelectedTableEntries,
        clearActiveVersion,
        addIntersectedLayer,
        updateInfluenceArea,
        influenceArea,
        addToMeasureTable,
      }}
    >
      {props.children}
    </IntersectCtx.Provider>
  );
}
