import { LoadingSVG } from './LoadingSVG';
import './ButtonWithIcon.scss';

type Props = {
  isDisabled: boolean;
  isLoading: boolean;
  text: string;
  textLoading?: string;
  onClick: () => void;
};

export function ButtonWithIcon(props: Props) {
  const { isDisabled, isLoading, text, textLoading, onClick } = props;

  return (
    <button className="button" disabled={isDisabled} onClick={onClick}>
      {isLoading ? (
        <div>
          {textLoading} <LoadingSVG />{' '}
        </div>
      ) : (
        text
      )}
    </button>
  );
}
