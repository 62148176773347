/**
 * Triggers a file download in the browser.
 * @param {Blob} blob - The blob data to be downloaded.
 * @param {string} fileName - The desired name for the downloaded file.
 */
export function downloadFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();
  
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  