import { useContext, useEffect, useState } from 'react';
import * as intl from '@arcgis/core/intl';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import esriConfig from '@arcgis/core/config';
import './MapComponent.scss';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { createExpand, createMapView } from '../../utils/mapHelper';
import { IntersectCtx } from '../../context/IntersectDataCtx';
import { useParams } from 'react-router-dom';
import { useProjects } from '../../hooks/useProjects';
import Basemap from '@arcgis/core/Basemap';
import TileLayer from '@arcgis/core/layers/TileLayer';
import { useProjectSndInformation } from '../../hooks/useProjectSndInformation';
import { CreateLinesBetweenPoints } from '../LineProfileDrillingPoint/LineProfileDrillingPoint';
import { TransformCoordinates } from '../../callouts/TransformCoordinates';
import { PlotDrillingPoints } from '../DrillingPointPlotter/DrillingPointPlotter';
import { getAllLineCoordinates } from '../../utils/gisLinesExtractor';
import PlotlyComponentIframe from '../InteractivePlotIframe/InteractivePlotIframe';

async function calculateAllPoints(
  projectInformation: any,
  fetchAuthenticated: any,
  coordinateSystem: string
) {
  try {
    return await TransformCoordinates(projectInformation, coordinateSystem, fetchAuthenticated);
  } catch (error) {
    console.error('Error transforming coordinates:', error);
    return [];
  }
}

export function MapComponent(props: {
  setXLayer: React.Dispatch<React.SetStateAction<FeatureLayer[] | undefined>>;
  selectedOption: string | null;
  projectData: any;
}) {
  const { activeVersion, influenceArea: influenceAreaContext } = useContext(IntersectCtx);

  intl.setLocale('nb'); // Set location to Norway and language to Norwegian
  esriConfig.apiKey = process.env.REACT_APP_API_KEY as string;
  const { projectId } = useParams();
  const { fetchProject } = useProjects();
  const [map, setMap] = useState<Map | undefined>();
  const [view, setView] = useState<MapView | undefined>();
  const [pointsLayer, setPointsLayer] = useState<GraphicsLayer | undefined>();
  const [influenceArea, setInfluenceArea] = useState<number[][][]>([[[]]]);
  const { projectInformation, setProjectInformation } = useProjectSndInformation(
    props.selectedOption
  );

  const [showPlotComponent, setShowPlotComponent] = useState(false); // New state to control PlotlyComponent visibility
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [userDistance, setUserDistance] = useState<number | null>(null);
  const [lineCoordinates, setLineCoordinates] = useState<[number, number][][] | null>(null);
  const [lines, setLines] = useState<number[][][] | null>(null);

  const handleLineClick = (distanceInput: number, lineCoordinates: [number, number][][]) => {
    setUserDistance(distanceInput);
    setShowPlotComponent(true); // Callback to show PlotlyComponent
    setLineCoordinates(lineCoordinates);
  };

  const handleClosePlotly = () => {
    setShowPlotComponent(false);
    setIsFullScreen(false); // Reset full-screen state on close
  };

  useEffect(() => {
    if (projectId) {
      fetchProject(parseInt(projectId)).then((project) => {
        if (project) {
          setInfluenceArea(JSON.parse(project.influenceArea));
        }
      });
    }
  }, []);

  useEffect(() => {
    getAllLineCoordinates().then((data: number[][][] | null) => {
      setLines(data);
    });

    const createBaseMap = () => {
      const map = new Map({
        basemap: new Basemap({
          baseLayers: [
            new TileLayer({
              url: 'https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheBasis/MapServer/',
            }),
          ],
        }),
      });

      const view = createMapView(map, influenceAreaContext[0][0]);
      view.ui.add(createExpand(view), 'bottom-right');

      const newPointsLayer = new GraphicsLayer({ id: 'points' });
      setPointsLayer(newPointsLayer);

      map.add(newPointsLayer, 15);

      setMap(map);
      setView(view);
    };
    createBaseMap();
  }, [activeVersion, influenceArea]);

  useEffect(() => {
    if (map && !activeVersion) {
      map.removeAll();
    }
  }, [activeVersion]);

  return (
    <div className="map-wrapper" id="map">
      {/* Render the PlotDrillingPoints component here */}
      <PlotDrillingPoints
        projectData={props.projectData}
        view={view}
        pointsLayer={pointsLayer}
        projectInformation={projectInformation}
      />
      <CreateLinesBetweenPoints
        view={view}
        projectInformation={projectInformation}
        projectData={props.projectData}
        onLineClick={handleLineClick}
        lines={lines}
      />
      {/* Conditionally render PlotlyComponentIframe */}
      {showPlotComponent && (
        <PlotlyComponentIframe
          onClose={handleClosePlotly}
          distanceInput={userDistance}
          projectNumber={props.projectData['Project Number']}
          lineCoordinates={lineCoordinates}
        />
      )}
    </div>
  );
}
