import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import IdentityManager from '@arcgis/core/identity/IdentityManager';
import Portal from '@arcgis/core/portal/Portal';
import PortalQueryParams from '@arcgis/core/portal/PortalQueryParams';

interface SignInResponse {
  token: string;
}

async function getOAuthInfo(): Promise<OAuthInfo> {
  return new OAuthInfo({
    appId: process.env.REACT_APP_CLOUD_GIS_APP_ID,
    portalUrl: process.env.REACT_APP_CLOUD_GIS_PORTAL_URL,
    flowType: 'authorization-code', // default that uses two-step flow
    popup: false,
  });
}

export async function getCloudgisToken(): Promise<string | null> {
  const info = await getOAuthInfo();

  IdentityManager.registerOAuthInfos([info]);

  try {
    const res: SignInResponse = await IdentityManager.checkSignInStatus(
      info.portalUrl + '/sharing'
    );
    return res.token;
  } catch (err) {
    console.error('Error getting token:', err);
    IdentityManager.getCredential(info.portalUrl + '/sharing');
    return null;
  }
}

export async function getCloudgisLayers(): Promise<any[] | null> {
  const token = await getCloudgisToken();
  if (!token) return null;

  const portal = new Portal({
    url: process.env.REACT_APP_CLOUD_GIS_PORTAL_URL,
  });

  portal.authMode = 'immediate';

  try {
    await portal.load();
    const queryParams = new PortalQueryParams({
      query: 'tags: Geobutler AND type: Feature Service',
    });
    const response = await portal.queryItems(queryParams);
    return response.results;
  } catch (err) {
    console.error('Error fetching layers:', err);
    return null;
  }
}

export async function getAllLineCoordinates(): Promise<number[][][] | null> {
  const BACKEND_URL = process.env.REACT_APP_HOST + 'geobutler/gis';
  try {
    const tokenGis = await getCloudgisToken();
    if (!tokenGis) {
      console.error('Failed to fetch token');
      return null;
    }
    const layers = await getCloudgisLayers();
    if (!layers) {
      console.error('Failed to fetch layers');
      return null;
    }

    const layerDataPromises = layers.map(async (layer) => {
      const requestBody = JSON.stringify({
        token: tokenGis,
        url: `${layer.url}?f=pjson`,
      });
      const response = await fetch(BACKEND_URL, {
        method: 'post',
        body: requestBody,
      });
      return response.json();
    });

    const layerData = await Promise.all(layerDataPromises);
    return layerData as number[][][];
  } catch (error) {
    console.error('Error fetching line coordinates:', error);
    return null;
  }
}
