import { toast } from 'react-toastify';

export async function useSendInterpretationsToBackend(
  selectedBorehole: string,
  selectedProject: string,
  ekRefereceAreas: any,
  skRefereceAreas: any,
  selectedStatus: string,
  fetchAuthenticated: any
) {
  const formData = new FormData();
  const BACKEND_URL = process.env.REACT_APP_HOST;
  formData.append('project', selectedProject);
  if (selectedBorehole.includes('~')) {
    formData.append('borehole', selectedBorehole);
  } else {
    formData.append('borehole', `${selectedBorehole}~TOT~`);
  }
  formData.append('selected_status', selectedStatus);
  formData.append('json_ekRefereceAreas', JSON.stringify(ekRefereceAreas));
  formData.append('json_skRefereceAreas', JSON.stringify(skRefereceAreas));
  
  try {
    const response = await fetchAuthenticated(
      `${BACKEND_URL}geobutler/projects/save-interpretation/`,
      {
        method: 'POST', // Use POST method
        body: formData,
      }
    );

    if (response.ok) {
      const backendResponse = await response.json();
      toast.success(
        `Lagret tolkning for ${
          selectedBorehole.split('~')[0]
        } med status ${selectedStatus.toLowerCase()}`
      );
    } else {
      console.error('Failed to save arrays', response.status, response.statusText);
      toast.error(
        `Kunne ikke lagre tolkning for ${
          selectedBorehole.split('~')[0]
        } med status ${selectedStatus.toLowerCase()}. Error: ${response.status}, ${
          response.statusText
        }`
      );
    }
  } catch (error) {
    console.error('Error saving interpretations:', error);
    toast.error(
      `Kunne ikke lagre tolkning for ${
        selectedBorehole.split('~')[0]
      } med status ${selectedStatus.toLowerCase()}. Error: ${error}`
    );
  }
  return;
}
