import { useEffect } from 'react';
import { toast } from 'react-toastify';
import '../MapComponent/MapComponent.scss';
import Graphic from '@arcgis/core/Graphic';
import { BoreholeData } from '../../hooks/useProjectSndInformation';
import { loadModules } from 'esri-loader';
import Point from '@arcgis/core/geometry/Point';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol.js';
import Extent from '@arcgis/core/geometry/Extent';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import { useFetchAuthenticated } from '../../hooks/useFetchAuthenticated';
import { TransformCoordinates } from '../../callouts/TransformCoordinates';

async function calculateAllPoints(
  projectInformation: any,
  fetchAuthenticated: any,
  coordinateSystem: string
) {
  try {
    return await TransformCoordinates(projectInformation, coordinateSystem, fetchAuthenticated);
  } catch (error) {
    console.error('Error transforming coordinates:', error);
    return [];
  }
}

/**
 * Creates a template for a popup displaying borehole information.
 * The template includes a title and content. The content is an array of objects,
 * each specifying a type of content and the associated text. In this case,
 * it includes a text type with HTML markup for displaying the filename attribute.
 *
 * @returns {Object} An object representing the popup template with `title` and `content` properties.
 *                   The `title` is a string containing HTML for the popup title.
 *                   The `content` is an array of objects, each describing a piece of content in the popup.
 *                   Currently, it contains a single text item with HTML markup and a placeholder for the filename.
 */
function setPopupTemplate() {
  return {
    title: "<div class='popup-title'>Borehole Information</div>",

    content: [
      {
        type: 'text',
        text: "<div class='popup-content'><strong>Filename:</strong> {filename}</div>", // Access the filename attribute
      },
    ],
  };
}

/**
 * Gets the minimum and maximum UTM coordinates.
 * @returns {Object} An object containing the minimum and maximum X and Y coordinates in UTM32 format.
 */
function getUtmMinMaxCoordinates() {
  return {
    utm32MinX: -1877994.66,
    utm32MaxX: 3473041.38,
    utm32MinY: 3638086.74,
    utm32MaxY: 9494203.2,
  };
}

/**
 * Checks if a given point is outside of specified rectangular boundaries.
 *
 * @param {Object} projectedPoint - The point to be checked, with 'x' and 'y' properties.
 * @param {number} utm32MinX - The minimum X-coordinate of the boundary.
 * @param {number} utm32MaxX - The maximum X-coordinate of the boundary.
 * @param {number} utm32MinY - The minimum Y-coordinate of the boundary.
 * @param {number} utm32MaxY - The maximum Y-coordinate of the boundary.
 * @returns {boolean} Returns `true` if the point is outside the boundaries; otherwise, returns `false`.
 */
function isPointOutsideBoundaries(
  projectedPoint: any,
  utm32MinX: number,
  utm32MaxX: number,
  utm32MinY: number,
  utm32MaxY: number
) {
  return (
    projectedPoint.x < utm32MinX ||
    projectedPoint.x > utm32MaxX ||
    projectedPoint.y < utm32MinY ||
    projectedPoint.y > utm32MaxY
  );
}

/**
 * Creates a graphic with specified point geometry and attributes.
 *
 * @param {Object} projectedPoint - The point geometry to be used for the graphic.
 * @param {Object} point - The original point data, used for copying existing attributes.
 * @param {Object} popupTemplate - The template to be used for the popup.
 * @returns {Graphic} A new Graphic object with specified geometry, symbol, and attributes.
 */
function createPointGraphic(projectedPoint: any, point: any, popupTemplate: object) {
  return new Graphic({
    geometry: projectedPoint,
    symbol: new SimpleMarkerSymbol({
      color: [226, 119, 40],
      outline: {
        color: [255, 255, 255],
        width: 1,
      },
    }),
    attributes: {
      ...point,
      borehole: point.borehole.split('~')[0],
    },
    popupTemplate,
  });
}

/**
 * Creates a graphic for a borehole point name at a specified location.
 *
 * @param {number} x - The x-coordinate for the graphic's geometry.
 * @param {number} y - The y-coordinate for the graphic's geometry.
 * @param {number} targetCrs - The well-known ID (wkid) of the target spatial reference.
 * @param {string} borepointNameText - The text to be displayed as the borehole point name.
 * @returns {Graphic} A new Graphic object representing the borehole point name graphic.
 */
function createBoreholePointNameGraphic(
  x: number,
  y: number,
  targetCrs: any,
  borepointNameText: string
) {
  return new Graphic({
    geometry: new Point({
      x: x + 8, // Adjust this value as needed
      y: y, // Keep the same y coordinate
      spatialReference: { wkid: targetCrs },
    }),
    symbol: new TextSymbol({
      text: borepointNameText,
      color: [0, 0, 0], // Text color
      font: {
        size: 8,
      },
      horizontalAlignment: 'left',
      verticalAlignment: 'middle',
    }),
  });
}

//TODO: refactor code more
export function PlotDrillingPoints(props: any) {
  // Destructure all needed props
  const { projectData, view, pointsLayer, projectInformation } = props;
  const { fetchAuthenticated } = useFetchAuthenticated();

  const popupTemplate = setPopupTemplate();

  useEffect(() => {
    loadModules(['esri/symbols/SimpleMarkerSymbol']).then(([LoadedSimpleMarkerSymbol]) => {
      if (projectInformation && pointsLayer) {
        pointsLayer.removeAll(); // Clear any existing graphics
        // From https://epsg.io/25832
        const { utm32MinX, utm32MaxX, utm32MinY, utm32MaxY } = getUtmMinMaxCoordinates();

        let notificationDisplayed = false;

        // Transform the coordinates
        calculateAllPoints(
          Object.values(projectInformation).flat(),
          fetchAuthenticated,
          projectData?.['Coordinate System']
        ).then((allPoints) => {
          const targetCrs = 25832; // EUREF 89 UTM 32
          const pointGraphic = allPoints.map((point: BoreholeData) => {
            // Convert coordinates using ArcGIS projection
            const projectedPoint = new Point({
              x: parseFloat(point.coordinate_x), // Parse the coordinate_x as a number
              y: parseFloat(point.coordinate_y), // Parse the coordinate_y as a number
              spatialReference: { wkid: targetCrs }, // EUREF 89 UTM 32
            });
            if (
              isPointOutsideBoundaries(projectedPoint, utm32MinX, utm32MaxX, utm32MinY, utm32MaxY)
            ) {
              if (!notificationDisplayed) {
                toast.error(
                  'Koordinatene er utenfor lovlig område, har prosjektet rett koordinatsystem?'
                );
                notificationDisplayed = true;
              }
              return; // Exit the function early
            }
            // Create a graphic with the projected point geometry
            const pointGraphic = createPointGraphic(projectedPoint, point, popupTemplate);
            const coordinateZ = parseFloat(point.coordinate_z);
            const depth = parseFloat(point.depth);
            const emDash = '\u2014\u2014\u2014';
            const borepointNameText = point.borehole.split('~')[0] || '';

            const borepointNameGraphic = createBoreholePointNameGraphic(
              parseFloat(point.coordinate_x) + 8,
              parseFloat(point.coordinate_y),
              targetCrs,
              borepointNameText
            );

            const boreholeNameLength = borepointNameText.length * 5 || 0;

            const borepointInfoText =
              coordinateZ.toFixed(1) +
              '\n' +
              emDash +
              depth.toFixed(1) +
              ' + ' +
              depth.toFixed(1) +
              '\n' +
              (coordinateZ - depth).toFixed(1);

            const borepointInfoGraphic = new Graphic({
              geometry: new Point({
                x: parseFloat(point.coordinate_x) + boreholeNameLength, // Adjust this value as needed
                y: parseFloat(point.coordinate_y), // Keep the same y coordinate
                spatialReference: { wkid: targetCrs },
              }),
              symbol: new TextSymbol({
                text: borepointInfoText,
                color: [0, 0, 0], // Text color
                font: {
                  size: 8,
                },
                horizontalAlignment: 'left',
                verticalAlignment: 'middle',
              }),
            });

            // Add the graphics to the pointsLayer
            if (pointsLayer) {
              pointsLayer.add(pointGraphic);
              pointsLayer.add(borepointNameGraphic);
              pointsLayer.add(borepointInfoGraphic);
            }

            return pointGraphic;
          });

          // Calculate the extent of the graphics in pointsLayer
          let minX = Infinity;
          let minY = Infinity;
          let maxX = -Infinity;
          let maxY = -Infinity;

          pointGraphic.forEach((graphic) => {
            if (graphic) {
              const geometry = graphic.geometry;
              if (geometry) {
                if (geometry.type === 'point') {
                  const point = geometry as Point;
                  minX = Math.min(minX, point.x);
                  minY = Math.min(minY, point.y);
                  maxX = Math.max(maxX, point.x);
                  maxY = Math.max(maxY, point.y);
                } else {
                  const extent = geometry.extent;
                  if (extent) {
                    minX = Math.min(minX, extent.xmin);
                    minY = Math.min(minY, extent.ymin);
                    maxX = Math.max(maxX, extent.xmax);
                    maxY = Math.max(maxY, extent.ymax);
                  }
                }
              }
            }
          });

          if (minX !== Infinity && minY !== Infinity && maxX !== -Infinity && maxY !== -Infinity) {
            const graphicsExtent = new Extent({
              xmin: minX,
              ymin: minY,
              xmax: maxX,
              ymax: maxY,
              spatialReference: new SpatialReference({ wkid: targetCrs }), // Adjust this spatial reference as needed
            });

            if (view) {
              view.goTo(graphicsExtent.expand(1.5)); // You can adjust the factor as needed
            }
          }
        });
      }
    });
  }, [projectInformation, pointsLayer, view]);

  return null;
}
