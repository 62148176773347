import { useState, MouseEvent, useContext, useEffect } from 'react';
import { MapComponent } from '../../components';
import './MapPage.scss';
import { IntersectCtx } from '../../context/IntersectDataCtx';
import { NavigationMenu } from '../../components/NavigationMenu/NavigationMenu';
import { useProjectsLoader } from '../../hooks/useProjectsLoader';
import { useFileUploader } from '../../hooks/useFileUploader';
import { AlternativeListing, Phase } from '../../utils/databaseTypes';
import { ExpandableContainer } from '../../components/ExpandableContainer/ExpandableContainer';
import { ExpandableHeader } from '../../components/ExpandableHeader/ExpandableHeader';
import { ImageContainer } from '../../components/ImageContainer/ImageContainer';
import NewProjectForm from '../../components/NewProjectForm/NewProjectForm';
import ProjectBrowser from '../../components/ProjectBrowser/ProjectBrowser';
import { IoMdBrowsers } from 'react-icons/io';
import { FaWpforms } from 'react-icons/fa';
import { AiOutlineFileAdd } from 'react-icons/ai';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { DashboardData } from '../../hooks/useDashboard';
import LoadingBar from '../../components/LoadingBar/LoadingBar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFetchAuthenticated } from '../../hooks/useFetchAuthenticated';

export function MapPage() {
  const { clearActiveVersion, updateActiveVersion } = useContext(IntersectCtx);
  //const { fetchProjects } = useProjects();
  const {
    projects,
    setProjects,
    loading,
    error,
    loadData,
    isLoadingData,
    projectData,
    setProjectData,
    selectedProject,
    setSelectedProject,
    fetchGeoProjects,
  } = useProjectsLoader();
  const { fetchAuthenticated } = useFetchAuthenticated();
  const [showTable, setShowTable] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [mouseStart, setMouseStart] = useState<number>(0);
  const initialTableHeight: number = 250;
  const [tableHeight, setTableHeight] = useState<number>(initialTableHeight);
  const [tab, setTab] = useState<number>(0);
  const [phases, setPhases] = useState<Phase[]>([]);
  const [alternatives, setAlternatives] = useState<AlternativeListing[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [xLayer, setXLayer] = useState<FeatureLayer[] | undefined>();
  const [dashboardData, setDashboardData] = useState<DashboardData | undefined>();
  const { isUploadingFiles, progress, addFiles, sndData, setSndData } = useFileUploader(fetchAuthenticated);
  const dragElement: HTMLElement | null = document.getElementById('expand-div');
  const [showFullPlot, setshowFullPlot] = useState<boolean>(true);
  const [splittedWiews, setSplittedWiews] = useState<boolean>(false);
  const [showQuickClay, setShowQuickClay] = useState<boolean>(false);
  const [selectedFilenames, setSelectedFilenames] = useState<string[]>([]);


  // const pickerOptions = [
  //   { value: 'planning', label: 'Tidlig planlegging', iconOption: <EarlyPhaseIcon /> },
  // ];

  // const selectOptions = [
  //   {
  //     value: 'planning',
  //     icon: <EarlyPhaseIcon />,
  //     optionText: '1. Tidlig Planlegging',
  //   },

  //   {
  //     value: 'divider',
  //     divider: (
  //       <div>
  //         <div className="line-seperator"></div>
  //       </div>
  //     ),
  //     isDisabled: true,
  //   },

  // ];

  interface ProjectFormData {
    projectNumber: string;
    projectName: string;
  }

  const handleClick = (alternativeId: number) => {};

  useEffect(() => {
    clearActiveVersion();
  }, []);

  const initDrag = (event: MouseEvent) => {
    if (dragElement) {
      setTableHeight(parseInt(dragElement.style.height, 10));
    }
    setMouseStart(event.clientY);
    setIsDragging(true);
  };
  const mouseMoveHandler = (event: MouseEvent) => {
    if (isDragging) {
      const deltaY: number = tableHeight + mouseStart - event.pageY;
      if (dragElement) {
        dragElement.style.height = deltaY + 'px';
      }
    }
  };

  const handleProjectSelection = (newSelectedProject: string | null) => {
    setSelectedProject(newSelectedProject);
    if (!newSelectedProject) {
      setProjectData(undefined);
    }
    // Perform any further actions based on the selected option
  };
  const handleSelectedFilenamesChange = (selectedFilenames: string[]) => {
    setSelectedFilenames(selectedFilenames);
  };
  const [isProjectBrowserVisible, setIsProjectBrowserVisible] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const handleOpenProjectBrowser = () => {
    setIsProjectBrowserVisible(true);
  };
  const handleCloseProjectBrowser = () => {
    setIsProjectBrowserVisible(false);
  };
  const handleOpenForm = () => {
    setIsFormVisible(true);
  };
  const handleCloseForm = () => {
    setIsFormVisible(false);
  };
  // Create new project
  const handleFormSubmit = (data: ProjectFormData) => {
    setIsFormVisible(!isFormVisible);
    // Perform any further actions based on the form data
  };
  const handleToggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const handleFileUploadAndLoad = async (selectedProject: string | null) => {
    let loadingFlag = true;
    const newBoreholeName: string[] = ['']; 
    try {
      await addFiles(selectedProject, setSndData, handleSelectedFilenamesChange);
      handleProjectSelection(null);

      if (!isLoadingData) {
        loadingFlag = false;
      }
    } catch (error) {
      // Handle error scenario
    } finally {
      setTimeout(() => {
        if (!loadingFlag) {
          handleProjectSelection(selectedProject);
        }
      }, 1000); // 2 seconds delay
    }
  };

  return (
    <div
      className="page-container"
      onMouseUp={() => {
        setIsDragging(false);
      }}
      onMouseMove={(event) => {
        mouseMoveHandler(event);
      }}
    >
      <ToastContainer />
      <div className="tool-box">
        <h3></h3>
        {/* Include the ProjectBrowser component */}
        <div>
          <button onClick={handleOpenProjectBrowser}>
            <IoMdBrowsers />
            Lagrede prosjekter og boringer
          </button>
          {isProjectBrowserVisible && (
            <div className="project-browser-container">
              <div className="project-browser">
                <ProjectBrowser
                  onClose={handleCloseProjectBrowser}
                  projects={projects}
                  projectData={projectData}
                  selectedOption={selectedProject !== null ? selectedProject : ''}
                  setSndData={setSndData}
                  setProjects={setProjects}
                  handleSelectChange={handleProjectSelection} // Pass the callback function to handle project selection
                  isLoadingData={isLoadingData} // Pass isLoadingData to show loading spinner
                  selectedFilenames={selectedFilenames}
                  setSelectedFilenames={setSelectedFilenames}
                  handleSelectedFilenamesChange={handleSelectedFilenamesChange}
                />
              </div>
            </div>
          )}
          <button onClick={handleToggleFormVisibility}>
            <FaWpforms />
            Nytt Prosjekt
          </button>
          {isFormVisible && (
            <div className="form-container">
              <div className="form">
                <NewProjectForm
                  onSubmit={handleFormSubmit}
                  projects={projects}
                  refreshProjects={fetchGeoProjects}
                  loadNewProject={loadData}
                  handleSelectChange={handleProjectSelection}
                />
              </div>
            </div>
          )}
          <button onClick={() => handleFileUploadAndLoad(selectedProject)}>
            {isUploadingFiles ? (
              <span>
                {isUploadingFiles && <LoadingBar progress={progress} />}
                Uploading {`${Number(progress.toFixed(1))}%`}
              </span>
            ) : (
              <span>
                <AiOutlineFileAdd />
                Legg til boringer
              </span>
            )}
          </button>
        </div>
      </div>
      <div className="navigation-box">
        <NavigationMenu
          setSndData={setSndData}
          selectedOption={selectedProject !== null ? selectedProject : ''}
          projects={projects}
          projectData={projectData}
          isLoadingData={isLoadingData}
          handleSelectChange={handleProjectSelection}
          selectedFilenames={selectedFilenames}
          setSelectedFilenames={setSelectedFilenames}
          handleSelectedFilenamesChange={handleSelectedFilenamesChange}
        />
        <ExpandableContainer isExpanded={isExpanded}>
          <ExpandableHeader
            handleClick={() => {
              setIsExpanded(!isExpanded);
            }}
            isExpanded={isExpanded}
            editDate={'dato'}
            projectNumber={1}
            // pickerOptions={pickerOptions}
            // selectOptions={selectOptions}
          />
          <div className="data-container">
            <div className="add-project-version">
              <h3>Prosjekt: {selectedProject !== null ? selectedProject : ''}</h3>
              <div className="project-version-buttons" style={{ display: 'flex' }}>
                {selectedProject !== null && sndData && (
                  <button onClick={() => setshowFullPlot(!showFullPlot)}>
                    {showFullPlot ? 'Forenklet plotvisning' : 'Komplett plotvisning'}
                  </button>
                )}
                {selectedProject !== null && sndData && (
                  <button onClick={() => setSplittedWiews(!splittedWiews)}>
                    {splittedWiews ? 'Enkel tolkningsvisning' : 'Splittet tolkningvisning'}
                  </button>
                )}
                {selectedProject !== null && sndData && (
                  <button onClick={() => setShowQuickClay(!showQuickClay)}>
                    {showQuickClay ? 'Geobutler' : 'Geobutler sprøbrudd'}
                  </button>
                )}
              </div>
            </div>
            <div className={'plot-list'}>
              <ImageContainer
                handleClick={handleClick}
                sndData={sndData}
                setSndData={setSndData}
                showFullPlot={showFullPlot}
                splittedWiews={splittedWiews}
                showQuickClay={showQuickClay}
                selectedProject={selectedProject !== null ? selectedProject : ''}
                selectedFilenames={selectedFilenames}
              />
            </div>
            <div></div>
          </div>
        </ExpandableContainer>
      </div>
      <div className={showTable ? 'container-map-table' : 'container-map'}>
        <MapComponent
          setXLayer={setXLayer}
          selectedOption={selectedProject}
          projectData={projectData}
        />
      </div>
    </div>
  );
}
