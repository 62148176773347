import { useState } from 'react';
import { ProjectType } from '../utils/databaseTypes';

type Projects = {
  fetchingProjects: boolean;
  fetchingProjectsError: boolean;
  fetchProjects: () => Promise<ProjectType[]>;
  savingProject: boolean;
  savingProjectError: boolean;
  saveProject: (project: ProjectType) => Promise<number>;
  deleteProject: (projectId: number) => void;
  fetchProject: (projectId: number) => Promise<ProjectType | null>;
};

export function useProjects(): Projects {
  const serverUrl = process.env.REACT_APP_BACKEND_HOST as string;
  const [savingProject, setSavingProject] = useState(false);
  const [savingProjectError, setSavingProjectError] = useState(false);
  const [fetchingProjects, setFetchingProjects] = useState(false);
  const [fetchingProjectsError, setFetchingProjectsError] = useState(false);

  const fetchProject = async (projectId: number): Promise<ProjectType | null> => {
    setFetchingProjects(true);
    setFetchingProjectsError(false);
    const url = `${serverUrl}/project/${projectId}`;
    try {
      let response = await fetch(url);
      let project: ProjectType = await response.json();
      return project;
    } catch (error) {
      console.log(error);
      setFetchingProjectsError(true);
    } finally {
      setFetchingProjects(false);
    }
    return null;
  };

  const fetchProjects = async (): Promise<ProjectType[]> => {
    setFetchingProjects(true);
    setFetchingProjectsError(false);
    const url = `${serverUrl}/project`;
    let projects: ProjectType[] = [];
    try {
      let response = await fetch(url);
      projects = await response.json();
    } catch (error) {
      console.log(error);
      setFetchingProjectsError(true);
    } finally {
      setFetchingProjects(false);
    }
    return projects;
  };

  const saveProject = async (project: ProjectType): Promise<number> => {
    setSavingProject(true);
    setSavingProjectError(false);
    let newProjectId: number = -1;
    const url = `${serverUrl}/project`;
    try {
      let response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(project),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      newProjectId = await response.json();
    } catch (error) {
      console.log(error);
      setSavingProjectError(true);
    } finally {
      setSavingProject(false);
    }
    return newProjectId;
  };

  const deleteProject = async (projectId: number) => {
    const url = `${serverUrl}/project/${projectId}`;
    try {
      fetch(url, {
        method: 'DELETE',
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    fetchingProjects,
    fetchingProjectsError,
    fetchProjects,
    savingProject,
    savingProjectError,
    saveProject,
    deleteProject,
    fetchProject,
  };
}
