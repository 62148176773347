import React, { useState } from 'react';
import HamburgerIcon from 'calcite-ui-icons-react/HamburgerIcon';
import ChevronLeftIcon from 'calcite-ui-icons-react/ChevronLeftIcon';
import classNames from 'classnames';
import './NavigationMenu.scss';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import { useProjectSndInformation } from '../../hooks/useProjectSndInformation';
import { plotSelectedBoreholes } from '../../hooks/useFileUploader';
import { useFetchAuthenticated } from '../../hooks/useFetchAuthenticated';

export type MenuItem = {
  open: React.ReactNode;
  closed: React.ReactNode;
};

interface Props {
  topPart?: MenuItem[];
  bottomPart?: MenuItem[];
  setSndData: React.Dispatch<React.SetStateAction<any>>;
  projects: string[];
  selectedOption: string | null;
  handleSelectChange: (selectedProject: string) => void;
  isLoadingData: boolean;
  projectData: any;
  selectedFilenames: string[];
  setSelectedFilenames: React.Dispatch<React.SetStateAction<string[]>>;
  handleSelectedFilenamesChange: (selectedFilenames: string[]) => void;
}

export function NavigationMenu(props: Props) {
  const { fetchAuthenticated } = useFetchAuthenticated();
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const { projectInformation, setProjectInformation, loading, setLoading } =
    useProjectSndInformation(props.selectedOption);

  const toggleBoreholeSelection = (borehole: string) => {
    // If there is no selected filename, set snd data to null
    if (props.selectedFilenames.length === 0) {
      props.setSndData(null);
    }
    if (props.selectedFilenames.includes(borehole)) {
      const updatedSelectedFilenames = props.selectedFilenames.filter((item) => item !== borehole);
      props.handleSelectedFilenamesChange(updatedSelectedFilenames);
      console.log(updatedSelectedFilenames);
      if (updatedSelectedFilenames.length !== 0) {
        console.log('Plotter', updatedSelectedFilenames.length);
        handlePlotSelectedBoreholes(updatedSelectedFilenames, fetchAuthenticated);
      } else {
        props.setSndData(null);
      }
    } else {
      const updatedSelectedFilenames = [...props.selectedFilenames, borehole];
      props.handleSelectedFilenamesChange(updatedSelectedFilenames);
      handlePlotSelectedBoreholes(updatedSelectedFilenames, fetchAuthenticated);
    }
  };

  const handlePlotSelectedBoreholes = async (selectedFilenames: any, fetchAuthenticated: any) => {
    setLoading(true); // Assuming you have access to setLoading from useProjectSndInformation
    console.log('Prosjekt, ', props.selectedOption, 'Filer, ', selectedFilenames);
    await plotSelectedBoreholes(
      props.selectedOption,
      selectedFilenames,
      props.setSndData,
      setLoading,
      fetchAuthenticated
    );
  };

  return (
    <div className={isClosed ? 'sideMenuClosed' : 'sideMenu'}>
      <div
        className={'menuButtonContainer'}
        style={{ zIndex: '1' }}
        onClick={() => setIsClosed(!isClosed)}
        onKeyDown={(event) => {
          event.key === 'Enter' && setIsClosed(!isClosed);
        }}
        tabIndex={0}
      >
        {isClosed ? <HamburgerIcon /> : <ChevronLeftIcon />}
      </div>
      {/*Top part goes here*/}
      <div className={'menuContent'}>
        <div className={'topPart'}>
          {props.topPart?.map((item: MenuItem, index: number) => (
            <div
              className={classNames(
                'topPartItem',
                isClosed && 'flexAlignCenter',
                !isClosed && 'fadeIn'
              )}
              key={`menu-item-${index}`}
            >
              {isClosed ? item.closed : item.open}
            </div>
          ))}
          <div className={'project-menu'}>
            {!isClosed && <h3 style={{ marginRight: '12px' }}>Prosjekter</h3>}
            <div className={isClosed ? 'dropdown-select-closed' : 'dropdown-select-open'}>
              {
                // When closed...
              }
              {!isClosed && (
                <DropdownSelect
                  options={props.projects}
                  selectedOption={props.selectedOption !== null ? props.selectedOption : ''}
                  handleSelectChange={props.handleSelectChange} // Pass the callback function to handle project selection
                  isLoadingData={props.isLoadingData} // Pass isLoadingData to show loading spinner
                  projectData={props.projectData} // Pass projectData to display project information
                />
              )}
            </div>
          </div>
        </div>
        <div className="snd-data-container">
          <div className={isClosed ? 'snd-data-closed' : 'snd-data-open'}>
            {}
            {!isClosed &&
              projectInformation &&
              Object.keys(projectInformation)
                .sort((boreholeA, boreholeB) => boreholeA.localeCompare(boreholeB))
                .map((borehole, index) => (
                  <div key={`snd-dataa-item-${index}`} className="checkbox-row">
                    <input
                      type="checkbox"
                      checked={props.selectedFilenames.includes(borehole)}
                      onChange={() => toggleBoreholeSelection(borehole)}
                    />
                    <label>{borehole.split('~')[0]}</label>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}
