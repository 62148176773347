import React, { useState } from "react";
import DataSelectionComponent from "../DataSelectionComponent/DataSelectionComponent";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import "./ProjectBrowser.scss";
import { FaTimes } from "react-icons/fa"; // Import the close icon from FontAwesome
import { useFetchAuthenticated } from "../../hooks/useFetchAuthenticated";
import { handleExportToLeapfrog } from "../ExportToLeapFrog/ExportToLeapfrog";

interface ProjectBrowserProps {
  onClose: () => void;
  selectedOption: string | null;
  setSndData: React.Dispatch<React.SetStateAction<any>>;
  projects: string[];
  projectData: any;
  handleSelectChange: (selectedProject: string) => void;
  setProjects: React.Dispatch<React.SetStateAction<any[]>>;
  isLoadingData: boolean;
  selectedFilenames: string[];
  setSelectedFilenames: React.Dispatch<React.SetStateAction<string[]>>;
  handleSelectedFilenamesChange: (selectedFilenames: string[]) => void;
}

function ProjectBrowser({
  onClose,
  selectedOption,
  setSndData,
  projects,
  projectData,
  handleSelectChange,
  setProjects,
  isLoadingData,
  selectedFilenames,
  setSelectedFilenames,
  handleSelectedFilenamesChange,
}: ProjectBrowserProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const bedrockThicknessOptions = [
    { label: "Svært liten påvisning: 0.1 m", value: "0.1" },
    { label: "Liten påvisning: 0.5 m", value: "0.5" },
    { label: "Middels påvisning: 1 m", value: "1" },
    { label: "Høy påvisning: 2 m", value: "2" },
  ];
  const [showLeapfrogOptions, setShowLeapfrogOptions] = useState(false);
  const [selectedQuickclay, setSelectedQuickclay] = useState("False");
  const [selectedBedrockThickness, setSelectedBedrockThickness] = useState(""); // Default to 0.1 m
  const [showBedrockOptions, setShowBedrockOptions] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_HOST;
  const { fetchAuthenticated } = useFetchAuthenticated();

  const onExportToLeapfrogClick = async () => {
    // Ensure all required data is available
    if (selectedOption && selectedFilenames.length > 0) {
      // Now we call the exported function with the required arguments
      await handleExportToLeapfrog(
        selectedOption,
        selectedFilenames,
        selectedQuickclay,
        fetchAuthenticated
      );
    } else {
      // Handle the case where the required data isn't available
      console.error("Required data for export is missing");
      // Optionally show an error message to the user
    }
  };

  const handleExportBedrockSurfaceLandXML = async () => {
    if (selectedOption !== null) {
      const url = new URL(
        `${BACKEND_URL}geobutler/projects/export-to-bedrock-surface-landxml/`,
        window.location.origin
      );
      const params = new URLSearchParams();
      params.append("project_number", selectedOption);
      params.append("minimum_thickness", selectedBedrockThickness);
      selectedFilenames.forEach((filename) => {
        params.append("borehole_name", filename);
      });
      params.append("quickclay", "False");
      params.append("order_of_control", "True");
      url.search = params.toString();
      try {
        const response = await fetchAuthenticated(url.toString());
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            selectedOption + "_surface-" + selectedBedrockThickness + "m.xml";
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          throw new Error("Failed to export data to surface LandXML");
        }
      } catch (error) {
        console.error("Error exporting data to surface LandXML:", error);
        alert("Failed to export data to surface LandXML");
      }
    }
  };

  const handleExportBedrockPointLandXML = async () => {
    if (selectedOption !== null) {
      const url = new URL(
        `${BACKEND_URL}geobutler/projects/export-to-bedrock-points-landxml/`,
        window.location.origin
      );
      const params = new URLSearchParams();
      params.append("project_number", selectedOption);
      params.append("minimum_thickness", selectedBedrockThickness);
      selectedFilenames.forEach((filename) => {
        params.append("borehole_name", filename);
      });
      params.append("quickclay", "False");
      params.append("order_of_control", "True");
      url.search = params.toString();
      try {
        const response = await fetchAuthenticated(url.toString());
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            selectedOption +
            "_points-" +
            selectedBedrockThickness +
            "m_tolket_innboring_GeoButler.xml";
          a.click();
          window.URL.revokeObjectURL(url);
        } else {
          throw new Error("Failed to export data to point LandXML");
        }
      } catch (error) {
        console.error("Error exporting data to point LandXML:", error);
        alert("Failed to export data to point LandXML");
      }
    }
  };

  const handleDeleteProject = () => {
    // Call the API to delete the project
    fetchAuthenticated(
      `${BACKEND_URL}geobutler/projects/delete-project/?project_number=${selectedOption}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Perform any necessary actions after project deletion

        // Remove the deleted project from projectNames array
        const updatedProjects = projects.filter(
          (project: any) => project.projectName !== selectedOption
        );
        setProjects(updatedProjects);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting project:", error);
        // Handle error scenario
      });
  };

  const confirmDelete = () => {
    setShowConfirmation(!showConfirmation);
    setShowLeapfrogOptions(false);
    setShowBedrockOptions(false);
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setShowLeapfrogOptions(false);
    setShowBedrockOptions(false);
  };

  const handleToggleLeapfrogOptions = () => {
    setShowLeapfrogOptions(!showLeapfrogOptions);
    setShowBedrockOptions(false);
    setShowConfirmation(false);
  };

  const handleToggleBedrockOptions = () => {
    setShowBedrockOptions(!showBedrockOptions);
    setShowConfirmation(false);
    setShowLeapfrogOptions(false);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmation(false);
    await handleDeleteProject();
  };

  const handleBedrockOptionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedBedrockThickness(event.target.value);
  };

  const handleLeapfrogOptionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedQuickclay(event.target.value);
  };

  return (
    <div>
      <div className="browser-header">
        <h2 style={{ paddingLeft: "10px" }} className="header-title">
          Lagrede prosjekter og boringer
        </h2>
        <button className="close-button" onClick={onClose}>
          <FaTimes className="close-icon" />
        </button>
      </div>
      <div className="browser-content">
        <div>
          <DropdownSelect
            options={projects}
            selectedOption={selectedOption !== null ? selectedOption : ""}
            handleSelectChange={handleSelectChange} // Pass the callback function to handle project selection
            isLoadingData={isLoadingData} // Pass isLoadingData to show loading spinner
            projectData={projectData} // Pass projectData to display project information
          />
        </div>
      </div>
      <div className="browser-content">
        <DataSelectionComponent
          selectedOption={selectedOption !== null ? selectedOption : ""}
          setSndData={setSndData}
          onSelectedFilenamesChange={handleSelectedFilenamesChange} // Pass the callback function to the DataSelectionComponent
          selectedFilenames={selectedFilenames}
          setSelectedFilenames={setSelectedFilenames}
        />
        <br />

        <div
          style={{
            display: "fixed",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <button onClick={handleToggleLeapfrogOptions}>
            Eksporter til LeapFrog
          </button>
          <button onClick={handleToggleBedrockOptions}>
            Eksporter bergflate til landXML
          </button>
          <button onClick={confirmDelete}>Slett prosjekt</button>
        </div>
        {showLeapfrogOptions || showBedrockOptions || showConfirmation ? (
          <hr style={{ backgroundColor: "black", margin: "10px 0" }} />
        ) : null}

        {showLeapfrogOptions && (
          <div className="leapfrog-options">
            <div style={{ marginRight: "10px" }}>Inkluder kvikkleire?</div>
            <select
              value={selectedQuickclay}
              onChange={handleLeapfrogOptionChange}
              style={{
                border: "1px solid black",
                color: "black",
                backgroundColor: "lightgrey",
                width: "150px",
                borderRadius: "5px",
                padding: "2px",
              }}
            >
              <option value="False">Nei</option>
              <option value="True">Ja</option>
            </select>
            {selectedQuickclay !== "" && (
              <div>
                <button onClick={onExportToLeapfrogClick}>
                  Last ned LeapFrog
                </button>
              </div>
            )}
          </div>
        )}
        {showBedrockOptions && (
          <div className="bedrock-options">
            <div style={{ marginRight: "10px" }}>
              Minimum tolket bergpåvisning:
            </div>
            <select
              value={selectedBedrockThickness}
              onChange={handleBedrockOptionChange}
              style={{
                display: "fixed",
                border: "1px solid black",
                color: "black",
                backgroundColor: "lightgrey",
                width: "180px",
                borderRadius: "5px",
                padding: "2px",
              }}
            >
              <option value="">Velg</option>
              {bedrockThicknessOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {selectedBedrockThickness !== "" && (
              <div>
                <button onClick={handleExportBedrockSurfaceLandXML}>
                  Eksport SURFACE
                </button>
                <button onClick={handleExportBedrockPointLandXML}>
                  Export POINT
                </button>
              </div>
            )}
          </div>
        )}
        {/* Confirmation Dialog */}
        {showConfirmation && (
          <div className="confirmation-modal">
            <div className="confirmation-content">
              <p>Are you sure you want to delete the project?</p>
              <div>
                <button onClick={handleConfirmDelete}>Yes</button>
                <button onClick={cancelDelete}>No</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectBrowser;
