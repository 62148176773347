import React from 'react';
import styles from './DropdownSelect.module.scss';
import { Spinner } from '../Spinner/Spinner';

interface DropdownSelectProps {
  options: string[];
  selectedOption: string | null;
  handleSelectChange: (selectedProject: string) => void;
  isLoadingData: boolean;
  projectData: any;
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  options,
  selectedOption,
  handleSelectChange,
  isLoadingData,
  projectData,
}) => {
  const handleSelectChangeInternal = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    handleSelectChange(selectedValue);
  };

  return (
    <div>
      <div className={styles.select}>
        <select value={selectedOption !== null ? selectedOption : ''} onChange={handleSelectChangeInternal}>
          <option value="">Velg prosjekt</option>
          {options.map((option) => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
        <span className={styles.focus}></span>
      </div>
      <div>
        {isLoadingData ? (
          <Spinner />
        ) : (
          projectData && selectedOption !== null && (
            <div className={styles.projectData}>
              <span>Prosjektnr: {projectData['Project Number']}</span>
              <span>Prosjektnavn: {projectData['Project Name']}</span>
              <span>Koordinatsystem: {projectData['Coordinate System']}</span>
              <span>Vertikal datum: {projectData['Vertical Datum']}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default DropdownSelect;
