// exportToLeapfrog.ts
import { downloadFile } from "../../components/DownloadFile/DownloadFile";
const BACKEND_URL = process.env.REACT_APP_HOST;

// Import any other dependencies required by the function

export async function handleExportToLeapfrog(
  selectedOption: string | null,
  selectedFilenames: string[],
  selectedQuickclay: string,
  fetchAuthenticated: Function
) {
  if (selectedOption !== null) {
    const url = new URL(
      `${BACKEND_URL}geobutler/projects/export-to-leapfrog/`,
      window.location.origin
    );

    const requestBody = {
      project_number: selectedOption,
      borehole_names: selectedFilenames,
      select_prediction: true,
      quickclay: selectedQuickclay,
      order_of_control: true,
    };

    try {
      const response = await fetchAuthenticated(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const blob = await response.blob();

        downloadFile(blob, `${selectedOption}_LeapFrog_export.zip`);
      } else {
        throw new Error("Failed to export data to Leapfrog format");
      }
    } catch (error) {
      console.error("Error exporting data to Leapfrog format:", error);
      alert("Failed to export data to Leapfrog format");
    }
  }
}
