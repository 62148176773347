import React from 'react';
import { useForm } from 'react-hook-form';
import './NewProjectForm.scss';
import ReactSelect from 'react-select';
import { useFetchAuthenticated } from '../../hooks/useFetchAuthenticated';

interface ProjectFormData {
  projectNumber: string;
  projectName: string;
  coordinateSystem: string;
  verticalDatum: string;
}

interface NewProjectFormProps {
  onSubmit: (data: ProjectFormData) => void;
  projects: string[];
  refreshProjects: () => Promise<void>;
  loadNewProject: (selectedProject: string) => Promise<void>;
  handleSelectChange: (selectedProject: string) => void;
}

const NewProjectForm: React.FC<NewProjectFormProps> = ({ onSubmit, projects, refreshProjects, loadNewProject, handleSelectChange }) => {
  const { register, handleSubmit, setValue } = useForm<ProjectFormData>();
  const { fetchAuthenticated } = useFetchAuthenticated();

  const coordinateOptions = [
    { value: 'NTM10', label: 'NTM 10' },
    { value: 'UTM32', label: 'UTM 32' },
    { value: 'UTM33', label: 'UTM 33' },
    { value: 'UTM35', label: 'UTM 35' },
    { value: 'NTM5', label: 'NTM 5' },
    { value: 'NTM6', label: 'NTM 6' },
    { value: 'NTM7', label: 'NTM 7' },
    { value: 'NTM8', label: 'NTM 8' },
    { value: 'NTM9', label: 'NTM 9' },
    { value: 'NTM11', label: 'NTM 11' },
    { value: 'NTM12', label: 'NTM 12' },
    { value: 'NTM13', label: 'NTM 13' },
    { value: 'NTM14', label: 'NTM 14' },
    { value: 'NTM15', label: 'NTM 15' },
    { value: 'NTM16', label: 'NTM 16' },
    { value: 'NTM17', label: 'NTM 17' },
    { value: 'NTM18', label: 'NTM 18' },
    { value: 'NTM19', label: 'NTM 19' },
    { value: 'NTM20', label: 'NTM 20' },
    { value: 'NTM21', label: 'NTM 21' },
    { value: 'NTM22', label: 'NTM 22' },
    { value: 'NTM23', label: 'NTM 23' },
    { value: 'NTM24', label: 'NTM 24' },
    { value: 'NTM25', label: 'NTM 25' },
    { value: 'NTM26', label: 'NTM 26' },
    { value: 'NTM27', label: 'NTM 27' },
    { value: 'NTM28', label: 'NTM 28' },
    { value: 'NTM29', label: 'NTM 29' },
    { value: 'NTM30', label: 'NTM 30' },
    // Add more coordinate options as needed
  ];
  
  const verticalDatum = [
    { value: 'NN2000', label: 'NN 2000' },
    { value: 'SeaMapZero', label: 'Sjøkartnull' },
    // Add more veritcal datum options as needed
  ];

  const handleCoordinateSelect = (selectedOption: { value: string; label: string } | null) => {
    setValue('coordinateSystem', selectedOption?.value || '');
  };

  const handleVerticalSelect = (selectedOption: { value: string; label: string } | null) => {
    setValue('verticalDatum', selectedOption?.value || '');
  };

  const handleFormSubmit = handleSubmit(async (data) => {
    const { projectNumber, projectName, coordinateSystem, verticalDatum } = data;
    const BACKEND_URL = process.env.REACT_APP_HOST
    
    const isFound = projects.find((project) => {
      return project === projectNumber;
    });
    if (isFound) {
      alert('Prosjektet finnes allerede');
      return;
    }

    const queryParams = new URLSearchParams({
        project_number: projectNumber,
        project_name: projectName,
        coordinate_system: coordinateSystem,
        vertical_datum: verticalDatum,
    });
    try {
        const response = await fetchAuthenticated(
          `${BACKEND_URL}geobutler/create-new-project/?${queryParams}`);

      if (response.ok) {
        // Success
        onSubmit(data);
        // Refresh project list
        refreshProjects();
        // Set the new project as selected
        handleSelectChange(projectNumber);
        // Load the new project
        loadNewProject(projectNumber);
      } else {
        // Handle error
        console.error('Failed to create new project:', response.status, response.statusText);
        alert('Failed to create new project');
      }
    } catch (error) {
      // Handle network or fetch-related error
      console.error('Error creating new project:', error);
      alert('Failed to create new project');
    }
  });

  return (
    <div>
      <h4>Legg til nytt prosjekt</h4>
      <form onSubmit={handleFormSubmit}>
        <input {...register('projectNumber')} type="text" placeholder="Prosjektnummer" />
        <input {...register('projectName')} type="text" placeholder="Prosjektnavn" />
        <div>
          <label>Koordinatsystem</label>
          <ReactSelect
            options = {coordinateOptions}
            onChange={handleCoordinateSelect}
            placeholder="Koordinatsystem"
          />      
        </div>
        <div>
          <label>Vertikalt datum</label>
          <ReactSelect
            options = {verticalDatum}
            onChange={handleVerticalSelect}
            placeholder="Vertikal datum"
          />
        </div>
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default NewProjectForm;
