import './ExpandableContainer.scss';

type Props = {
  isExpanded: boolean;
  children?: React.ReactNode;
};

//TODO: make container scrollable
export function ExpandableContainer(props: Props) {
  const { isExpanded, children } = props;
  return (
    <div
      style={{ overflowY: 'auto' }}
      className={isExpanded ? 'expandable-container' : 'minimized-container'}
    >
      {children}
    </div>
  );
}
