export const MinimizeIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1573 1.37346L16.6256 1.84169L12.5708 5.89647L12.2178 6.24948L12.5703 6.60304L14.4615 8.50002H9.49935V3.54046L11.3875 5.42857L11.7407 5.78179L12.0942 5.42891L16.1573 1.37346ZM5.4279 11.3881L3.53979 9.50002H8.49935V14.4622L6.60237 12.5709L6.24881 12.2184L5.8958 12.5715L1.84102 16.6262L1.37279 16.158L5.42824 12.0949L5.78112 11.7414L5.4279 11.3881Z"
        fill="#063342"
        stroke="#063342"
      />
    </svg>
  );
};
