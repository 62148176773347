import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from '../auth/authentication';

const clientId = process.env.REACT_APP_CLIENT_ID;

export function useFetchAuthenticated() {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0]);

  async function fetchAuthenticated(
    url: string,
    options: RequestInit = { method: 'GET' }
  ): Promise<Response> {
    if (!account) {
      throw Error('User not logged in');
    }
    const tokenResponse = await getToken(instance, account);

    return fetch(url, {
      ...options,
      headers: {
        Authorization: 'Bearer ' + tokenResponse.accessToken,
        ...options.headers,
      },
    });
  }

  return {
    fetchAuthenticated,
  };
}

export async function getToken(instance: IPublicClientApplication, account: AccountInfo) {
  try {
    return await instance.acquireTokenSilent({
      account,
      scopes: [clientId + '/.default', 'email', 'openid'],
    });
  } catch (e) {
    instance.loginRedirect(loginRequest);
    throw e;
  }
}
