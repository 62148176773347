import { SketchLayer } from './mapHelper';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';

export const highlightedGraphicsFillSymbol = new SimpleFillSymbol({
  color: [255, 125, 0, 0.6],
  outline: {
    color: [0, 255, 255],
    width: 3,
  },
});

export const seizedGraphicFillSymbol = new SimpleFillSymbol({
  color: [255, 125, 0, 0.3],
  outline: {
    color: 'orange',
  },
});

export const compensateGraphicFillSymbol = new SimpleFillSymbol({
  color: [255, 0, 0, 0.3],
  outline: {
    style: 'short-dash',
    color: 'red',
  },
});
export const restoreGraphicFillSymbol = new SimpleFillSymbol({
  color: [0, 255, 0, 0.3],
  outline: {
    style: 'short-dash',
    color: 'green',
  },
});

export const lineGraphicSymbol = new SimpleLineSymbol({
  color: [255, 255, 255, 1],
  width: 1,
});

export const getFillsymbol = (activeLayer: SketchLayer): SimpleFillSymbol => {
  if (activeLayer === 'compensate') {
    return compensateGraphicFillSymbol;
  }
  if (activeLayer === 'restore') {
    return restoreGraphicFillSymbol;
  } else {
    return seizedGraphicFillSymbol;
  }
};

export const barChartValueColors = [
  'rgba(200,200,200,1)',
  'rgba(255, 204, 80, 0.8)',
  'rgba(255, 153, 51, 0.8)',
  'rgba(255, 80, 80, 0.8)',
  'rgba(255, 117, 209, 0.8)',
];
export const pieChartValueColors = ['#16A97C', '#faab4b', '#818285', '#425B67', '#3D6FB7'];
