import { useState, useEffect } from 'react';
import './LoadingBar.scss';

function LoadingBar({ progress }: { progress: number }) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(progress);
  }, [progress]);

  return (
    <div className="loading-bar-container">
        <div className="loading-bar" style={{ width: `${width}%` }}>
            <div className="loading-text">{`${Number(progress.toFixed(1))}%`}</div>
        </div>
    </div>
  );
}

export default LoadingBar;
