export function SplashIcon() {
    return (
        <svg width="320" height="286" viewBox="0 0 320 286" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M155.994 246.485L30.1431 167.435C26.1376 165.039 22.6993 161.116 26.6068 158.556L152.913 81.1513C154.81 79.9009 157.689 79.0822 159.614 80.273L291.725 161.786C295.731 164.257 296.452 171.164 292.426 173.642L172.604 246.381C170.083 247.933 167.225 248.759 164.312 248.777C161.399 248.796 158.532 248.005 155.994 246.485V246.485Z" fill="#FFDCB4"/>
            <path d="M159.433 176.962L214.312 211.727L228.086 203.004L172.485 165.046" fill="#FDC98A"/>
            <path d="M131.59 217.599L165.433 240.434L180.335 230.051L144.643 205.684" fill="#FDC98A"/>
            <path d="M65.1914 169.229L86.6474 182.418L106.248 171.812L84.6096 157.507" fill="#FDC98A"/>
            <path d="M73.4549 171.172C81.4488 171.172 87.9292 166.323 87.9292 160.342C87.9292 154.362 81.4488 149.513 73.4549 149.513C65.4609 149.513 58.9805 154.362 58.9805 160.342C58.9805 166.323 65.4609 171.172 73.4549 171.172Z" fill="#F8992E"/>
            <path d="M73.4611 158.66C79.4479 158.66 84.3012 156.548 84.3012 153.941C84.3012 151.335 79.4479 149.223 73.4611 149.223C67.4743 149.223 62.6211 151.335 62.6211 153.941C62.6211 156.548 67.4743 158.66 73.4611 158.66Z" fill="#974C26"/>
            <path d="M74.1829 154.09C75.0372 152.818 75.8915 151.485 76.7879 149.989C77.9363 148.077 79.0077 146.119 79.988 144.11C80.9038 142.322 81.6858 140.461 82.3269 138.543C83.0413 136.46 83.6146 134.326 84.0426 132.157C84.1546 131.591 84.2456 131.018 84.3437 130.445C84.3857 130.177 84.3927 130.214 84.3927 130.251C84.3927 130.289 84.3927 130.065 84.4277 129.753C84.5257 128.584 84.5958 127.423 84.6098 126.255C84.6626 122.212 83.9308 118.201 82.46 114.473C78.5603 120.503 75.9327 127.358 74.7571 134.568C74.6824 135 74.6124 135.431 74.547 135.863C74.547 135.863 74.435 136.652 74.393 136.965C74.351 137.277 74.2879 137.962 74.2739 138.051C74.2226 138.602 74.1759 139.153 74.1339 139.704C73.9728 141.825 73.9028 143.953 73.9098 146.082C73.8678 148.739 73.9868 151.404 74.1829 154.09ZM78.7696 122.258C79.031 121.94 79.3018 121.633 79.5819 121.335C79.509 121.603 79.4923 121.885 79.5329 122.161C79.2753 122.151 79.0177 122.184 78.7696 122.258Z" fill="#999A1C"/>
            <path d="M74.4558 154.247C74.5258 152.691 74.5678 151.069 74.5608 149.305C74.5608 147.072 74.4628 144.772 74.2667 142.517C74.1288 140.486 73.8408 138.471 73.4054 136.488C72.9465 134.327 72.3427 132.202 71.5987 130.132C71.4026 129.589 71.1926 129.053 70.9755 128.517C70.8774 128.271 70.8984 128.294 70.9124 128.324L70.6954 127.884C70.1842 126.85 69.659 125.83 69.0708 124.84C67.0557 121.413 64.3978 118.464 61.2629 116.177C61.0374 123.501 62.3059 130.791 64.9812 137.545C65.1353 137.952 65.294 138.356 65.4574 138.758L65.8916 139.778L66.3468 140.76C66.5802 141.257 66.8136 141.753 67.047 142.249C67.9924 144.125 69.0147 145.97 70.1002 147.749C71.4867 149.96 72.9432 152.126 74.4558 154.247ZM62.1662 124.848C62.2269 124.431 62.2969 124.017 62.3763 123.605C62.4487 123.88 62.5779 124.134 62.7544 124.349C62.5356 124.483 62.3373 124.651 62.1662 124.848Z" fill="#999A1C"/>
            <path d="M74.5257 155.058C74.0916 154.195 73.6224 153.309 73.0832 152.356C72.3829 151.136 71.6827 149.945 70.8634 148.791C70.1727 147.736 69.4047 146.741 68.5665 145.814C67.6622 144.804 66.691 143.864 65.6604 143.001C65.3873 142.777 65.1142 142.562 64.8341 142.346C64.7081 142.249 64.7221 142.249 64.7431 142.264L64.491 142.1C63.9028 141.721 63.3075 141.356 62.6913 141.013C60.5623 139.853 58.2309 139.171 55.8428 139.011C57.9446 143.05 60.8449 146.557 64.3439 149.29L64.9672 149.781L65.5064 150.183L66.0526 150.555L66.8649 151.113C67.9433 151.803 69.0497 152.443 70.1841 153.033C71.6196 153.77 73.0692 154.44 74.5257 155.058ZM58.9659 143.41C58.8726 143.162 58.7839 142.914 58.6998 142.666C58.8205 142.784 58.9639 142.873 59.12 142.926C59.0455 143.078 58.9935 143.241 58.9659 143.41Z" fill="#999A1C"/>
            <path d="M74.1829 154.09C75.0372 152.818 75.8915 151.485 76.7879 149.989C77.9363 148.077 79.0077 146.119 79.988 144.11C80.9038 142.322 81.6858 140.461 82.3269 138.543C83.0413 136.46 83.6146 134.326 84.0426 132.157C84.1546 131.591 84.2456 131.018 84.3437 130.445C84.3857 130.177 84.3927 130.214 84.3927 130.251C84.3927 130.289 84.3927 130.065 84.4277 129.753C84.5257 128.584 84.5958 127.423 84.6098 126.255C84.6626 122.212 83.9308 118.201 82.46 114.473C78.5603 120.503 75.9327 127.358 74.7571 134.568C74.6824 135 74.6124 135.431 74.547 135.863C74.547 135.863 74.435 136.652 74.393 136.965C74.351 137.277 74.2879 137.962 74.2739 138.051C74.2226 138.602 74.1759 139.153 74.1339 139.704C73.9728 141.825 73.9028 143.953 73.9098 146.082C73.8678 148.739 73.9868 151.404 74.1829 154.09ZM78.7696 122.258C79.031 121.94 79.3018 121.633 79.5819 121.335C79.509 121.603 79.4923 121.885 79.5329 122.161C79.2753 122.151 79.0177 122.184 78.7696 122.258Z" fill="#009262"/>
            <path d="M74.4558 154.247C74.5258 152.691 74.5678 151.069 74.5608 149.305C74.5608 147.072 74.4628 144.772 74.2667 142.517C74.1288 140.486 73.8408 138.471 73.4054 136.488C72.9465 134.327 72.3427 132.202 71.5987 130.132C71.4026 129.589 71.1926 129.053 70.9755 128.517C70.8774 128.271 70.8984 128.294 70.9124 128.324L70.6954 127.884C70.1842 126.85 69.659 125.83 69.0708 124.84C67.0557 121.413 64.3978 118.464 61.2629 116.177C61.0374 123.501 62.3059 130.791 64.9812 137.545C65.1353 137.952 65.294 138.356 65.4574 138.758L65.8916 139.778L66.3468 140.76C66.5802 141.257 66.8136 141.753 67.047 142.249C67.9924 144.125 69.0147 145.97 70.1002 147.749C71.4867 149.96 72.9432 152.126 74.4558 154.247ZM62.1662 124.848C62.2269 124.431 62.2969 124.017 62.3763 123.605C62.4487 123.88 62.5779 124.134 62.7544 124.349C62.5356 124.483 62.3373 124.651 62.1662 124.848Z" fill="#009262"/>
            <path d="M74.5257 155.058C74.0916 154.195 73.6224 153.309 73.0832 152.356C72.3829 151.136 71.6827 149.945 70.8634 148.791C70.1727 147.736 69.4047 146.741 68.5665 145.814C67.6622 144.804 66.691 143.864 65.6604 143.001C65.3873 142.777 65.1142 142.562 64.8341 142.346C64.7081 142.249 64.7221 142.249 64.7431 142.264L64.491 142.1C63.9028 141.721 63.3075 141.356 62.6913 141.013C60.5623 139.853 58.2309 139.171 55.8428 139.011C57.9446 143.05 60.8449 146.557 64.3439 149.29L64.9672 149.781L65.5064 150.183L66.0526 150.555L66.8649 151.113C67.9433 151.803 69.0497 152.443 70.1841 153.033C71.6196 153.77 73.0692 154.44 74.5257 155.058ZM58.9659 143.41C58.8726 143.162 58.7839 142.914 58.6998 142.666C58.8205 142.784 58.9639 142.873 59.12 142.926C59.0455 143.078 58.9935 143.241 58.9659 143.41Z" fill="#009262"/>
            <path d="M153.627 37.5668L148.55 39.9559L153.431 42.2334L154.607 39.554" fill="#58717D"/>
            <path d="M199.915 196.179L157.717 170.04C156.015 168.99 154.602 167.483 153.619 165.67C152.636 163.857 152.117 161.802 152.115 159.71L150.764 40.9605C150.764 37.842 155.315 36.2641 157.836 37.842L201.498 65.2462C202.58 65.9254 203.478 66.8929 204.102 68.0531C204.726 69.2134 205.054 70.5265 205.055 71.8629V193.038C205.056 193.678 204.897 194.306 204.596 194.86C204.295 195.413 203.862 195.873 203.341 196.191C202.819 196.51 202.228 196.677 201.626 196.674C201.025 196.672 200.434 196.502 199.915 196.179Z" fill="#A7A9AC"/>
            <path d="M203.29 196.209L197.771 199.313L198.416 195.219L202.246 194.49" fill="#A7A9AC"/>
            <path d="M194.62 199.149L152.422 173.01C150.72 171.959 149.307 170.452 148.324 168.639C147.341 166.827 146.822 164.772 146.82 162.679V42.8509C146.821 42.291 146.961 41.7412 147.226 41.2574C147.492 40.7735 147.872 40.3726 148.33 40.0954C148.788 39.8181 149.306 39.6743 149.833 39.6786C150.36 39.6828 150.876 39.8349 151.33 40.1195L196.203 68.2531C197.286 68.9323 198.183 69.8997 198.807 71.06C199.431 72.2203 199.759 73.5333 199.76 74.8697V196.015C199.759 196.654 199.6 197.281 199.299 197.834C198.997 198.387 198.564 198.845 198.043 199.163C197.522 199.48 196.931 199.646 196.33 199.644C195.729 199.642 195.139 199.471 194.62 199.149Z" fill="#E6EAED"/>
            <path d="M197.086 82.1936L149.006 52.8915V157.738L197.086 187.278V82.1936Z" fill="white"/>
            <path d="M169.267 179.952C170.459 179.65 171.115 178.02 170.731 176.313C170.348 174.606 169.07 173.467 167.878 173.77C166.685 174.073 166.03 175.702 166.413 177.409C166.797 179.116 168.074 180.255 169.267 179.952Z" fill="#BABABC"/>
            <path d="M166.771 57.9302C166.77 58.131 166.82 58.3284 166.915 58.5023C167.009 58.6761 167.146 58.8203 167.31 58.92L176.595 64.5914C176.758 64.6925 176.944 64.7458 177.133 64.7462C177.322 64.7465 177.507 64.6937 177.671 64.5932C177.834 64.4927 177.97 64.348 178.064 64.1739C178.158 63.9997 178.207 63.8023 178.206 63.6015V63.3634C178.206 63.1597 178.155 62.9598 178.057 62.7844C177.96 62.609 177.82 62.4645 177.653 62.366L168.367 56.9328C168.204 56.8352 168.019 56.7848 167.832 56.7867C167.644 56.7886 167.461 56.8427 167.299 56.9436C167.137 57.0446 167.004 57.1888 166.911 57.3619C166.818 57.535 166.77 57.7309 166.771 57.9302Z" fill="#CCCCCC"/>
            <path d="M162.996 56.341C163.54 56.127 163.758 55.3129 163.483 54.5226C163.208 53.7323 162.544 53.2652 162 53.4792C161.456 53.6932 161.238 54.5073 161.513 55.2976C161.788 56.0879 162.452 56.555 162.996 56.341Z" fill="#CCCCCC"/>
            <path d="M222.686 111.704C222.609 111.86 222.238 113.364 221.944 113.051C221.65 112.739 220.088 113.453 220.088 113.453L215.726 107.558L216.307 105.631H220.221" fill="#F8992E"/>
            <path d="M201.616 86.2943L201.203 84.7611L196.616 82.104V73.8054C196.616 73.6416 201.056 76.5741 201.056 76.5741L201.266 76.1126C201.515 74.8429 201.97 73.6289 202.61 72.5252L199.382 66.184L203.486 62.8719L206.553 69.4067C207.669 69.1349 208.83 69.1579 209.935 69.4737V63.2069L215.803 66.6678V73.2248C216.955 74.3976 218.003 75.6807 218.934 77.0578L222.47 74.6538L226.524 82.7441L223.359 85.4235L223.632 86.7781C224.042 88.212 224.369 89.671 224.613 91.147L229.318 93.9008V102.17L224.837 99.3266C224.642 100.834 224.148 102.28 223.387 103.569L226.748 109.627L222.491 113.051L219.501 106.814H215.782V113.051L210.18 109.426V103.294C208.893 101.95 207.758 100.452 206.798 98.8279L203.416 101.291L199.34 93.0598L202.715 90.3878L202.386 89.1523C202.078 88.1922 201.826 87.2395 201.616 86.2943Z" fill="#F8992E"/>
            <path d="M190.972 77.2588L196.168 73.9244L197.456 75.9638L195.453 80.4294" fill="#F8992E"/>
            <path d="M197.765 104.752L201.602 103.055V100.584" fill="#F8992E"/>
            <path d="M210.111 116.505L215.748 113.051L214.319 108.518L209.242 110.104L210.111 116.505Z" fill="#F8992E"/>
            <path d="M203.479 62.8647L197.835 66.3256L200.937 72.8753L204.319 72.9422L205.104 66.3256L203.479 62.8647Z" fill="#F8992E"/>
            <path d="M208.532 95.8145C209.838 95.2689 210.181 92.8881 209.297 90.497C208.413 88.1059 206.637 86.6098 205.331 87.1554C204.024 87.7011 203.681 90.0818 204.565 92.473C205.449 94.8641 207.225 96.3602 208.532 95.8145Z" fill="#F8992E"/>
            <path d="M215.825 66.6679L210.188 70.1214L204.319 66.6605L209.956 63.207L215.825 66.6679Z" fill="#F8992E"/>
            <path d="M216.854 78.1147L222.498 74.6613L226.489 82.7441L220.852 86.1975L216.854 78.1147Z" fill="#F8992E"/>
            <path d="M226.713 109.628L221.076 113.081L216.818 116.505L222.455 113.051L226.713 109.628Z" fill="#0B0B0B"/>
            <path opacity="0.44" d="M222.686 111.704C222.609 111.86 222.238 113.364 221.944 113.051C221.65 112.739 220.088 113.453 220.088 113.453L215.726 107.558L216.307 105.631H220.221" fill="#053855"/>
            <path d="M201.616 86.2943L201.203 84.7611L196.616 82.104V73.8054C196.616 73.6416 201.056 76.5741 201.056 76.5741L201.266 76.1126C201.515 74.8429 201.97 73.6289 202.61 72.5252L199.382 66.184L203.486 62.8719L206.553 69.4067C207.669 69.1349 208.83 69.1579 209.935 69.4737V63.2069L215.803 66.6678V73.2248C216.955 74.3976 218.003 75.6807 218.934 77.0578L222.47 74.6538L226.524 82.7441L223.359 85.4235L223.632 86.7781C224.042 88.212 224.369 89.671 224.613 91.147L229.318 93.9008V102.17L224.837 99.3266C224.642 100.834 224.148 102.28 223.387 103.569L226.748 109.627L222.491 113.051L219.501 106.814H215.782V113.051L210.18 109.426V103.294C208.893 101.95 207.758 100.452 206.798 98.8279L203.416 101.291L199.34 93.0598L202.715 90.3878L202.386 89.1523C202.078 88.1922 201.826 87.2395 201.616 86.2943Z" fill="#F8992E"/>
            <path opacity="0.44" d="M190.972 77.2588L196.168 73.9244L197.456 75.9638L195.453 80.4294" fill="#053855"/>
            <path d="M197.765 104.752L201.602 103.055V100.584" fill="#F8992E"/>
            <path d="M210.111 116.505L215.748 113.051L214.319 108.518L209.242 110.104L210.111 116.505Z" fill="#F8992E"/>
            <path d="M203.479 62.8647L197.835 66.3256L200.937 72.8753L204.319 72.9422L205.104 66.3256L203.479 62.8647Z" fill="#F8992E"/>
            <path d="M208.532 95.8145C209.838 95.2689 210.181 92.8881 209.297 90.497C208.413 88.1059 206.637 86.6098 205.331 87.1554C204.024 87.7011 203.681 90.0818 204.565 92.473C205.449 94.8641 207.225 96.3602 208.532 95.8145Z" fill="#F8992E"/>
            <path d="M216.854 78.1147L222.498 74.6613L226.489 82.7441L220.852 86.1975L216.854 78.1147Z" fill="#F8992E"/>
            <path d="M226.713 109.628L221.076 113.081L216.818 116.505L222.455 113.051L226.713 109.628Z" fill="#F8992E"/>
            <path opacity="0.44" d="M201.616 86.2943L201.203 84.7611L196.616 82.104V73.8054C196.616 73.6416 201.056 76.5741 201.056 76.5741L201.266 76.1126C201.515 74.8429 201.97 73.6289 202.61 72.5252L199.382 66.184L203.486 62.8719L206.553 69.4067C207.669 69.1349 208.83 69.1579 209.935 69.4737V63.2069L215.803 66.6678V73.2248C216.955 74.3976 218.003 75.6807 218.934 77.0578L222.47 74.6538L226.524 82.7441L223.359 85.4235L223.632 86.7781C224.042 88.212 224.369 89.671 224.613 91.147L229.318 93.9008V102.17L224.837 99.3266C224.642 100.834 224.148 102.28 223.387 103.569L226.748 109.627L222.491 113.051L219.501 106.814H215.782V113.051L210.18 109.426V103.294C208.893 101.95 207.758 100.452 206.798 98.8279L203.416 101.291L199.34 93.0598L202.715 90.3878L202.386 89.1523C202.078 88.1922 201.826 87.2395 201.616 86.2943Z" fill="#053855"/>
            <path opacity="0.1" d="M197.765 104.752L201.602 103.055V100.584" fill="#053855"/>
            <path opacity="0.1" d="M210.111 116.505L215.748 113.051L214.319 108.518L209.242 110.104L210.111 116.505Z" fill="#053855"/>
            <path opacity="0.1" d="M208.532 95.8145C209.838 95.2689 210.181 92.8881 209.297 90.497C208.413 88.1059 206.637 86.6098 205.331 87.1554C204.024 87.7011 203.681 90.0818 204.565 92.473C205.449 94.8641 207.225 96.3602 208.532 95.8145Z" fill="#053855"/>
            <path opacity="0.1" d="M216.854 78.1147L222.498 74.6613L226.489 82.7441L220.852 86.1975L216.854 78.1147Z" fill="#053855"/>
            <path opacity="0.1" d="M226.713 109.628L221.076 113.081L216.818 116.505L222.455 113.051L226.713 109.628Z" fill="#053855"/>
            <path opacity="0.1" d="M197.765 104.752L201.602 103.055V100.584" fill="#053855"/>
            <path opacity="0.44" d="M210.111 116.505L215.748 113.051L214.319 108.518L209.242 110.104L210.111 116.505Z" fill="#053855"/>
            <path d="M195.972 89.7555L195.558 88.2223L190.972 85.5652V77.2591C190.972 77.0954 195.411 80.0278 195.411 80.0278L195.621 79.5663C195.87 78.2966 196.325 77.0827 196.966 75.9789L193.738 69.6377L197.813 66.3257L200.88 72.8604C201.996 72.5886 203.157 72.6116 204.263 72.9274V66.668L210.131 70.1289V76.686C211.283 77.8588 212.331 79.1419 213.261 80.519L216.797 78.115L220.796 86.1978L217.631 88.8772L217.904 90.2318C218.313 91.6657 218.641 93.1247 218.884 94.6007L223.59 97.3545V105.631L219.108 102.788C218.914 104.295 218.42 105.741 217.659 107.03L221.02 113.089L216.762 116.512L213.772 110.275H210.054V116.512L204.452 112.888V106.755C203.164 105.412 202.029 103.913 201.069 102.289L197.701 104.738L193.64 96.4837L197.015 93.8341L196.686 92.5986C196.413 91.6459 196.182 90.6933 195.972 89.7555Z" fill="#F8992E"/>
            <path opacity="0.44" d="M208.532 95.8145C209.838 95.2689 210.181 92.8881 209.297 90.497C208.413 88.1059 206.637 86.6098 205.331 87.1554C204.024 87.7011 203.681 90.0818 204.565 92.473C205.449 94.8641 207.225 96.3602 208.532 95.8145Z" fill="#053855"/>
            <path opacity="0.44" d="M226.713 109.628L221.076 113.081L216.818 116.505L222.455 113.051L226.713 109.628Z" fill="#053855"/>
            <path d="M215.825 66.6679L210.188 70.1214L204.319 66.6605L209.956 63.207L215.825 66.6679Z" fill="#F8992E"/>
            <path opacity="0.44" d="M215.825 66.6456L210.202 70.1214L204.319 66.6902L209.949 63.207L215.825 66.6456Z" fill="#053855"/>
            <path d="M229.284 93.8933L223.646 97.3542V105.623L229.284 102.17V93.8933Z" fill="#F8992E"/>
            <path opacity="0.44" d="M229.284 93.8933L223.646 97.3542V105.623L229.284 102.17V93.8933Z" fill="#053855"/>
            <path d="M182.555 123.903V154.909L176.504 151.15L176.448 120.159L182.555 123.903Z" fill="#009262"/>
            <path d="M172.394 112.456L172.499 148.896L166.162 144.981V108.712L172.394 112.456Z" fill="#CBD6DD"/>
            <path d="M162.156 102.594L162.254 142.807L156.61 139.324V99.0066L162.156 102.594Z" fill="#FAAB4B"/>
            <path d="M182.023 158.712C182.023 158.974 181.958 159.23 181.835 159.456C181.712 159.682 181.535 159.87 181.322 160C181.109 160.131 180.868 160.2 180.622 160.199C180.376 160.199 180.135 160.131 179.922 160L153.936 144.229C153.723 144.098 153.546 143.911 153.423 143.685C153.3 143.459 153.236 143.202 153.235 142.941C153.236 142.68 153.301 142.424 153.424 142.198C153.547 141.971 153.723 141.784 153.936 141.653C154.149 141.523 154.391 141.454 154.636 141.454C154.882 141.454 155.123 141.523 155.336 141.654L181.323 157.388C181.541 157.521 181.721 157.715 181.844 157.948C181.967 158.181 182.029 158.445 182.023 158.712Z" fill="#FAAB4B"/>
            <path d="M187.681 98.1878V109.352C187.681 109.627 187.612 109.898 187.483 110.136C187.354 110.375 187.168 110.573 186.943 110.711C186.719 110.849 186.465 110.922 186.206 110.922C185.947 110.923 185.692 110.851 185.468 110.714L156.869 93.1714C156.645 93.0329 156.459 92.8342 156.33 92.5951C156.201 92.356 156.133 92.085 156.134 91.8094V80.6452C156.134 80.3699 156.202 80.0995 156.331 79.861C156.461 79.6225 156.647 79.4243 156.871 79.2864C157.095 79.1484 157.349 79.0755 157.608 79.075C157.867 79.0744 158.122 79.1462 158.347 79.2832L186.945 96.8332C187.168 96.9711 187.354 97.1687 187.483 97.4063C187.612 97.644 187.68 97.9134 187.681 98.1878Z" fill="#FAAB4B"/>
            <path d="M182.696 105.715C184.661 105.052 185.622 102.4 184.842 99.7902C184.063 97.1809 181.839 95.603 179.874 96.2658C177.909 96.9286 176.948 99.5812 177.727 102.191C178.507 104.8 180.731 106.378 182.696 105.715Z" fill="white"/>
            <path d="M176.708 94.1838L160 84.2328C159.412 83.883 158.879 84.8431 159.468 85.2004L176.183 95.1439C176.771 95.4937 177.303 94.5336 176.715 94.1763L176.708 94.1838Z" fill="white"/>
            <path d="M176.106 98.5229L165.7 92.3157C165.112 91.9659 164.58 92.926 165.168 93.2832L175.56 99.4682C176.148 99.818 176.68 98.8579 176.092 98.5006L176.106 98.5229Z" fill="white"/>
            <path d="M152.899 95.3896V64.1971C152.899 63.5445 152.74 62.9032 152.436 62.3357C152.133 61.7683 151.697 61.2941 151.17 60.9595L132.487 49.0957C132.147 48.8821 131.761 48.7675 131.366 48.7637C130.971 48.7598 130.583 48.8667 130.24 49.0737C129.896 49.2806 129.611 49.5802 129.411 49.9422C129.212 50.3041 129.106 50.7156 129.104 51.135V82.3053C129.105 83.0229 129.287 83.7272 129.631 84.3449C129.974 84.9627 130.467 85.4714 131.058 85.8183L150.504 96.9154C150.753 97.055 151.03 97.1242 151.311 97.1163C151.592 97.1083 151.866 97.0236 152.107 96.8701C152.347 96.7167 152.547 96.4998 152.686 96.2405C152.825 95.9811 152.898 95.6881 152.899 95.3896Z" fill="#F8992E"/>
            <path d="M136.989 61.1082C141.001 59.7536 145.497 63.2369 147.234 69.0273C148.019 71.5391 148.075 74.244 147.395 76.7901C146.695 79.3281 145.147 81.0995 143.137 81.7768C142.569 81.9691 141.976 82.0671 141.38 82.067C139.762 82.0216 138.204 81.4034 136.954 80.3105C135.022 78.6414 133.606 76.393 132.892 73.8577C131.184 68.0598 132.976 62.4628 136.989 61.1082Z" fill="white"/>
            <path d="M140.07 60.9666V71.7065L132.647 66.3403C132.647 66.3403 134.09 59.8502 140.07 60.9666Z" fill="#2D4651"/>
            <path d="M140.071 71.7064L132.55 72.3688C132.55 72.3688 131.604 68.9154 132.648 66.3328L140.071 71.7064Z" fill="#009262"/>
            <path d="M238.296 159.256C238.233 159.382 237.931 160.595 237.693 160.342C237.455 160.089 236.188 160.662 236.188 160.662L232.687 155.892L233.156 154.336H236.328" fill="#F8992E"/>
            <path d="M221.245 138.691L220.915 137.456L217.204 135.305V128.606C217.204 128.472 220.796 130.839 220.796 130.839L220.964 130.467C221.167 129.439 221.537 128.457 222.057 127.564L219.445 122.436L222.764 119.75L225.25 125.041C226.153 124.82 227.093 124.838 227.988 125.093V120.01L232.736 122.808V128.115C233.669 129.065 234.517 130.104 235.271 131.219L238.135 129.276L241.37 135.818L238.807 137.984L239.024 139.078C239.355 140.239 239.619 141.419 239.816 142.614L243.625 144.846V151.545L239.998 149.245C239.841 150.464 239.442 151.634 238.828 152.676L241.545 157.581L238.1 160.35L235.677 155.304H232.666V160.35L228.135 157.417V152.453C227.094 151.37 226.175 150.161 225.397 148.851L222.673 150.83L219.382 144.132L222.113 141.988L221.847 140.984C221.623 140.225 221.413 139.458 221.245 138.691Z" fill="#F8992E"/>
            <path d="M212.631 131.383L216.832 128.681L217.876 130.333L216.251 133.943" fill="#F8992E"/>
            <path d="M218.128 153.629L221.23 152.26V150.258" fill="#F8992E"/>
            <path d="M228.121 163.141L232.68 160.35L231.524 156.673L227.421 157.961L228.121 163.141Z" fill="#F8992E"/>
            <path d="M222.75 119.735L218.191 122.526L220.691 127.833L223.429 127.885L224.067 122.526L222.75 119.735Z" fill="#F8992E"/>
            <path d="M226.845 146.399C227.903 145.957 228.181 144.029 227.465 142.093C226.749 140.158 225.311 138.946 224.253 139.388C223.195 139.83 222.917 141.758 223.633 143.694C224.348 145.63 225.786 146.841 226.845 146.399Z" fill="#F8992E"/>
            <path d="M232.742 122.809L228.183 125.607L223.429 122.809L227.994 120.01L232.742 122.809Z" fill="#F8992E"/>
            <path d="M233.583 132.075L238.142 129.276L241.377 135.819L236.811 138.617L233.583 132.075Z" fill="#F8992E"/>
            <path d="M241.559 157.574L236.993 160.372L233.548 163.141L238.114 160.35L241.559 157.574Z" fill="#F8992E"/>
            <path opacity="0.44" d="M238.296 159.256C238.233 159.382 237.931 160.595 237.693 160.342C237.455 160.089 236.188 160.662 236.188 160.662L232.687 155.892L233.156 154.336H236.328" fill="#053855"/>
            <path d="M221.245 138.691L220.915 137.456L217.204 135.305V128.606C217.204 128.472 220.796 130.839 220.796 130.839L220.964 130.467C221.167 129.439 221.537 128.457 222.057 127.564L219.445 122.436L222.764 119.75L225.25 125.041C226.153 124.82 227.093 124.838 227.988 125.093V120.01L232.736 122.808V128.115C233.669 129.065 234.517 130.104 235.271 131.219L238.135 129.276L241.37 135.818L238.807 137.984L239.024 139.078C239.355 140.239 239.619 141.419 239.816 142.614L243.625 144.846V151.545L239.998 149.245C239.841 150.464 239.442 151.634 238.828 152.676L241.545 157.581L238.1 160.35L235.677 155.304H232.666V160.35L228.135 157.417V152.453C227.094 151.37 226.175 150.161 225.397 148.851L222.673 150.83L219.382 144.132L222.113 141.988L221.847 140.984C221.623 140.225 221.413 139.458 221.245 138.691Z" fill="#F8992E"/>
            <path opacity="0.44" d="M212.631 131.383L216.832 128.681L217.876 130.333L216.251 133.943" fill="#053855"/>
            <path d="M218.128 153.629L221.23 152.26V150.258" fill="#F8992E"/>
            <path d="M228.121 163.141L232.68 160.35L231.524 156.673L227.421 157.961L228.121 163.141Z" fill="#F8992E"/>
            <path d="M222.75 119.735L218.191 122.526L220.691 127.833L223.429 127.885L224.067 122.526L222.75 119.735Z" fill="#F8992E"/>
            <path d="M226.845 146.399C227.903 145.957 228.181 144.029 227.465 142.093C226.749 140.158 225.311 138.946 224.253 139.388C223.195 139.83 222.917 141.758 223.633 143.694C224.348 145.63 225.786 146.841 226.845 146.399Z" fill="#F8992E"/>
            <path d="M233.583 132.075L238.142 129.276L241.377 135.819L236.811 138.617L233.583 132.075Z" fill="#F8992E"/>
            <path d="M241.559 157.574L236.993 160.372L233.548 163.141L238.114 160.35L241.559 157.574Z" fill="#F8992E"/>
            <path opacity="0.44" d="M221.245 138.691L220.915 137.456L217.204 135.305V128.606C217.204 128.472 220.796 130.839 220.796 130.839L220.964 130.467C221.167 129.439 221.537 128.457 222.057 127.564L219.445 122.436L222.764 119.75L225.25 125.041C226.153 124.82 227.093 124.838 227.988 125.093V120.01L232.736 122.808V128.115C233.669 129.065 234.517 130.104 235.271 131.219L238.135 129.276L241.37 135.818L238.807 137.984L239.024 139.078C239.355 140.239 239.619 141.419 239.816 142.614L243.625 144.846V151.545L239.998 149.245C239.841 150.464 239.442 151.634 238.828 152.676L241.545 157.581L238.1 160.35L235.677 155.304H232.666V160.35L228.135 157.417V152.453C227.094 151.37 226.175 150.161 225.397 148.851L222.673 150.83L219.382 144.132L222.113 141.988L221.847 140.984C221.623 140.225 221.413 139.458 221.245 138.691Z" fill="#053855"/>
            <path opacity="0.1" d="M218.128 153.629L221.23 152.26V150.258" fill="#053855"/>
            <path opacity="0.1" d="M228.121 163.141L232.68 160.35L231.524 156.673L227.421 157.961L228.121 163.141Z" fill="#053855"/>
            <path opacity="0.1" d="M226.845 146.399C227.903 145.957 228.181 144.029 227.465 142.093C226.749 140.158 225.311 138.946 224.253 139.388C223.195 139.83 222.917 141.758 223.633 143.694C224.348 145.63 225.786 146.841 226.845 146.399Z" fill="#053855"/>
            <path opacity="0.1" d="M233.583 132.075L238.142 129.276L241.377 135.819L236.811 138.617L233.583 132.075Z" fill="#053855"/>
            <path opacity="0.1" d="M241.559 157.574L236.993 160.372L233.548 163.141L238.114 160.35L241.559 157.574Z" fill="#053855"/>
            <path opacity="0.1" d="M218.128 153.629L221.23 152.26V150.258" fill="#053855"/>
            <path opacity="0.44" d="M228.121 163.141L232.68 160.35L231.524 156.673L227.421 157.961L228.121 163.141Z" fill="#053855"/>
            <path d="M216.678 141.49L216.349 140.254L212.638 138.103V131.405C212.638 131.271 216.23 133.638 216.23 133.638L216.398 133.266C216.6 132.238 216.97 131.256 217.49 130.363L214.879 125.235L218.198 122.548L220.684 127.84C221.587 127.618 222.527 127.636 223.422 127.892V122.831L228.169 125.629V130.936C229.108 131.87 229.963 132.894 230.725 133.995L233.589 132.052L236.825 138.595L234.227 140.783L234.444 141.877C234.775 143.037 235.039 144.218 235.235 145.412L239.045 147.645V154.344L235.417 152.044C235.261 153.263 234.862 154.432 234.248 155.475L236.965 160.38L233.526 163.141L231.104 158.095H228.092V163.141L223.562 160.208V155.252C222.52 154.168 221.601 152.959 220.824 151.649L218.121 153.629L214.829 146.931L217.561 144.787L217.294 143.782C217.056 143.023 216.846 142.249 216.678 141.49Z" fill="#F8992E"/>
            <path opacity="0.44" d="M226.845 146.399C227.903 145.957 228.181 144.029 227.465 142.093C226.749 140.158 225.311 138.946 224.253 139.388C223.195 139.83 222.917 141.758 223.633 143.694C224.348 145.63 225.786 146.841 226.845 146.399Z" fill="#053855"/>
            <path opacity="0.44" d="M241.559 157.574L236.993 160.372L233.548 163.141L238.114 160.35L241.559 157.574Z" fill="#053855"/>
            <path d="M232.742 122.809L228.183 125.607L223.429 122.809L227.994 120.01L232.742 122.809Z" fill="#F8992E"/>
            <path opacity="0.44" d="M232.742 122.786L228.19 125.607L223.429 122.824L227.987 120.01L232.742 122.786Z" fill="#053855"/>
            <path d="M243.639 144.847L239.073 147.645V154.336L243.639 151.538V144.847Z" fill="#F8992E"/>
            <path opacity="0.44" d="M243.639 144.847L239.073 147.645V154.336L243.639 151.538V144.847Z" fill="#053855"/>
            <path d="M170.027 119.951C170.027 119.951 171.232 117.554 171.652 116.855C172.072 116.155 173.858 113.49 174.593 113.446C175.952 113.356 173.459 116.802 174.019 117.204C174.579 117.606 178.732 113.877 179.621 113.61C181.568 113.029 180.468 114.823 180.468 114.823C180.468 114.823 182.114 114.376 182.436 114.979C182.758 115.582 181.456 116.564 181.456 116.564C181.456 116.564 182.394 116.192 182.464 117.07C182.534 117.949 181.498 118.365 181.498 118.365C181.498 118.365 182.688 118.678 181.967 119.69C181.603 120.204 173.816 123.047 173.024 123.501C172.233 123.955 170.027 119.951 170.027 119.951Z" fill="#EEAD97"/>
            <path d="M180.489 114.815L176.295 117.457" stroke="#8C6239" strokeWidth="0.25375" strokeMiterlimit="10"/>
            <path d="M181.477 116.564L177.282 118.529" stroke="#8C6239" strokeWidth="0.25375" strokeMiterlimit="10"/>
            <path d="M181.497 118.358L177.87 119.735" stroke="#8C6239" strokeWidth="0.25375" strokeMiterlimit="10"/>
            <path d="M144.349 210.871C143.039 209.971 141.24 211.526 140.147 211.377C139.055 211.228 138.6 204.604 138.6 204.604L132.017 204.389L131.625 217.592C132.472 218.214 133.393 218.714 134.363 219.081C134.363 219.081 133.922 213.037 135.119 213.178C136.317 213.32 136.765 217.972 138.186 218.448C139.608 218.924 144.027 215.947 144.762 215.419C145.497 214.89 145.658 211.772 144.349 210.871Z" fill="#C15827"/>
            <path d="M157.983 219.438C156.674 218.537 154.874 220.093 153.782 219.944C152.689 219.795 152.234 213.171 152.234 213.171L145.659 212.955L145.267 226.166C146.112 226.787 147.03 227.288 147.998 227.655C147.998 227.655 147.556 221.611 148.754 221.753C149.951 221.894 150.4 226.546 151.821 227.022C153.243 227.498 157.661 224.521 158.396 223.993C159.132 223.464 159.3 220.331 157.983 219.438Z" fill="#C15827"/>
            <path d="M133.194 145.725C133.194 145.725 128.292 155.646 129.195 211.028C129.195 211.028 134.629 215.017 143.2 212.933C143.2 212.933 143.508 148.36 142.64 146.03C141.772 143.7 133.194 145.725 133.194 145.725Z" fill="#F8992E"/>
            <path d="M149.496 144.556C151.338 157.35 153.2 170.159 154.223 183.058C155.098 194.051 155.322 205.111 155.917 216.126L155.98 217.332C153.522 218.657 150.814 219.378 148.058 219.443C145.302 219.507 142.568 218.913 140.056 217.704C140.056 217.704 137.732 163.93 137.956 156.576C138.18 149.223 138.523 140.694 142.374 139.54C146.226 138.386 149.496 144.556 149.496 144.556Z" fill="#F8992E"/>
            <path d="M132.759 118.566C132.759 118.566 122.886 132.134 125.315 136.756C127.745 141.378 138.004 137.761 138.004 137.761" fill="#2D4651"/>
            <path d="M169.803 118.268C169.803 118.268 161.05 126.552 157.597 124.267C145.168 115.924 142.493 115.969 142.493 115.969C142.493 115.969 133.228 112.791 131.856 117.04C130.483 121.29 131.968 144.966 133.473 146.811L133.557 146.893C134.315 147.417 135.122 147.853 135.966 148.196C137.268 148.622 138.604 148.919 139.958 149.081C142.836 149.208 146.96 148.203 149.306 146.409C150.525 143.083 150.854 131.807 150.854 131.807C150.854 131.807 155.84 135.833 161.512 134.263C166.554 132.864 174.298 124.327 174.298 124.327C174.109 121.015 169.803 118.268 169.803 118.268Z" fill="#425B67"/>
            <path d="M147.731 106.673C147.731 106.673 148.046 115.604 146.379 115.723C144.713 115.842 141.512 114.979 141.526 113.222C141.54 111.466 141.526 107.469 143.046 106.903C144.566 106.338 146.967 105.072 147.731 106.673Z" fill="#EEAD97"/>
            <path d="M147.885 107.663C148.175 107.208 148.367 106.693 148.45 106.152C148.533 105.61 148.504 105.056 148.366 104.528C148.227 104 147.982 103.51 147.648 103.093C147.313 102.675 146.897 102.34 146.429 102.11C142.865 99.9147 136.912 100.376 135.463 105.288C134.097 109.918 135.561 110.751 133.46 112.277C131.359 113.803 128.558 115.403 129.651 118.388C130.743 121.372 131.373 120.323 131.885 121.558C132.396 122.794 130.281 124.729 131.52 126.917C132.76 129.105 138.81 130.877 141.072 128.227C143.334 125.577 142.08 121.789 143.74 121.067C144.544 120.717 145.215 120.092 145.647 119.292C146.08 118.491 146.249 117.56 146.128 116.646C145.764 114.986 142.627 112.031 143.852 110.275C145.077 108.519 147.885 107.663 147.885 107.663Z" fill="#744122"/>
            <path d="M138.34 161.422C138.34 160.752 137.325 160.678 137.339 161.422C137.698 180.192 138.583 198.938 139.993 217.659C140.042 218.322 141.043 218.366 140.994 217.659C139.585 198.943 138.7 180.197 138.34 161.422Z" fill="#F9A234"/>
        </svg>

    )
}