import './LoadingSVG.scss';

export function LoadingSVG() {
  return (
    <svg
      className="svg-icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.33331L8 3.33331"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.7"
      />
      <path
        d="M8 12.6666L8 14.6666"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.2" //ferdig
      />
      <path
        d="M1.33203 8L3.33203 8"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.5" // ferdig
      />
      <path
        d="M12.666 8L14.666 8"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.9" //ferdig
      />
      <path
        d="M3.28501 3.28592L4.69922 4.70013"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.6" //ferdig
      />
      <path
        d="M11.2987 11.3L12.7129 12.7142"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        // opacity="0.1" //ferdig
      />
      <path
        d="M12.713 3.28592L11.2988 4.70013"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.8" //ferdig
      />
      <path
        d="M4.69937 11.3L3.28516 12.7142"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        opacity="0.4" //ferdig
      />
    </svg>
  );
}
