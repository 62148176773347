import { Configuration, IPublicClientApplication, RedirectRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: process.env.REACT_APP_AD_AUTHORITY as string,
    redirectUri: '/',
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ['User.Read'],
};

export function loginUser(instance: IPublicClientApplication) {
  return instance.loginRedirect(loginRequest);
}
