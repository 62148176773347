import React, { useMemo } from 'react';
import { useTable, Column, useSortBy, useRowSelect, usePagination } from 'react-table';
import { Checkbox } from '../Checkbox/Checkbox';
import './SelectableTable.scss';

interface RowData {
  borehole: string;
  filename: string;
  coordinate_x: string;
  coordinate_y: string;
  coordinate_z: string;
  depth: string;
  status: string;
}

interface SelectableTableProps {
  data: RowData[];
  onSelectedRowsChange: (selectedRows: string[]) => void;
  selectedFilenames: string[];
}

const COLUMNS: Column<RowData>[] = [
  {
    Header: 'Borhull',
    accessor: (row: RowData) => {
      const borehole = row.filename.split('~')[0];
      return borehole !== undefined ? borehole : 'None';
    },
    Cell: ({ value }: any) => value, // Display the modified value
  },
  // {
  //   Header: 'Filename',
  //   accessor: 'filename',
  // },
  {
    Header: 'X',
    accessor: 'coordinate_x',
  },
  {
    Header: 'Y',
    accessor: 'coordinate_y',
  },
  {
    Header: 'Z',
    accessor: 'coordinate_z',
  },
  {
    Header: 'Dybde',
    accessor: 'depth',
  },
  {
    Header: 'Status',
    accessor: (row: RowData) => {
      const status = row.filename.split('~')[2];
      return status !== undefined ? status : 'Ikke tolket';
    },
    Cell: ({ value }: any) => value, // Display the modified value
  },
];

export function SelectableTable({ data, onSelectedRowsChange, selectedFilenames }: SelectableTableProps) {
  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => data, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    selectedFlatRows,
  } = useTable(
    {
      columns: columns,
      data: tableData,
      initialState: {
        selectedRowIds: selectedFilenames.reduce((acc, filename) => {
          const rowIndex = data.findIndex((row) => row.filename === filename);
          if (rowIndex !== -1) {
            acc[rowIndex] = true;
          }
          return acc;
        }, {} as any),
      }
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: any) => {
      hooks.visibleColumns.push((columns: any) => {
        return [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }: any) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: any) => <Checkbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ];
      });
    }
  );

  // This tracks the selected boreholes, and memorizes it so that it only changes when the selectedFlatRows changes
  // Also it passes it to the parent component
  React.useEffect(() => {
    const filenames = selectedFlatRows.map((row) => row.original.filename);
    onSelectedRowsChange(filenames);
  }, [selectedFlatRows, onSelectedRowsChange]);

  const memoizedFilenames = React.useMemo(
    () => selectedFlatRows.map((row) => row.original.filename),
    [selectedFlatRows]
  );

  React.useEffect(() => {
    onSelectedRowsChange(memoizedFilenames);
  }, [memoizedFilenames, onSelectedRowsChange]);

  const { pageIndex } = state;

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
          <tr></tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
          <tr></tr>
        </tbody>
      </table>
      <div>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </button>
      </div>
    </>
  );
}
