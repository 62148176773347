export type GeojsonObject = {
  type: string;
  crs: {
    type: string;
    properties: {
      name: string;
    };
  };
  features: {
    type: string;
    geometry: {
      type: string;
      coordinates: number[][][];
    };
    properties: { id?: string };
  }[];
};

export type GeojsonStructure = {
  type: string;
  features: {
    type: string;
    geometry: {
      type: string;
      coordinates: number[][][];
    };
    properties: {};
  }[];
};

export type SeizedGeometries = {
  buffer: GeojsonObject;
  structure: GeojsonStructure;
};

export type MeasureAreas = {
  restore: GeojsonObject;
  compensate: GeojsonObject;
};

export const emptyGeojson: string = `
{
    "type": "FeatureCollection",
    "crs": {
        "type": "",
        "properties": {
            "name": "EPSG: 102100"
        }
    },
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": []
            },
            "properties": {}
        }
    ]
}
`;

export const emptyProjectGeojson: string = `
{
    "type": "FeatureCollection",
    "crs": {
        "type": "",
        "properties": {
            "name": "EPSG: 4326"
        }
    },
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "MultiLineString",
                "coordinates": []
            },
            "properties": {}
        }
    ]
}
`;

export const multiLineGeojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',

      geometry: {
        type: 'MultiLineString',
        coordinates: [[[0, 0]]],
      },
      properties: {},
    },
  ],
};

export const measureAreas = `{
 "restore": ${emptyGeojson},
 "compensate": ${emptyGeojson}
}`;

export const emptySeizedGeometries = `
{
  "buffer": ${emptyGeojson},
  "structure": ${JSON.stringify(multiLineGeojson)}
 }
`;

export const isGeojsonEmpty = (geojson: GeojsonObject | GeojsonStructure): boolean => {
  return geojson.features[0].geometry.coordinates.length === 0;
};
