export const ExpandIcon = () => {

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="expand-icon-path"
        d="M4.07145 13.1119L5.95956 15H1V10.0404L2.88811 11.9286L3.24167 12.2821L3.59522 11.9286L11.9286 3.59522L12.2821 3.24167L11.9286 2.88811L10.0404 1H15V5.95956L13.1119 4.07145L12.7583 3.71789L12.4048 4.07145L4.07145 12.4048L3.71789 12.7583L4.07145 13.1119Z"
        fill="#063342"
        stroke="#063342"
      />
    </svg>
  );
};
