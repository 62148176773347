import { useFetchAuthenticated } from '../../hooks/useFetchAuthenticated';
import Polyline from '@arcgis/core/geometry/Polyline';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import MapView from '@arcgis/core/views/MapView';
import { useEffect } from 'react';

interface HoverHandlerCleanupFunction {
  (): void;
}
type OnLineClickCallback = (distance: number, paths: number[][][]) => void;

export function CreateLinesBetweenPoints(props: any) {
  const { view, projectInformation, projectData, onLineClick, lines } = props;
  const { fetchAuthenticated } = useFetchAuthenticated();

  useEffect(() => {
    if (view && projectInformation && Object.keys(projectInformation).length > 1) {
      // Make sure the view is ready
      view
        .when(() => {
          // Create a new GraphicsLayer
          const graphicsLayer = new GraphicsLayer();
          // Add the layer to the view
          view.map.add(graphicsLayer);

          // Define the symbol for the lines
          const lineSymbol = createLineSymbol();

          const hoverSymbol = createHoverSymbol();

          createLineGraphicsFromPointsAndAddToLayers(lines, lineSymbol, graphicsLayer);

          // Defining hover
          let lastHoveredGraphic: any = null;

          // Define the hover event handler
          const hoverHandler = setupHoverHandler(view, graphicsLayer, hoverSymbol, lineSymbol);

          // Define the click event handler
          const clickHandler = setupClickHandler(view, graphicsLayer, onLineClick);

          // Add the event listeners
          view.on('pointer-move', hoverHandler);
          view.on('click', clickHandler);

          // Cleanup function to remove event listeners
          return () => {
            view.off('pointer-move', hoverHandler);
            view.off('click', clickHandler);
          };
        })
        .catch((error: Error) => {
          console.error('Error creating lines:', error.message);
        });
    }
  }, [view, projectInformation, projectData, fetchAuthenticated]);

  return null;
}

function createLineSymbol() {
  return new SimpleLineSymbol({
    color: [226, 119, 40], // Orange color for the line
    width: 4,
  });
}

function createHoverSymbol() {
  return new SimpleLineSymbol({
    color: [0, 0, 255], // Bright blue color for the line on hover
    width: 4,
  });
}

function createLineGraphicsFromPointsAndAddToLayers(
  lines: [[][]],
  lineSymbol: SimpleLineSymbol,
  graphicsLayer: GraphicsLayer
) {
  lines[0].forEach((point, index) => {
    if (index - 1 < lines.length) {
      const lineGeometry = new Polyline({
        paths: [point],
        spatialReference: { wkid: 25832 },
      });

      const lineGraphic = new Graphic({
        geometry: lineGeometry,
        symbol: lineSymbol,
      });
      graphicsLayer.add(lineGraphic);
    }
  });
}

function setupHoverHandler(
  view: MapView,
  graphicsLayer: GraphicsLayer,
  hoverSymbol: SimpleLineSymbol,
  lineSymbol: SimpleLineSymbol
) {
  let lastHoveredGraphic: __esri.Graphic | null = null;

  const hoverHandler = (event: __esri.ViewPointerMoveEvent) => {
    view.hitTest(event).then((response: any) => {
      const hitGraphic = response.results.find((result: any) => {
        return result.graphic && result.graphic.layer === graphicsLayer;
      })?.graphic;

      if (hitGraphic) {
        if (hitGraphic !== lastHoveredGraphic) {
          hitGraphic.symbol = hoverSymbol;
          if (lastHoveredGraphic) {
            lastHoveredGraphic.symbol = lineSymbol;
          }
          lastHoveredGraphic = hitGraphic;
        }
      } else {
        if (lastHoveredGraphic) {
          lastHoveredGraphic.symbol = lineSymbol;
          lastHoveredGraphic = null;
        }
      }
    });
  };

  return hoverHandler;
}

function setupClickHandler(
  view: MapView,
  graphicsLayer: GraphicsLayer,
  onLineClick: OnLineClickCallback
) {
  const clickHandler = (event: __esri.ViewClickEvent) => {
    event.stopPropagation();

    view.hitTest(event).then((response: any) => {
      if (response.results.length > 0) {
        const hitGraphic = response.results.find((result: any) => {
          return result.graphic && result.graphic.layer === graphicsLayer;
        })?.graphic;

        if (hitGraphic) {
          const paths = (hitGraphic.geometry as __esri.Polyline).paths;

          // Prompt user for distance.
          const userInputDistance = prompt('What distance do you want?');

          if (userInputDistance) {
            const distance = parseFloat(userInputDistance);
            onLineClick(distance, paths);
          }
        }
      }
    });
  };

  return clickHandler;
}
