import React from 'react';
import { SelectableTable } from '../SelectableTable/SelectableTable';
import { useProjectSndInformation } from '../../hooks/useProjectSndInformation';
import { Spinner } from '../Spinner/Spinner';
import { useFileUploader, plotSelectedBoreholes } from '../../hooks/useFileUploader';
import { useFetchAuthenticated } from '../../hooks/useFetchAuthenticated';

interface DataSelectionComponentProps {
  selectedOption: string;
  setSndData: React.Dispatch<React.SetStateAction<any>>;
  onSelectedFilenamesChange: (selectedFilenames: string[]) => void;
  selectedFilenames: string[];
  setSelectedFilenames: React.Dispatch<React.SetStateAction<string[]>>;
}

function DataSelectionComponent({
  selectedOption,
  setSndData: setGlobalSndData,
  onSelectedFilenamesChange,
  selectedFilenames,
  setSelectedFilenames,
}: DataSelectionComponentProps) {
  const { fetchAuthenticated } = useFetchAuthenticated();
  const { projectInformation, setProjectInformation, loading, setLoading } =
    useProjectSndInformation(selectedOption);
  // Selected filenames/boreholes
  const { addFiles, isUploadingFiles, progress, setSndData } = useFileUploader(fetchAuthenticated);

  const handleSelectedRowsChange = (selectedFilenames: string[]) => {
    onSelectedFilenamesChange(selectedFilenames);
  };

  const handlePlotSelectedBoreholes = async () => {
    setLoading(true); // Assuming you have access to setLoading from useProjectSndInformation
    await plotSelectedBoreholes(selectedOption, selectedFilenames, setGlobalSndData, setLoading, fetchAuthenticated);
  };

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const projectInformationData = Object.values(projectInformation).flat();

  return (
    <div>
      <SelectableTable
        data={projectInformationData}
        onSelectedRowsChange={handleSelectedRowsChange}
        selectedFilenames={selectedFilenames}
      />
      <button onClick={handlePlotSelectedBoreholes}>Tegn valgte boringer</button>
    </div>
  );
}

export default DataSelectionComponent;
