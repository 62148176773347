import { useState } from 'react';
import './ImageContainer.scss';
//import { Chart, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import SndPlot from '../SndPlot/SndPlot';
import { useFetchAuthenticated } from '../../hooks/useFetchAuthenticated';
//Chart.register(CategoryScale, LinearScale, PointElement, LineElement);

interface SNDData {
  filename: string;
  coordinate_z: number;
  borehole: string;
  penetration_force: number;
  depth: number;
  pred: string;
  pred_quickclay: string;
  color: string;
  flushing_pressure: number;
  penetration_time: number;
  R: boolean;
  Y: boolean;
  S: boolean;
  manual_control: string;
  final_control: string;
}

// interface StripLineMappings {
//   [key: string]: { min: number; max: number };
// }

type ImageContainerProps = {
  src?: string;
  handleClick: (alternativId: number) => void;
  sndData: any;
  setSndData: (sndData: any) => void;
  showFullPlot: boolean;
  splittedWiews: boolean;
  showQuickClay: boolean;
  selectedProject: string;
  selectedFilenames: string[];
};

function processAndPlotSndData(snd_json: any): SNDData[] {
  if (!snd_json || snd_json.length === 0) {
    return [];
  }
  const chartData: SNDData[] = snd_json.map((data: SNDData) => ({
    borehole: data.filename,
    coordinate_z: data.coordinate_z,
    penetration_force: data.penetration_force,
    depth: data.depth,
    pred: data.pred,
    pred_quickclay: data.pred_quickclay,
    color: getColorForPred(data.pred),
    flushing_pressure: data.flushing_pressure,
    penetration_time: 10 * data.penetration_time,
    R: data.R,
    Y: data.Y,
    S: data.S,
    manual_control: data.manual_control,
    final_control: data.final_control,
  }));

  // Color mappings for different predValues
  function getColorForPred(predValue: string): string {
    const colorMappings: { [key: string]: string } = {
      Topplag: 'rgba(139, 70, 19, 0.9)',
      Kohesjon: 'rgba(0, 191, 255, 0.9)',
      Friksjonsmateriale: 'rgba(223, 127, 255, 0.9)',
      Sprobrudd: 'rgba(255, 0, 0, 0.9)',
      Losmasser: 'rgba(255, 0, 0, 0.9)',
      Berg: 'rgba(101, 116, 154, 0.9)',
    };

    return colorMappings[predValue] || 'rgba(0, 0, 0, 0.1)'; // Default color if no mapping found
  }

  return chartData;
}

export function ImageContainer(props: ImageContainerProps) {
  const {
    src,
    sndData,
    showFullPlot,
    splittedWiews,
    showQuickClay,
    selectedProject,
    selectedFilenames,
  } = props;
  const [active, setActive] = useState<boolean>(false);
  const [zoomXRange, setZoomXRange] = useState<[number, number] | null>(null);
  const [highlightYRange, setHighlightYRange] = useState<[number, number] | null>(null);
  const { fetchAuthenticated } = useFetchAuthenticated();

  let plotSndData;

  if (sndData) {
    plotSndData = sndData.map((data: any) => JSON.parse(data));
  } else {
    plotSndData = [
      { filename: 'Test', depth: 0, penetration_force: 10 },
      { filename: 'Test', depth: 1, penetration_force: 200 },
      { filename: 'Test', depth: 2, penetration_force: 15 },
      // add more data points here
    ];
  }
  if (sndData) {
    return (
      <div className="image-container-row">
        {sndData.map((data: any, index: any, array: any) => (
          <div key={index} className={`image-container`}>
            <div className="text-container">
              <h3>Borpunkt: {JSON.parse(data)[0].filename.split('~')[0]} - Metode: {JSON.parse(data)[0].filename.split('~')[1]} - Status: {JSON.parse(data)[0].filename.split('~')[2]}</h3>
            </div>
            <div className="recharts-container">
              <SndPlot
                data={processAndPlotSndData(JSON.parse(data))}
                lastIndex={index === array.length - 1 ? true : false}
                showFullPlot={showFullPlot}
                splittedWiews={splittedWiews}
                showQuickClay={showQuickClay}
                selectedProject={selectedProject}
                selectedFilenames={selectedFilenames}
                fetchAuthenticated={fetchAuthenticated}
              />
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className={`no-plot-text-container`}>
        {/* <img src={src} alt="Testdata:"></img> */}
        <h2 style={{ fontSize: '15px' }}>
          Ingen boringer lastet inn. Velg et prosjekt og borfiler fra listen over.
        </h2>
      </div>
    );
  }
}
