import { Link } from 'react-router-dom';
import './Header.scss';

export const Header = () => {
  return (
    <div className="header">
      <nav className="navbar">
        <Link to={'/'} className="navbarBrand" />
        
        <ul className="navbarNav">
          <li className="navItem">
            <Link to="/" className="navLink">
              Prosjekter
            </Link>
          </li>
          <li className="navItem">
            <Link to="/ToDos" className="navLink">
              ToDos
            </Link>
          </li>
          <li className="navItem">
            <Link to="/" className="navLink" onClick={() => { }}>
              Logg Ut
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
