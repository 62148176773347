import './Spinner.scss';

export const Spinner = () => {
  return (
    <div>
      <svg className={'spinner'} viewBox=" 25 25 50 50">
        <circle className={'spinner-circle'} cx="50" cy="50" r="20" />
      </svg>
    </div>
  );
};
