import './ExpandableHeader.scss';
import { useState } from 'react';
import { MiniDropdownPhases, OptionInfo } from '../MiniDropdownPhases/MiniDropdownPhases';
import { Picker, PickerOption } from '../Picker/Picker';

//icons
import { ExpandIcon } from '../../assets/Icons/ExpandIcon';
import { MinimizeIcon } from '../../assets/Icons/MinimizeIcon';

type ExpandableHeaderProps = {
  handleClick: () => void;
  isExpanded: boolean;
  editDate: string;
  projectNumber: number;
  // pickerOptions: PickerOption[];
  // selectOptions: OptionInfo[];
};

export function ExpandableHeader(props: ExpandableHeaderProps) {
  const { handleClick, isExpanded, editDate, projectNumber } = props;

  //const values = pickerOptions.map((option) => option.value);
  // type NewAlternativeSourceOption = (typeof values)[number];
  // const [newAlternativeSource, setNewAlternativeSource] = useState<NewAlternativeSourceOption>(
  //   values[0]
  // );

  // const handleChangePickerOption = (value: string) => {
  //   if (values.includes(value)) {
  //     setNewAlternativeSource(value);
  //   }
  // };

  return (
    <div className="header-expandable-container">
      <div className="header-top">
        <button className="expand-icon" onClick={handleClick}>
          {isExpanded ? <MinimizeIcon /> : <ExpandIcon />}
        </button>
        {/* <div className="alternativ-info">
          <p className="plain-text">Prosjekt {projectNumber}</p>
        </div> */}
      </div>
      {/* {isExpanded ? (
        <div className="phase-picker">
          <Picker
            options={pickerOptions}
            selectedOption={newAlternativeSource}
            handleChange={handleChangePickerOption}
          />
        </div>
      ) : (
        <MiniDropdownPhases selectOptions={selectOptions} />
      )} */}
      <div className="line-seperator"></div>
    </div>
  );
}
