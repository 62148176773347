import React, { useState, useEffect, useRef } from 'react';
import './InteractivePlotIframe.scss'; // Assuming your styling remains the same
import { Spinner } from '../Spinner/Spinner';
import { useFetchAuthenticated } from '../../hooks/useFetchAuthenticated';

interface ApiResponse {
  PID: number;
  SIGNTERM: number;
  PORT: number;
}

interface PlotlyComponentProps {
  onClose: () => void;
  distanceInput: number | null;
  projectNumber: string;
  lineCoordinates: [number, number][][] | null;
}

interface LastParams {
  lineCoordinates: [number, number][][] | null;
  distanceInput: number | null;
  projectNumber: string | null;
}

const buttonCommonStyle: React.CSSProperties = {
  position: 'absolute',
  zIndex: 1000,
  cursor: 'pointer',
  background: 'white',
  border: '1px solid #ccc',
  borderRadius: '4px',
  fontSize: '1.1em',
  padding: '10px 15px',
  lineHeight: '1',
  top: '10px',
};

const closeButtonStyle: React.CSSProperties = {
  ...buttonCommonStyle,
  right: '60px',
};

const toggleButtonStyle: React.CSSProperties = {
  ...buttonCommonStyle,
  right: '10px',
};

const PlotlyComponentIframe: React.FC<PlotlyComponentProps> = ({
  onClose,
  distanceInput,
  projectNumber,
  lineCoordinates,
}) => {
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_HOST + 'geobutler/plotly';
  const DASH_URL = process.env.REACT_APP_HOST + 'PORT';
  const { fetchAuthenticated } = useFetchAuthenticated();
  const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
  const lastParamsRef = useRef<LastParams>({
    lineCoordinates: null,
    distanceInput: null,
    projectNumber: null,
  });

  useEffect(() => {
    if (
      lineCoordinates &&
      (JSON.stringify(lastParamsRef.current.lineCoordinates) !== JSON.stringify(lineCoordinates) ||
        lastParamsRef.current.distanceInput !== distanceInput ||
        lastParamsRef.current.projectNumber !== projectNumber)
    ) {
      lastParamsRef.current = { lineCoordinates, distanceInput, projectNumber };
      setIsLoading(true); // Activate spinner while iframe is loading
      fetchAuthenticated(BACKEND_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          project_number: projectNumber,
          user_distance: distanceInput,
          line_coordinates: { coordinates: lineCoordinates },
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data: ApiResponse) => {
          const iframeUrl = BACKEND_URL.includes('localhost')
            ? DASH_URL.replace('8080', `${data.PORT}`).replace('PORT', `${data.PORT}`)
            : DASH_URL.replace('PORT', `${data.PORT}`);
          setIframeSrc(iframeUrl);
          setApiResponse(data);
        })
        .catch((error) => console.error('Error fetching data:', error))
        .finally(() => setIsLoading(false));
    }
  }, [lineCoordinates, distanceInput, projectNumber, fetchAuthenticated, BACKEND_URL, DASH_URL]);

  const handleCloseClick = () => {
    if (apiResponse) {
      fetchAuthenticated(process.env.REACT_APP_HOST + 'geobutler/plotly-shutdown', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          PID: apiResponse.PID,
          SIGNTERM: apiResponse.SIGNTERM,
          PORT: apiResponse.PORT,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          console.log('Data successfully sent to the endpoint');
        })
        .catch((error) => console.error('Error sending data:', error))
        .finally(() => {
          onClose(); // Close the iframe after sending the data
        });
    } else {
      onClose(); // Just close the iframe if there's no data to send
    }
  };

  const handleToggleClick = () => {
    setIsFullScreen((prevIsFullScreen) => !prevIsFullScreen);
  };

  const iframeStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
  };

  return (
    <div className={`plot-wrapper ${isFullScreen ? 'full-screen' : ''}`}>
      <button onClick={handleCloseClick} style={closeButtonStyle}>
        x
      </button>
      <button onClick={handleToggleClick} style={toggleButtonStyle}>
        {isFullScreen ? '-' : '+'}
      </button>
      {isLoading ? (
        <Spinner />
      ) : iframeSrc ? (
        <iframe src={iframeSrc} frameBorder="0" style={iframeStyle} allowFullScreen></iframe>
      ) : null}
    </div>
  );
};

export default PlotlyComponentIframe;
