import { useState, useEffect } from 'react';
import { useFetchAuthenticated } from './useFetchAuthenticated';
export interface ProjectsLoaderHookData {
  projects: any[];
  setProjects: React.Dispatch<React.SetStateAction<any[]>>;
  loading: boolean;
  error: Error | null;
  loadData: (selectedProject: string) => Promise<void>;
  isLoadingData: boolean;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  projectData: any;
  setProjectData: React.Dispatch<React.SetStateAction<any>>;
  selectedProject: string | null;
  setSelectedProject: React.Dispatch<React.SetStateAction<string | null>>;
  handleSelectChange: (selectedProject: string) => void;
  fetchGeoProjects: () => Promise<void>;
}

export function useProjectsLoader(): ProjectsLoaderHookData {
  const [projects, setProjects] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [projectData, setProjectData] = useState<any>(null);
  const [selectedProject, setSelectedProject] = useState<string | null>(null); // Add selectedProject state
  const BACKEND_URL = process.env.REACT_APP_HOST;
  const { fetchAuthenticated } = useFetchAuthenticated();

  const loadData = async (selectedProject: string) => {
    setIsLoadingData(true);

    try {
      const response = await fetchAuthenticated(
        `${BACKEND_URL}geobutler/projects/get-project-data/?project_number=${selectedProject}`
      );
      if (response.ok) {
        const projectData = await response.json();

        setProjectData(projectData);
      } else {
        throw new Error('Failed response');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      alert('Failed to fetch data');
    }

    setIsLoadingData(false);
  };

  useEffect(() => {
    // Load data when selectedOption changes
    if (selectedProject) {
      loadData(selectedProject);
    }
  }, [selectedProject]);

  const fetchGeoProjects = async () => {
    try {
      const response = await fetchAuthenticated(`${BACKEND_URL}geobutler/projects/`);
      if (!response.ok) {
        throw new Error('Failed to fetch projects');
      }
      const data = await response.json();
      setProjects(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError(error instanceof Error ? error : new Error('An unknown error occurred'));
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch projects when the component mounts
    fetchGeoProjects();
  }, []);

  const handleSelectChange = (selectedProject: string) => {
    setSelectedProject(selectedProject);
  };

  return {
    projects,
    setProjects,
    loading,
    error,
    loadData,
    isLoadingData,
    setIsLoadingData,
    projectData,
    setProjectData,
    selectedProject,
    setSelectedProject,
    handleSelectChange,
    fetchGeoProjects,
  };
}
