import { useState } from 'react';

async function SendFilesToBackend(
  files: File[],
  projectNumber: string,
  filename: string,
  incompatibleFiles: string[] = [],
  setSndData: React.Dispatch<React.SetStateAction<any>>,
  fetchAuthenticated: any
) {
  const formData = new FormData();
  const BACKEND_URL = process.env.REACT_APP_HOST;

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    formData.append('files', file);
  }

  let mlResponseFilename = null;
  const filenameWithMethod: string[] = [''];

  try {
    const response = await fetchAuthenticated(
      `${BACKEND_URL}upload-snd/?project_number=${projectNumber}`,
      {
        method: 'POST',
        body: formData,
      }
    );

    if (response.ok) {
      const sndJsonResponse = await response.json();
      try {
        if (typeof sndJsonResponse.str_error === 'string') {
          console.error('Error when uploading file!: ' + sndJsonResponse.str_error);
          incompatibleFiles.push(filename);
        } else {
          setSndData(sndJsonResponse.ml_responses);
          mlResponseFilename = sndJsonResponse.ml_responses;
          const mlResponseFilenameArray = mlResponseFilename[0]; // Access the first element of the array
          const mlResponseFilenameObject = JSON.parse(mlResponseFilenameArray); // Parse the JSON string into an object
          filenameWithMethod.pop(); // Remove the empty string
          filenameWithMethod.push(mlResponseFilenameObject[0].filename); // Access the "filename" property

          // props.setSndData(sndJsonResponse.ml_responses); // You need to pass the "setSndData" function as a parameter to this hook
        }
      } catch (error) {
        console.error('File med feil:', sndJsonResponse, 'Feil:', sndJsonResponse.str_error, error);
        incompatibleFiles.push(filename);
      }
    } else {
      console.error('Failed to upload files test1:', response.status, response.statusText);
      incompatibleFiles.push(filename);
    }
  } catch (error) {
    console.error('Error uploading files test2:', error);
    incompatibleFiles.push(filename);
  }
  return { incompatibleFiles, filenameWithMethod };
}

export async function plotSelectedBoreholes(
  selectedOption: string | null,
  selectedFilenames: string[],
  setSndData: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  fetchAuthenticated: any
) {
  if (selectedOption === null) {
    alert('No project selected');
    return;
  }
  setSndData(null);
  const BACKEND_URL = process.env.REACT_APP_HOST;

  setLoading(true);
  const url = new URL(`${BACKEND_URL}geobutler/projects/load-snd-data/`, window.location.origin);
  const params = new URLSearchParams();

  params.append('project_number', selectedOption);
  selectedFilenames.forEach((filename) => {
    params.append('borehole_name', filename);
  });

  url.search = params.toString();

  try {
    const response = await fetchAuthenticated(url.toString());

    if (response.ok) {
      const data = await response.json();

      setSndData(data.ml_responses);
    } else {
      throw new Error('Failed to fetch projects');
    }
  } catch (error) {
    console.error('Error fetching projects:', error);
    alert('Failed to fetch projects');
  } finally {
    setLoading(false);
  }
  return { setSndData, selectedFilenames };
}

export function useFileUploader(fetchAuthenticated: any) {
  const [isUploadingFiles, setisUploadingFiles] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sndData, setSndData] = useState<any>();

  async function addFiles(
    selectedOption: string | null,
    setSndData: React.Dispatch<React.SetStateAction<any>>,
    handleSelectedFilenamesChange: (selectedFilenames: string[]) => void
  ) {
    if (!selectedOption) {
      alert('Prosjekt ikke valgt!');
      return;
    }

    return new Promise<void>(async (resolve) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;
      input.accept = '.snd';

      input.addEventListener('change', async () => {
        const files = input.files;
        if (!files || files.length === 0) {
          resolve(); // Resolve immediately if no files are selected
          return;
        }

        setisUploadingFiles(true);
        setProgress(0);

        const maxSize = 1024 * 1024;
        const minSize = 1500;
        const fileList = Array.from(files);
        const incompatibleFiles = [];
        const filenameWithMethod: string[] = [];

        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i];

          if (file.name.split('.').pop()?.toLowerCase() !== 'snd') {
            incompatibleFiles.push(file.name);
            continue;
          }

          if (file.size > maxSize || file.size < minSize) {
            incompatibleFiles.push(file.name);
            continue;
          }

          try {
            setProgress(((i + 1) / fileList.length) * 100);
            const result = await SendFilesToBackend(
              [file],
              selectedOption,
              file.name,
              incompatibleFiles,
              setSndData,
              fetchAuthenticated
            );
            const { filenameWithMethod }: { filenameWithMethod: string[] } = result;
            setSndData(null);
            // If there is only one file in the upload
            // if (fileList.length === 1) {
            //   await plotSelectedBoreholes(
            //     selectedOption,
            //     filenameWithMethod,
            //     setSndData,
            //     setisUploadingFiles,
            //     fetchAuthenticated
            //   );
            //   console.log('filenameWithMethod', filenameWithMethod);
            //   handleSelectedFilenamesChange([`${filenameWithMethod}.csv`]);
            // }
            // // If not, set sndData to nothing
            // else {
            //   setSndData(null);
            // }
            // Handle the result if needed
          } catch (error) {
            console.error('Error sending file to backend:', error);
            alert('Failed to send file to backend');
            resolve(); // Resolve the promise on error
          }
        }

        setisUploadingFiles(false);
        setProgress(0);

        if (incompatibleFiles.length > 0) {
          alert(`The following files are not compatible: ${incompatibleFiles.join(', ')}`);
        }

        resolve(); // Resolve when all files have been processed
      });

      input.click();
    });
  }

  return { addFiles, isUploadingFiles, progress, sndData, setSndData };
}
