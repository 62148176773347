import { useMsal } from '@azure/msal-react';
import { SplashIcon } from '../../assets/Icons/SplashIcon';
import { loginUser } from '../../auth/authentication';
import { ButtonWithIcon } from '../../components/ButtonWithIcon/ButtonWithIcon';

import './LoginScreen.scss';
import { MulticonsultLogo } from '../../assets/Icons/MulticonsultLogo';

export function LoginScreen() {
  const { instance } = useMsal();
  return (
    <div className={'container'}>
      <div className={'main-content'}>
        <div className={'title-and-description'}>
          <h1>Geobutler</h1>
          <h3>Et verktøy laget av Multiconsult</h3>
        </div>
        <div className={'icon-and-actions'}>
          <SplashIcon />
          <div className={'button-container'}>
            <ButtonWithIcon
              onClick={() => loginUser(instance)}
              text={'Logg inn'}
              isDisabled={false}
              isLoading={false}
            />
          </div>
        </div>
      </div>
      <div className={'footer'}>
        <MulticonsultLogo />
      </div>
    </div>
  );
}
