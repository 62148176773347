import { useState, useEffect } from 'react';
import { useFetchAuthenticated } from './useFetchAuthenticated';

export interface BoreholeData {
  filename: string;
  coordinate_x: string;
  coordinate_y: string;
  coordinate_z: string;
  depth: string;
  penetration_force: string;
  flushing_pressure: string;
  penetration_time: string;
  R: string;
  Y: string;
  S: string;
  rolling_average_force: string;
  force_difference: string;
  pred: string;
  borehole: string;
  status: string;
}

export function useProjectSndInformation(selectedOption: string | null) {
  const [projectInformation, setProjectInformation] = useState<{ [key: string]: BoreholeData[] }>(
    {}
  );
  const [loading, setLoading] = useState(true);
  const BACKEND_URL = process.env.REACT_APP_HOST;
  const { fetchAuthenticated } = useFetchAuthenticated();

  useEffect(() => {
    const loadProjectSndInformation = async () => {
      try {
        const response = await fetchAuthenticated(
          `${BACKEND_URL}geobutler/projects/load-snd-info/?project_number=${selectedOption}`
        );
        if (response.ok) {
          const data = await response.json();
          setProjectInformation(data);
        } else {
          throw new Error('Failed to fetch project information');
        }
      } catch (error) {
        console.error('Error fetching project information:', error);
        // You might want to handle errors differently, e.g., set an error state
      } finally {
        setLoading(false);
      }
    };

    if (selectedOption) {
      setLoading(true);
      loadProjectSndInformation();
    } else {
      setProjectInformation({});
      setLoading(false);
    }
  }, [selectedOption]);

  return { projectInformation, setProjectInformation, loading, setLoading };
}
