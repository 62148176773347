import SketchViewModel from '@arcgis/core/widgets/Sketch/SketchViewModel';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import Polygon from '@arcgis/core/geometry/Polygon';
import Sketch from '@arcgis/core/widgets/Sketch';
import Map from '@arcgis/core/Map';
import {
  GeojsonObject,
  GeojsonStructure,
  SeizedGeometries,
  multiLineGeojson,
  MeasureAreas,
} from './geojsonHelper';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { MapLayer } from '../assets/mapLayers';
import MapView from '@arcgis/core/views/MapView';
import Expand from '@arcgis/core/widgets/Expand';
import LayerList from '@arcgis/core/widgets/LayerList';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import { lineGraphicSymbol, getFillsymbol } from './simpleFillSymbol';
import { VersionType } from './databaseTypes';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import { Polyline } from '@arcgis/core/geometry';
import * as webMercatorUtils from '@arcgis/core/geometry/support/webMercatorUtils';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import { MeasureEntry } from './databaseTypes';
import Geometry from '@arcgis/core/geometry/Geometry';

export type SketchLayer = 'restore' | 'seized' | 'compensate';
export type ListMode = 'show' | 'hide';
export const spatialReference_wkid: number = 102100; // WGS_1984_Web_Mercator_Auxiliary_Sphere

export const createMapView = (map: Map, mapFocus: number[]): MapView => {
  return new MapView({
    container: 'map',
    map: map,
    center: mapFocus.length !== 0 ? mapFocus : [10.7359753679, 59.9221679377],
    zoom: 9,
    ui: {
      components: ['attribution'],
    },
  });
};

export const createExpand = (view: MapView): Expand => {
  return new Expand({
    view: view,
    content: new LayerList({
      view: view,
    }),
  });
};

export const createGraphicsLayer = (
  title: string,
  id: string,
  listMode: ListMode = 'show'
): GraphicsLayer => {
  return new GraphicsLayer({ title, id, listMode });
};

export const createSketch = (
  map: Map,
  view: MapView,
  seizedLayer: GraphicsLayer,
  activeSketchLayer: SketchLayer
): Sketch => {
  return new Sketch({
    layer: seizedLayer || map.findLayerById(activeSketchLayer),
    view: view,
    visible: true,
    viewModel: new SketchViewModel({
      view: view,
      layer: seizedLayer || map.findLayerById(activeSketchLayer),
      polygonSymbol: {
        type: 'simple-fill',
        color: [255, 155, 0, 0.3],
        outline: {
          width: 1,
          style: 'solid',
          color: 'orange',
        },
      },
    }),
    snappingOptions: {
      enabled: true,
      featureSources: [
        { layer: map.findLayerById('restore') as GraphicsLayer, enabled: true },
        { layer: map.findLayerById('seized') as GraphicsLayer, enabled: true },
        { layer: map.findLayerById('compensate') as GraphicsLayer, enabled: true },
      ],
    },
    defaultUpdateOptions: {
      tool: 'reshape',
    },
    visibleElements: {
      createTools: {
        point: false,
        polyline: false,
        circle: false,
      },
      selectionTools: {
        'rectangle-selection': false,
        'lasso-selection': false,
      },
      settingsMenu: false,
    },
  });
};

export const mapLayersToFeatureLayers = (mapLayers: MapLayer[]): FeatureLayer[] => {
  return mapLayers.map(
    (mapLayer: MapLayer) =>
      new FeatureLayer({
        url: mapLayer.url,
        popupTemplate: mapLayer.popupTemplate,
        visible: true,
      })
  );
};

export const addAreaLayer = (
  area: GeojsonObject,
  layer: GraphicsLayer,
  view: MapView,
  isSeized: boolean
) => {
  const graphicsFromArea = area.features.map((feature) => {
    let isMulitLine: boolean = feature.geometry.type === 'MultiLineString';

    if (isMulitLine) {
      return new Graphic({
        geometry: new Polyline({
          paths: feature.geometry.coordinates,
          spatialReference: {
            wkid: spatialReference_wkid,
          },
        }),
        symbol: lineGraphicSymbol,
      });
    } else {
      return new Graphic({
        geometry: new Polygon({
          rings: feature.geometry.coordinates,
          spatialReference: {
            wkid: spatialReference_wkid,
          },
        }),
        symbol: getFillsymbol(`${layer.id}` as SketchLayer),
      });
    }
  });
  layer.addMany(graphicsFromArea);
  layer.title = isSeized ? 'Valgt område' : layer.id === 'restore' ? 'Istandsette' : 'Kompensere';
  if (isSeized) {
    view.when(() => {
      view.goTo(
        {
          target: graphicsFromArea,
          zoom: 10,
        },
        { easing: 'ease-in', duration: 600 }
      );
    });
  }
};

type IdGraphic = {
  rings: number[][][];
  id: string;
};

export const replaceGeojsonFeatures = (
  activeVersion: VersionType,
  sketchLayer: GraphicsLayer,
  activeSketchLayer: string
): VersionType => {
  let polygons: IdGraphic[] = [];
  sketchLayer.graphics.map((graphic) => {
    const JsonGraphic = graphic.toJSON();
    polygons.push({ rings: JsonGraphic.geometry.rings, id: JsonGraphic.attributes.id });
  });

  let features = polygons.map((polygon) => {
    return {
      type: 'Feature',
      geometry: { type: 'Polygon', coordinates: polygon.rings },
      properties: {
        id: polygon.id,
      },
    };
  });

  const updatedVersion = activeVersion;
  if (activeSketchLayer === 'seized') {
    const activeVersionSeizedAreas: SeizedGeometries = JSON.parse(activeVersion.seizedArea);
    const activeVersionBuffer: GeojsonObject = activeVersionSeizedAreas.buffer;
    activeVersionBuffer.features = features;
    activeVersionSeizedAreas.buffer = activeVersionBuffer;
    updatedVersion.seizedArea = JSON.stringify(activeVersionSeizedAreas);
  } else {
    if (features.length !== 0) {
      const activeVersionMeasureArea: MeasureAreas = JSON.parse(activeVersion.measureArea);
      if (activeSketchLayer === 'restore') {
        activeVersionMeasureArea.restore.features = features;
      } else {
        activeVersionMeasureArea.compensate.features = features;
      }
      updatedVersion.measureArea = JSON.stringify(activeVersionMeasureArea);
    }
  }

  return updatedVersion;
};

export const createIntersectedLayer = (): FeatureLayer => {
  const template = {
    title: '',
    content: '',
  };

  return new FeatureLayer({
    source: [],
    fields: [
      {
        name: 'OBJECTID',
        alias: 'id',
        type: 'oid',
      },
      {
        name: 'id',
        alias: 'ObjectID',
        type: 'string',
      },
    ],
    geometryType: 'polygon',
    popupEnabled: true,
    popupTemplate: template,
    title: 'Overlappende områder',
    listMode: 'hide',
    spatialReference: {
      wkid: spatialReference_wkid,
    },
  });
};

export const addLineStructure = (
  seizedAreaStructure: GeojsonStructure,
  lineLayer: GeoJSONLayer
) => {
  let json;
  const lineGraphics = seizedAreaStructure.features.filter((feature) => {
    return feature.geometry.type === 'MultiLineString';
  });
  if (lineGraphics.length === 0) {
    json = seizedAreaStructure;
  } else {
    json = multiLineGeojson;
    json.features = lineGraphics;
  }
  const blob = new Blob([JSON.stringify(json)], {
    type: 'application/json',
  });

  const url = URL.createObjectURL(blob);

  lineLayer.url = url;
  lineLayer.renderer = new SimpleRenderer({
    symbol: lineGraphicSymbol,
  });
  lineLayer.title = 'Struktur';
};

export const createGeoJsonLayer = (coords: number[][][]): GeoJSONLayer => {
  const geojson = multiLineGeojson;
  multiLineGeojson.features[0].geometry.coordinates = coords;
  const blob = new Blob([JSON.stringify(geojson)], {
    type: 'application/json',
  });
  const url = URL.createObjectURL(blob);
  return new GeoJSONLayer({
    url,
    title: 'Influensområde',
  });
};

export const createGraphicForInfluenceAreaDrawer = (coords: number[][][]): Graphic => {
  const geometry = new Polyline({
    paths: coords,
  });
  const transformedGeometry = webMercatorUtils.geographicToWebMercator(geometry);
  return new Graphic({
    geometry: transformedGeometry,
  });
};

export const createInfluenceSketch = (influenceAreaLayer: GraphicsLayer, view: MapView): Sketch => {
  const sketchTool = new Sketch({
    layer: influenceAreaLayer,
    view: view,
    visible: true,
    viewModel: new SketchViewModel({
      view: view,
      layer: influenceAreaLayer,
      polygonSymbol: {
        type: 'simple-fill',
        color: [255, 155, 0, 0.3],
        outline: {
          width: 1,
          style: 'solid',
          color: 'black',
        },
      },
    }),
    defaultUpdateOptions: {
      tool: 'reshape',
    },
    snappingOptions: {
      enabled: true,
    },
    visibleElements: {
      createTools: {
        point: false,
        polyline: false,
        circle: false,
        polygon: true,
        rectangle: false,
      },
      selectionTools: {
        'rectangle-selection': false,
        'lasso-selection': false,
      },
      undoRedoMenu: false,
      settingsMenu: false,
    },
  });
  return sketchTool;
};

export const createMeasure = (
  measureLayer: GraphicsLayer,
  relatedVersion: number
): MeasureEntry => {
  const measurePolygon: Graphic = measureLayer.graphics.getItemAt(measureLayer.graphics.length - 1);
  const area = geometryEngine.planarArea(measurePolygon.geometry as Polygon);
  const measure = {
    relatedVersion: relatedVersion,
    category: '',
    relatedVVO: '',
    degreeImprovement: -1,
    createsDamage: '',
    impact: '',
    challenges: '',
    area: area,
    polygonId: measurePolygon.attributes.id,
    probability: -1,
    comment: '',
  };
  return measure;
};

export const createGeometryFromSeizedArea = (area: SeizedGeometries): Geometry[] => {
  const polygons: Polygon[] = area.buffer.features.map((polygon) => {
    return new Polygon({
      rings: polygon.geometry.coordinates,
      spatialReference: { wkid: spatialReference_wkid },
    });
  });
  return polygons;
};
