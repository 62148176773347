import { MapPage } from './pages';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { IntersectDataCtx } from './context/IntersectDataCtx';
import { Header } from './components';
import { LoginScreen } from './pages/Login/LoginScreen';

function App() {
  return (
    <IntersectDataCtx>
      <Router>
        <Header />
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/" element={<MapPage />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="/*" element={<LoginScreen />} />
          </Routes>
        </UnauthenticatedTemplate>
      </Router>
    </IntersectDataCtx>
  );
}

export default App;
