import { BoreholeData } from '../hooks/useProjectSndInformation';

export async function TransformCoordinates(
  coordinates: BoreholeData[],
  coordinateSystem: string,
  fetchAuthenticated: any
) {
  const BACKEND_URL = process.env.REACT_APP_HOST;
  const transformedCoordinates = [];
  const url = new URL(`${BACKEND_URL}geobutler/map/transform-coordinates/`, window.location.origin);

  const requestBody = JSON.stringify({
    src_system: coordinateSystem,
    coordinates: coordinates,
  }); //creating body for api callout

  try {
    const response = await fetchAuthenticated(url.toString(), {
      method: 'POST', // Use POST method to send the data in the request body
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    });

    if (response.ok) {
      const transformedResult = await response.json();
      transformedCoordinates.push(transformedResult); //adding the transformed coordinates to the array
    }
  } catch (error) {
    console.log(error);
  }
  return transformedCoordinates.flat(); //flatting the array before the return
}
